import React, { useEffect, useState } from 'react'
import Slowpoke                       from './resources/Slowpoke'
import css                            from './Cover.module.css'

const Cover = ()=> {

  const [position, setPosition] = useState(0)

  const getMetrics = ()=> {

    const value = Math.abs(100 - position)

    const left_x_data = { start: -5, size: .3 }
    const right_x_data = { start: -12, size: 1 }

    const leftX = (value/4)*left_x_data.size + left_x_data.start
    const rightX = (value/4)*right_x_data.size + right_x_data.start

    return { leftX, rightX }
  }

  useEffect(()=> {
    const interval = setInterval(()=> setPosition(c=> (c+1)%200), 60)
    return ()=> clearInterval(interval)
  })

  return (
    <div className={css.main}>
      <Slowpoke className={css.slowpoke} {...getMetrics()} />
    </div>

  )
}

export default Cover