export const CHESS_SYMBOLS = {
  'br': '♜',
  'bn': '♞',
  'bb': '♝',
  'bq': '♛',
  'bk': '♚',
  'bp': '♟',
  'wr': '♖',
  'wn': '♘',
  'wb': '♗',
  'wq': '♕',
  'wk': '♔',
  'wp': '♙'
}

export const EMPTY_BOARD = [
  ['', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', '']
]



export const countQueens = (board)=> board.reduce((acc, row)=> {
  return acc + row.reduce((acc, cell)=> {
    return acc + (cell === 'bq' ? 1 : 0)
  }, 0)
}, 0)

export const checkProgress = (board)=> {
  const queens = []
  board.forEach((row, rowIndex)=> {
    row.forEach((cell, cellIndex)=> {
      if (cell === 'bq') {
        queens.push({ row: rowIndex, cell: cellIndex })
      }
    })
  })
  const errorQueens = []
  for (let i = 0; i < queens.length; i++) {
    for (let j = i + 1; j < queens.length; j++) {
      // Check if queens are in the same row
      if (queens[i].row === queens[j].row) {
        errorQueens.push(queens[i])
        errorQueens.push(queens[j])
      }
      // Check if queens are in the same column
      if (queens[i].cell === queens[j].cell) {
        errorQueens.push(queens[i])
        errorQueens.push(queens[j])
      }
      // Check if queens are in the same diagonal
      if (Math.abs(queens[i].row - queens[j].row) === Math.abs(queens[i].cell - queens[j].cell)) {
        errorQueens.push(queens[i])
        errorQueens.push(queens[j])
      }
    }
  }
  if (errorQueens.length > 0) {
    // Remove duplicates
    const conflictQueens = errorQueens.filter((queen, index, queens)=> {
      const { row, cell } = queen
      const fistIndex = queens.findIndex((q)=> q.row === row && q.cell === cell)
      return fistIndex === index
    })
    // Return all queens in conflict
    return { status: 'error', queens: conflictQueens }
  }
  if (queens.length < 8) {
    return { status: 'pending', queens: [] }
  }
  return { status: 'ok', queens: [] }
}