import React        from 'react'
import { Link }     from 'react-router-dom'
import styles       from './BackToConcepts.module.css'

const BackToConcepts = ({ label='More concepts' })=> (
  <div className={styles.main}>
    <Link to='/concepts' className={styles.link}>
      {label}
    </Link>
  </div>
)

export default BackToConcepts