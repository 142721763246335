import React, { useState, useEffect } from 'react'
import styles                         from './Cover.module.css'

const Cover = () => {

  const [letters, setLetters] = useState(['G', 'A', 'M', 'E'])

  useEffect(() => {
    const interval = setInterval(() => {
      const word = (letters[0] === 'G') ? "OVER" : "GAME"
      setLetters(word.split(""))
    }, 3000)
    return () => clearInterval(interval)
  })

  return (
    <div className={styles.main}>
      <span className={styles.pulse}>{letters[0]}</span>
      <span className={styles.pulse}>{letters[1]}</span>
      <span className={styles.pulse}>{letters[2]}</span>
      <span className={styles.pulse}>{letters[3]}</span>
    </div>
  )
}

export default Cover