import React    from 'react'
import styles   from './Cover.module.css'

const Cover = ()=> (
  <div className={styles.cover}>
    <div className={styles.shadow} />
    <div className={styles.content}>
      <p className={styles.monkey} />
    </div>
  </div>
)

export default Cover