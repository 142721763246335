import React, { useState, useEffect } from 'react'
import styles                         from './Cover.module.css'

const getTexts = ()=> {
  const elements = ("If there is a will there is a way")
    .split("")
    .map((_l1, i, elements)=> elements.filter((_l2, j)=> j <= i).join("").trim())
    .filter((e, i, elements)=> elements.indexOf(e) === i)
  return [
    ...elements,
    ...elements.slice().reverse()
  ]
}

const Cover = ()=> {

  const [textIndex, setTextIndex] = useState(0)

  useEffect(()=> {
    let isCurrent = true
    const texts = getTexts()
    const interval = setInterval(()=> {
      if (!isCurrent) return
      setTextIndex(index=> index >= (texts.length-1) ? 0 : index+1)
    }, 220)
    return ()=> {
      isCurrent = false
      clearInterval(interval)
    }
  }, [])
  
  return (
    <div className={styles.main}>
      <p className={styles.text}>
        {getTexts()[textIndex]}
      </p>
    </div>
  )

}

export default Cover