import ProfileIcon  from './ProfileIcon'
import photo        from './resources/profile.jpg'
import css          from './Profile.module.css'

const Profile = ()=> (
  <div className={css.main}>
    <div className={css.content}>
      <div className={css.profile}>
        <img className={css.image} src={photo} alt='Jorge Aguirre' />
        <p className={css.name}>Jorge Aguirre</p>
        <p className={css.header}>Full Stack Developer</p>
      </div>
      <div className={css.social}>
        <div className={css.icon}>
          <ProfileIcon icon='website' to='https://jorge.aguirre.sexy' />
        </div>
        <div className={css.icon}>
          <ProfileIcon icon='mail' to='mailto:jorge@aguirre.sexy' />
        </div>
        <div className={css.icon}>
          <ProfileIcon icon='github' to='https://github.com/code-jorge' />
        </div>
        <div className={css.icon}>
          <ProfileIcon icon='twitter' to='https://twitter.com/ja_codes' />
        </div>
        <div className={css.icon}>
          <ProfileIcon icon='linkedin' to='https://www.linkedin.com/in/the-dev/' />
        </div>
        <div className={css.icon}>
          <ProfileIcon icon='instagram' to='https://www.instagram.com/jorch_aguirre' />
        </div>
      </div>
    </div>
  </div>
)

export default Profile