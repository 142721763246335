export const formatNumber = (number)=> {
  if (number < 1000) return number
  if (number < 1_000_000) return `${(number/1000).toFixed(1)}k`
  if (number < 1_000_000_000) return `${(number/1_000_000).toFixed(1)}m`
  if (number < 1_000_000_000_000) return `${(number/1_000_000_000).toFixed(1)}b`
  if (number < 1_000_000_000_000_000) return `${(number/1_000_000_000_000).toFixed(1)}t`
  if (number < 1_000_000_000_000_000_000) return `${(number/1_000_000_000_000_000).toFixed(1)}q`
  if (number < 1_000_000_000_000_000_000_000) return `${(number/1_000_000_000_000_000_000).toFixed(1)}Q`
  if (number < 1_000_000_000_000_000_000_000_000) return `${(number/1_000_000_000_000_000_000_000).toFixed(1)}s`
  if (number < 1_000_000_000_000_000_000_000_000_000) return `${(number/1_000_000_000_000_000_000_000_000).toFixed(1)}S`
  if (number < 1_000_000_000_000_000_000_000_000_000_000) return `${(number/1_000_000_000_000_000_000_000_000_000).toFixed(1)}o`
  if (number < 1_000_000_000_000_000_000_000_000_000_000_000) return `${(number/1_000_000_000_000_000_000_000_000_000_000).toFixed(1)}n`
  if (number < 1_000_000_000_000_000_000_000_000_000_000_000_000) return `${(number/1_000_000_000_000_000_000_000_000_000_000_000).toFixed(1)}d`
  if (number < 1_000_000_000_000_000_000_000_000_000_000_000_000_000) return `${(number/1_000_000_000_000_000_000_000_000_000_000_000_000).toFixed(1)}U`
  if (number < 1_000_000_000_000_000_000_000_000_000_000_000_000_000_000) return `${(number/1_000_000_000_000_000_000_000_000_000_000_000_000_000).toFixed(1)}D`
  if (number < 1_000_000_000_000_000_000_000_000_000_000_000_000_000_000_000) return `${(number/1_000_000_000_000_000_000_000_000_000_000_000_000_000_000).toFixed(1)}T`
  if (number < 1_000_000_000_000_000_000_000_000_000_000_000_000_000_000_000_000) return `${(number/1_000_000_000_000_000_000_000_000_000_000_000_000_000_000_000).toFixed(1)}Qt`
  return number
}