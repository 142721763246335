import React, { useState }    from 'react'
import GameHeader             from '../../components/GameHeader/GameHeader'
import MoreGames              from '../../components/MoreGames/MoreGames'
import css                    from './GuessTheFlag.module.css'
import flags                  from './resources/flags.json'
import { selectRandomFlag }   from './resources/utils'

const LIVES = 5

const GameRules = ()=> (
  <>
    <p className={css.rulesBlock}>
      Guess the flag for the country shown below! Click on one of the available flags. 
      <br />
      Guess as many as you can!
    </p>
  </>
)

const GameOptions = ({ onResetClick })=> (
  <div className={css.options}>
    <button onClick={onResetClick} className={css.optionsButton}>
      Restart
    </button>
  </div>
)

const GuessTheFlag = ()=> {

  const [guessedFlags, setGuessedFlags] = useState([])
  const [isCorrectGuess, setCorrectGuess] = useState(false)
  const [lives, setLives] = useState(LIVES)

  const handleResetGame = ()=> {
    setGuessedFlags([])
    setLives(LIVES)
    setCorrectGuess(false)
  }

  
  const handleFlagClick = (flag)=> {
    if (flag.correct) {
      setGuessedFlags([...guessedFlags, flag.flag])
      setCorrectGuess(true)
    }
    else {
      setLives(l=> l-1)
      setCorrectGuess(false)
    }
  }

  const guess = selectRandomFlag({ flags, guessedFlags })

  const isGameOver = lives < 1 || guess.completed

  return (
    <>
      <GameHeader 
        title='Guess the flag' 
        rules={<GameRules />}
        options={(
          <GameOptions onResetClick={handleResetGame} />
        )}
      />
      <div className={css.main}>
        <div className={css.score}>
          <p className={css.scoreInfo}>
            Score:{' '}
            <span className={css.scoreData}>
              {guessedFlags.length} {guessedFlags.length === 1 ? 'flag' : 'flags'}
              {' '}
              {Array(Math.min(5, Math.floor(guessedFlags.length / 5))).fill('🔥').join('')}
            </span>
          </p>
          {!isGameOver && (
            <p className={css.scoreInfo}>
              Lives:{' '}
              <span className={css.scoreData}>
                {Array(lives).fill('❤️').join('')}
                {Array(LIVES-lives).fill('🤍').join('')}
              </span>
            </p>
          )}
        </div>
        {isGameOver && (
          <>
            <h2 className={css.title}>
              {lives < 1 && (
                <span className={css.feedback} data-status='incorrect'>
                  Game over!
                </span>
              )}
              {guess.completed && (
                <span className={css.feedback} data-status='correct'>
                  Challenge completed!
                </span>
              )}
              {' '}
              <span onClick={handleResetGame} className={css.again}>
                Play again?
              </span>
            </h2>
            {guessedFlags.length > 0 && (
              <>
                <p className={css.result}>
                  Your correctly guessed:
                </p>
                <p className={css.resultScore}>
                  {guessedFlags.map(f=> (
                    <span key={f} className={css.resultFlag}>{f}</span>
                  ))}
                </p>
              </>
            )}
          </>
        )}
        {!isGameOver && (
          <div className={css.game}>
            <h2 className={css.title}>
              {(guessedFlags.length > 0 || lives < LIVES) && (
                <span 
                  className={css.feedback} 
                  data-status={isCorrectGuess ? 'correct' : 'incorrect'}
                >
                  {isCorrectGuess ? 'Correct! ' : 'Incorrect! '}
                </span>
              )}
              Can you identify the flag of <span className={css.country}>{guess.country}</span>?
            </h2>
            <div className={css.flags}>
              {guess.options.map(flag => (
                <button
                  key={flag.flag}
                  className={css.button}
                  onClick={()=> handleFlagClick(flag)}
                >
                  {flag.flag}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
      <MoreGames />
    </>
  )
}

export default GuessTheFlag