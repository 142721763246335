import React                    from 'react'
import GameCard                 from '../../components/GameCard/GameCard'
import ComingSoonCard           from '../../components/ComingSoonCard/ComingSoonCard'
import TicTacToeCover           from '../../games/TicTacToe/resources/cover.jpg'
import SudokuCover              from '../../games/Sudoku/resources/cover.jpg'
import RPSLSCover               from '../../games/RockPaperScissorsLizardSpock/resources/cover.jpg'
import LightSwitchCover         from '../../games/LightSwitch/resources/cover.jpg'
import MemoryCover              from '../../games/Memory/resources/cover.jpg'
import MastermindCover          from '../../games/Mastermind/resources/cover.jpg'
import WordleCover              from '../../games/Wordle/resources/cover.jpg'
import GuessTheFlagCover        from '../../games/GuessTheFlag/resources/cover.jpg'
import LinesOfCodeCover         from '../../games/LinesOfCode/resources/cover.jpg'
import TheEightQueensCover      from '../../games/TheEightQueens/resources/cover.jpg'
import TheJumpingKnightCover    from '../../games/TheJumpingKnight/resources/cover.jpg'
import css                      from './Games.module.css'

const Games = ()=> (
  <div className={css.main}>
    <div className={css.header}>
      <h1 className={css.title}>
        Games, Puzzles, Riddles & Quizzes
      </h1>
      <h2 className={css.subtitle}>by Jorge Aguirre</h2>
    </div>
    <div className={css.cards}>
      <GameCard
        title='The Jumping Knight'
        cover={TheJumpingKnightCover}
        description='Can you go through all the chess squares with your knight?'
        to='/games/the-jumping-knight'
      />
      <GameCard
        title='The 8 Queens'
        cover={TheEightQueensCover}
        description='Can you place the 8 queens on the board?'
        to='/games/the-eight-queens'
      />
      <GameCard
        title='Lines of code'
        cover={LinesOfCodeCover}
        description='Start with an intern, code the universe'
        to='/games/lines-of-code'
      />
      <GameCard
        title='Guess the Flag'
        cover={GuessTheFlagCover}
        description='Identify the flag for every country!'
        to='/games/guess-the-flag'
      />
      <GameCard
        title='Wordle'
        cover={WordleCover}
        description='Guess the 5 letter word in 6 attempts or less'
        to='/games/wordle'
      />
      <GameCard
        title='Mastermind'
        cover={MastermindCover}
        description='Crack the 4 color code in as few guesses as possible'
        to='/games/mastermind'
      />
      <GameCard
        title='Memory'
        cover={MemoryCover}
        description='Find every card pair in the 5x4 board as fast as possible'
        to='/games/memory'
      />
      <GameCard
        title='Light Switch'
        cover={LightSwitchCover}
        description='Make it to the exit with all the room lights turned on'
        to='/games/light-switch'
      />
      <GameCard
        title='Rock Paper Scissors Lizard Spock'
        cover={RPSLSCover}
        description='A more complex variant of rock, paper, scissors.'
        to='/games/rock-paper-scissors-lizard-spock'
      />
      <GameCard
        title='Sudoku'
        cover={SudokuCover}
        description='A simple, randomly generated Sudoku board.'
        to='/games/sudoku'
      />
      <GameCard
        title='Tic Tac Toe'
        cover={TicTacToeCover}
        description='A simple, tic-tac-toe game, for 1 or 2 players.'
        to='/games/tic-tac-toe'
      />
      <ComingSoonCard />
    </div>
  </div>
)

export default Games