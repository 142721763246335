import React, { useState }                    from 'react'
import ConceptHeader                          from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts                         from '../../components/BackToConcepts/BackToConcepts'
import CodeArea, { CodeLine, FragmentUtils }  from '../../components/CodeArea/CodeArea'
import css                                    from './MongoTimestamps.module.css'

const getOID = date=> ("00000000" + Math.floor(date.getTime() / 1000).toString(16) + "0000000000000000").substr(-24)
const getDate = oid=> new Date(parseInt(oid.substring(0, 8), 16) * 1000)

const MongoTimestamps = ()=> {

  const [date, setDate] = useState(new Date())
  const [oid, setOid] = useState(getOID(date))

  const handleDateChange = field=> e=> {
    const new_date = new Date(date)
    const value = e.target.value
    if (field === 'year') new_date.setFullYear(value)
    if (field === 'month') new_date.setMonth(value - 1)
    if (field === 'date') new_date.setDate(value)
    if (field === 'hours') new_date.setHours(value)
    if (field === 'minutes') new_date.setMinutes(value)
    if (field === 'seconds') new_date.setSeconds(value)
    const final_date = new Date(Math.max(new_date.getTime(), 1))
    setDate(final_date)
    setOid(getOID(final_date))
  }

  const handleObjectIdChange = e=> {
    const new_id = e.target.value
    setOid(new_id)
    if (!e.target.checkValidity()) return
    const new_date = getDate(new_id)
    setDate(new_date)
  }

  return (
    <React.Fragment>
      <ConceptHeader title='Mongo Timestamps & Object Ids' />
      <div className={css.main}>
        <p className={css.block}>
          MongoDB uses an indexing system based on ObjectIds, which are formed of 12 bytes. They are unique within a MongoDB 
          collection (as all indexes should be), and to ensure their uniqueness they use a combination of a timestamp, an incrementing 
          counter and random numbers.
        </p>
        <p className={css.block}>
          If you maintain the ObjectId as the document's index in the MongoDB collections (it's possible to remove it 
          and use a different field for indexing), then, you have the timestamp information within those 12 bytes. More accurately,
          the first 4 bytes contain the timestamp of the creation of the document.
        </p>
        <p className={css.block}>
          This is useful if you need to review when documents were inserted (the ObjectId will <strong>not</strong> change 
          on document updates), or find all documents created within a time period.
        </p>
        <CodeArea>
          <CodeLine fragments={FragmentUtils.JS.comment('Get all documents created after 2020')} />
          <CodeLine fragments={[
            { content: 'db', color: 'green-2' },
            { content: '.' },
            { content: 'records', color: 'orange' },
            { content: '.' },
            { content: 'find', color: 'blue' },
            { content: '(', color: 'blue' },
            { content: '{ ' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: '_id', color: 'wood' },
            { content: ': ' },
            { content: '{ ' },
            { content: '$gt', color: 'red' },
            { content: ': ' },
            { content: 'ObjectId(', color: 'yellow' },
            { content: '"5e0bd2f00000000000000000"', color: 'green' },
            { content: ') ', color: 'yellow' },
            { content: '}' },
          ]} />
          <CodeLine fragments={[
            { content: '}' },
            { content: ')', color: 'blue' },
          ]} />
        </CodeArea>
        <p className={css.block}>
          The useful part is that the timestamp is directly stored in the 4 most significant bytes of the ObjectId, so, the rest
          of the identifier can be set to zero, and, it's easy to use in a query (as shown above). Here's a small tool to convert 
          dates to MongoDB's ObjectId format!
        </p>
      </div>
      <div className={css.calculator}>
        <div className={css.id}>
          <div className={css.input}>
            <label>ObjectId value</label>
            <input pattern='[a-fA-F0-9]{24}' value={oid} onChange={handleObjectIdChange} />
          </div>
          <div className={css.input}>
            <label className={css.readonly}>MongoDB's ObjectId</label>
            <input readOnly value={`ObjectId("${oid}")`} />
          </div>
        </div>
        <div className={css.date}>
          <div className={css.input}>
            <label>Year</label>
            <input onChange={handleDateChange('year')} type='number' value={date.getFullYear()} />
          </div>
          <div className={css.input}>
            <label>Month</label>
            <input onChange={handleDateChange('month')} type='number' value={date.getMonth()+1} />
          </div>
          <div className={css.input}>
            <label>Date</label>
            <input onChange={handleDateChange('date')} type='number' value={date.getDate()} />
          </div>
          <div className={css.input}>
            <label>Hours</label>
            <input onChange={handleDateChange('hours')} type='number' value={date.getHours()} />
          </div>
          <div className={css.input}>
            <label>Minutes</label>
            <input onChange={handleDateChange('minutes')} type='number' value={date.getMinutes()} />
          </div>
          <div className={css.input}>
            <label>Seconds</label>
            <input onChange={handleDateChange('seconds')} type='number' value={date.getSeconds()} />
          </div>
        </div>
      </div>
      <div className={css.main}>
        <p className={css.block}>
          You can change the ObjectId to see the correspnding date or, modify any of the date values
          to see the equivalent ObjectId! The date values can't go earliear than 1970, since this is when 
          the Unix timestamp starts counting!
        </p>
      </div>
      <BackToConcepts />
    </React.Fragment>
  )
}

export default MongoTimestamps