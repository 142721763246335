import React              from 'react'
import { Link }           from 'react-router-dom'
import styles             from './GameCard.module.css'

const GameCard = ({ to, cover, title,  description })=> (
  <div className={styles.card}>
    <Link to={to} className={styles.header}>
      <img className={styles.image} alt={title} src={cover} />
    </Link>
    <Link to={to} className={styles.title}>{title}</Link>
    <p className={styles.description}>{description}</p>
  </div>
)

export default GameCard