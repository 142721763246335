export const getRemainingFlags = ({ flags, guessedFlags })=> {
  return flags.filter(({ flag })=> !guessedFlags.includes(flag)) 
}

export const selectRandomFlag = ({ flags, guessedFlags })=> {
  const remainingFlags = getRemainingFlags({ flags, guessedFlags })
  if (remainingFlags.length === 0) return { completed: true }
  const selectedFlag = remainingFlags[Math.floor(Math.random() * remainingFlags.length)]
  // Get up to three alternatives
  let alternatives = []
  let options = remainingFlags.filter(({ country })=> country !== selectedFlag.country)
  if (options.length < 3) alternatives = [...options]
  else {
    while (alternatives.length < 3) {
      const index = Math.floor(Math.random() * options.length)
      alternatives.push(options[index])
      options = options.filter((_, i)=> i !== index)
    }
  }
  // Randomly select the order
  const offset = Math.floor(Math.random() * 4)
  const order = [
    ...alternatives.slice(0, offset).map(f=> ({ flag: f.flag, correct: false })),
    { ...selectedFlag, correct: true },
    ...alternatives.slice(offset).map(f=> ({ flag: f.flag, correct: false })),
  ]
  // Return the data
  return {
    completed: false,
    country: selectedFlag.country, 
    options: order 
  }
}