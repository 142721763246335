import React                from 'react'
import MovingParticles      from 'react-tsparticles'

import angular              from './icons/angular.png'
import analytics            from './icons/analytics.png'
import atom                 from './icons/atom.png'
import aws                  from './icons/aws.png'
import bootstrap            from './icons/bootstrap.png'
import css3                 from './icons/css3.png'
import eclipse              from './icons/eclipse.png'
import git                  from './icons/git.png'
import gitkraken            from './icons/gitkraken.png'
import gitlab               from './icons/gitlab.png'
import gulp                 from './icons/gulp.png'
import html5                from './icons/html5.png'
import java                 from './icons/java.png'
import jenkins              from './icons/jenkins.png'
import nodejs               from './icons/nodejs.png'
import npm                  from './icons/npm.png'
import pug                  from './icons/pug.png'
import python               from './icons/python.png'
import react                from './icons/react.png'
import redis                from './icons/redis.png'
import redux                from './icons/redux.png'
import runnable             from './icons/runnable.png'
import spring               from './icons/spring.png'
import stylus               from './icons/stylus.png'
import webpack              from './icons/webpack.png'
import d3                   from './icons/d3.png'
import docker               from './icons/docker.png'
import markdown             from './icons/markdown.png'
import trello               from './icons/trello.png'
import jira                 from './icons/jira.png'
import slack                from './icons/slack.png'
import bitbucket            from './icons/bitbucket.png'
import kibana               from './icons/kibana.png'
import storybook            from './icons/storybook.png'
import config               from './particles-config.json'
import styles               from './Particles.module.css'

const params = {
  particles: {
    ...config.particles,
    shape: {
      type: 'image',
      image: [
          { src: analytics, width: 25, height: 25 },
          { src: angular, width: 23.7, height: 25 },
          { src: atom, width: 25, height: 25 },
          { src: aws, width: 19.68, height: 25 },
          { src: bitbucket, width: 25, height: 29 },
          { src: bootstrap, width: 25, height: 25 },
          { src: css3, width: 25, height: 25 },
          { src: d3, width: 25, height: 25 },
          { src: docker, width: 25, height: 23.5 },
          { src: eclipse, width: 25, height: 23.44 },
          { src: git, width: 25, height: 25 },
          { src: gitkraken, width: 25, height: 21.88 },
          { src: gitlab, width: 25, height: 23 },
          { src: gulp, width: 9, height: 20 },
          { src: html5, width: 17.71, height: 25 },
          { src: java, width: 18.5, height: 25 },
          { src: jenkins, width: 15.35, height: 25 },
          { src: jira, width: 25, height: 10 },
          { src: kibana, width: 25, height: 25 },
          { src: markdown, width: 25, height: 15.5 },
          { src: nodejs, width: 22.7, height: 25 },
          { src: npm, width: 25, height: 10 },
          { src: pug, width: 25, height: 25 },
          { src: python, width: 25, height: 25 },
          { src: react, width: 30, height: 30 },
          { src: redis, width: 25, height: 21.48 },
          { src: redux, width: 25, height: 23.83 },
          { src: runnable, width: 25, height: 21 },
          { src: slack, width: 25, height: 25 },
          { src: spring, width: 25, height: 25 },
          { src: storybook, width: 25, height: 7 },
          { src: stylus, width: 25, height: 15.38 },
          { src: trello, width: 25, height: 25 },
          { src: webpack, width: 25, height: 25 },
        ]
    },
  }
}

const Particles = ({ className })=> (
  <MovingParticles
    className={`${styles.main} ${className}`}
    params={params}
  />
)

export default Particles