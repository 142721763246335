import React  from 'react'
import styles from './Cover.module.css'

const Cover = ()=> (
  <div className={styles.main}>
    <div className={styles.box}>
      <code className={styles.code}>box-shadow</code>
    </div>
  </div>
)

export default Cover