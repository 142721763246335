import React  from 'react'
import styles from './ShowResult.module.css'

const ShowResultDetail = ({ first, middle, second, result })=> (
  <div className={styles.main}>
    <p className={styles.result}>You {result}!
    {' '}
    <span className={styles.detail}>
      {'('}{first}{' '}{middle}{' '}{second}{')'}
    </span>
    </p>
  </div>
)

const ShowResultActive = ()=> (
  <div className={styles.main}>
    <p className={styles.result}>Make your choice!</p>
  </div>
)

const ShowResult = ({playerSelection, systemSelection})=> {

  if (!playerSelection || !systemSelection) return <ShowResultActive />

  if (playerSelection === systemSelection) 
    return <ShowResultDetail first={playerSelection} second={systemSelection} middle='ties' result='tie' />
  //Case II: SCISSORS VS PAPER
  else if (playerSelection === 'scissors' && systemSelection === 'paper') 
    return <ShowResultDetail first='scissors' second='paper' middle='cuts' result='win' />
  else if (systemSelection === 'scissors' && playerSelection === 'paper') 
    return <ShowResultDetail first='scissors' second='paper' middle='cuts' result='lose' />
  // Case III: PAPER VS ROCK
  else if (playerSelection === 'paper' && systemSelection === 'rock') 
    return <ShowResultDetail first='paper' second='rock' middle='covers' result='win' />
  else if (systemSelection === 'paper' && playerSelection === 'rock') 
    return <ShowResultDetail first='paper' second='rock' middle='covers' result='lose' />
  // Case IV: ROCK VS LIZARD
  else if (playerSelection === 'rock' && systemSelection === 'lizard') 
    return <ShowResultDetail first='rock' second='lizard' middle='crushes' result='win' />
  else if (systemSelection === 'rock' && playerSelection === 'lizard') 
    return <ShowResultDetail first='rock' second='lizard' middle='crushes' result='lose' />
  // Case V: LIZARD VS SPOCK
  else if (playerSelection === 'lizard' && systemSelection === 'spock') 
    return <ShowResultDetail first='lizard' second='spock' middle='poisons' result='win' />
  else if (systemSelection === 'lizard' && playerSelection === 'spock') 
    return <ShowResultDetail first='lizard' second='spock' middle='poisons' result='lose' />
  // Case V: SPOCK VS SCISSORS
  else if (playerSelection === 'spock' && systemSelection === 'scissors') 
    return <ShowResultDetail first='spock' second='scissors' middle='smashes' result='win' />
  else if (systemSelection === 'spock' && playerSelection === 'scissors') 
    return <ShowResultDetail first='spock' second='scissors' middle='smashes' result='lose' />
  // Case VI: SCISSORS VS LIZARD
  else if (playerSelection === 'scissors' && systemSelection === 'lizard') 
    return <ShowResultDetail first='scissors' second='lizard' middle='decapitates' result='win' />
  else if (systemSelection === 'scissors' && playerSelection === 'lizard') 
    return <ShowResultDetail first='scissors' second='lizard' middle='decapitates' result='lose' />
  // Case VII: LIZARD VS PAPER
  else if (playerSelection === 'lizard' && systemSelection === 'paper') 
    return <ShowResultDetail first='lizard' second='paper' middle='eats' result='win' />
  else if (systemSelection === 'lizard' && playerSelection === 'paper') 
    return <ShowResultDetail first='lizard' second='paper' middle='eats' result='lose' />
  // Case VIII: PAPER VS SPOCK
  else if (playerSelection === 'paper' && systemSelection === 'spock') 
    return <ShowResultDetail first='paper' second='spock' middle='disproves' result='win' />
  else if (systemSelection === 'paper' && playerSelection === 'spock') 
    return <ShowResultDetail first='paper' second='spock' middle='disproves' result='lose' />
  // Case IX: SPOCK VS ROCK
  else if (playerSelection === 'spock' && systemSelection === 'rock') 
    return <ShowResultDetail first='spock' second='rock' middle='vaporizes' result='win' />
  else if (systemSelection === 'spock' && playerSelection === 'rock') 
    return <ShowResultDetail first='spock' second='rock' middle='vaporizes' result='lose' />
  // Case X: ROCK VS SCISSORS
  else if (playerSelection === 'rock' && systemSelection === 'scissors') 
    return <ShowResultDetail first='rock' second='scissors' middle='crushes' result='win' />
  else if (systemSelection === 'rock' && playerSelection === 'scissors') 
    return <ShowResultDetail first='rock' second='scissors' middle='crushes' result='lose' />
}


export default ShowResult