import React  from 'react'
import Heart  from '../../elements/Heart/Heart'
import styles from './ComingSoonCard.module.css'

const ComingSoonCard = ({ text='More content soon' })=> (
  <div className={styles.card}>
    <div className={styles.clock} />
    <div className={styles.title}>
      {text}
    </div>
    <Heart />
  </div>
)

export default ComingSoonCard