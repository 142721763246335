import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react"
import { ethers } from "ethers"

const Web3IdentityContext = createContext()

export const Web3IdentityProvider = ({ children })=> {

  const providerRef = useRef()

  const [currentAccount, setCurrentAccount] = useState("")
  const [currentChain, setCurrentChain] = useState("")

  const isWeb3WalletAvailable = !!window.ethereum

  const configureWeb3Details = useCallback(async () => {
    if (!window.ethereum) return
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const accounts = await window.ethereum.request({ method: 'eth_accounts' })
    // Account handling
    if (accounts.length !== 0) {
      const account = accounts[0]
      setCurrentAccount(account)
    }
    // Network handling
    const network = await provider.getNetwork()
    if (network) {
      const chainId = network.chainId
      setCurrentChain(`${chainId}`)
    }
    providerRef.current = provider
  }, [])

  useEffect(()=> {
    configureWeb3Details() 
  }, [configureWeb3Details])

  useEffect(()=> {
    if (window.ethereum) {
      window.ethereum.on('chainChanged', configureWeb3Details) 
      window.ethereum.on('accountsChanged', configureWeb3Details)
    }
    return () => {
      if (!providerRef.current) return
      providerRef.current.off('chainChanged') 
      providerRef.current.off('accountsChanged') 
    }
  }, [configureWeb3Details])

  const connectWallet = async ()=> {
    try {
      const { ethereum } = window
      if (ethereum) {
        const accounts = await ethereum.request({ method: "eth_requestAccounts" })
        setCurrentAccount(accounts[0]) 
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Web3IdentityContext.Provider 
      value={{
        account: currentAccount,
        chain: currentChain,
        isWeb3WalletAvailable,
        connectWallet,
      }}
    >
      {children}
    </Web3IdentityContext.Provider>
  )

}

export const useWeb3Identity = () => useContext(Web3IdentityContext)