import React, { useState, useEffect } from 'react'
import styles                         from './Heart.module.css'

const Heart = ()=> {

  const [active, setActive] = useState(false)

  useEffect(()=> {
    if (active) {
      const timeout = setTimeout(()=> setActive(false), 4500)
      return ()=> clearTimeout(timeout)
    }
  }, [active])

  return (
    <div
      className={styles.heart}
      data-status={active ? 'active' : 'off'}
      onClick={()=> setActive(true)}
    />
  )
}

export default Heart