export const getAttemptStatus = ({ attempt, word })=> {
  const letters = attempt.toLowerCase().split('')
  const status = [ 'unknown', 'unknown', 'unknown', 'unknown', 'unknown' ]
  const solution = word.toLowerCase().split('')
  // First get the correct letters
  letters.forEach((letter, index)=> {
    if (solution[index] === letter) status[index] = 'correct'
  })
  const remainingSolution = solution.filter((_letter, index)=> status[index] !== 'correct')
  // Take the incorrect letters
  letters.forEach((letter, index, letters)=> {
    if (status[index] === 'correct') return
    const letterIndex = letters.filter((l, i)=> {
      if (l !== letter) return false
      if (status[i] === 'correct') return false
      if (i >= index) return false
      return true
    }).length + 1
    const remainingSolutionLetter = remainingSolution.filter(l=> l === letter).length
    if (remainingSolutionLetter >= letterIndex) status[index] = 'misplaced'
    else status[index] = 'incorrect'
  })
  return status
}

export const getKeyStatus = ({ attempts, solution })=> {
  const keyboard = 'abcdefghijklmnñopqrstuvwxyz'.split('').map(letter=> ({ letter, status: 'unknown' }))
  attempts.forEach(attempt=> {
    const letters = attempt.toLowerCase().split('')
    const status = getAttemptStatus({ attempt, word: solution })
    letters.forEach((letter, index)=> {
      if (status[index] === 'correct') {
        keyboard.map(key=> {
          if (key.letter === letter) key.status = status[index]
          return key
        })
      }
      else if (status[index] === 'misplaced') {
        // We only put misplaced if not "correct"
        keyboard.map(key=> {
          if (key.letter === letter && key.status !== 'correct') key.status = status[index]
          return key
        })
      }
      else if (status[index] === 'incorrect') {
        // We only put misplaced if not "correct" or "misplaced"
        keyboard.map(key=> {
          if (key.letter === letter && !['correct', 'misplaced'].includes(key.status)) key.status = status[index]
          return key
        })
      }
    })
  })
  return keyboard
}