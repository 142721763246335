import css from './TwitterCredit.module.css'

const TwitterCredit = ({ link, handle })=> (
  <a 
    className={css.credit}
    href={link}
    target='_blank'
    rel='noopener noreferrer'
  >
    <div className={css.content}>
      <svg className={css.logo} viewBox="-20 -20 296 296">
        <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
          <path 
            fill='white'
            d="M 0.219 2.882 l 34.748 46.461 L 0 87.118 h 7.87 l 30.614 -33.073 l 24.735 33.073 H 90 L 53.297 38.043 L 85.844 2.882 h -7.87 L 49.781 33.341 L 27.001 2.882 H 0.219 z M 11.793 8.679 h 12.303 L 78.425 81.32 H 66.122 L 11.793 8.679 z"
          />
        </g>
      </svg>
      <span className={css.handle}>{handle}</span>
    </div>
  </a>
)

export default TwitterCredit