import React                                from 'react'

import ConceptCard                          from '../../components/ConceptCard/ConceptCard'
import ComingSoonCard                       from '../../components/ComingSoonCard/ComingSoonCard'

import MernStackCover                       from '../../concepts/MERNStack/Cover'
import ReactHooksCover                      from '../../concepts/ReactHooks/Cover'
import ReactPortalsCover                    from '../../concepts/ReactPortals/Cover'
import TextCSSCover                         from '../../concepts/TextCSS/Cover'
import SliderImageTransitionCover           from '../../concepts/SliderImageTransition/Cover'
import MongoExtractorCover                  from '../../concepts/MongoExtractor/Cover'
import ImageASCIICover                      from '../../concepts/ImageASCII/Cover'
import VideoASCIICover                      from '../../concepts/VideoASCII/Cover'
import CopyingClipboardCover                from '../../concepts/CopyingClipboard/Cover'
import ArtASCIICover                        from '../../concepts/ArtASCII/Cover'
import NeonLightsCover                      from '../../concepts/NeonLights/Cover'
import SpeechSynthesiserCover               from '../../concepts/SpeechSynthesiser/Cover'
import SpeechParserCover                    from '../../concepts/SpeechParser/Cover'
import ScrollableFilterCover                from '../../concepts/ScrollableFilter/Cover'
import FlipTextCover                        from '../../concepts/FlipText/Cover'
import MayTheForceCover                     from '../../concepts/MayTheForce/Cover'
import DrawingCanvasCover                   from '../../concepts/DrawingCanvas/Cover'
import ProductionWorksCover                 from '../../concepts/ProductionWorks/Cover'
import FontsMatterCover                     from '../../concepts/FontsMatter/Cover'
import BoxShadowGeneratorCover              from '../../concepts/BoxShadowGenerator/Cover'
import DestructuringJavascriptCover         from '../../concepts/DestructuringJavascript/Cover'
import MongoTimestampsCover                 from '../../concepts/MongoTimestamps/Cover'
import TypewriterEffectCover                from '../../concepts/TypewriterEffect/Cover'
import ShakersBrandCover                    from '../../concepts/ShakersBrand/Cover'
import TwitterHeaderCover                   from '../../concepts/TwitterHeader/Cover'
import MouseTrackingSlowpokeCover           from '../../concepts/MouseTrackingSlowpoke/Cover'
import RageClicksCover                      from '../../concepts/RageClicks/Cover'
import FindTheMonkeyCover                   from '../../concepts/FindTheMonkey/Cover'
import CreatingNFTCover                     from '../../concepts/CreatingNFT/Cover'
import DynamicNFTsCover                     from '../../concepts/DynamicNFTs/Cover'
import ColorGridCover                       from '../../concepts/ColorGrid/Cover'
import OldPhoneCover                        from '../../concepts/OldPhone/Cover'
import css                                  from './Concepts.module.css'

const Concepts = ()=> (
  <div className={css.main}>
    <div className={css.header}>
      <h1 className={css.title}>
        Reasonable challenges & ideas
      </h1>
      <h2 className={css.subtitle}>by Jorge Aguirre</h2>
    </div>
    <div className={css.cards}>
      <ConceptCard
        content={<OldPhoneCover />}
        title='Old Phone'
        tag='JavaScript'
        date='18/01/2023'
        to='/concepts/old-phone'
      />
      <ConceptCard
        content={<ColorGridCover />}
        title='Color Grid'
        tag='CSS'
        date='14/09/2022'
        to='/concepts/color-grid'
      />
      <ConceptCard
        content={<DynamicNFTsCover />}
        title='Creating a dynamic NFT'
        tag='Web 3'
        date='17/07/2022'
        to='/concepts/dynamic-nfts'
      />
      <ConceptCard
        content={<CreatingNFTCover />}
        title='How I created my first NFT'
        tag='Web 3'
        date='28/06/2022'
        to='/concepts/creating-an-nft'
      />
      <ConceptCard
        content={<FindTheMonkeyCover />}
        title='Find the monkey'
        tag='JavaScript'
        date='15/05/2022'
        to='/concepts/find-the-monkey'
      />
      <ConceptCard
        content={<RageClicksCover />}
        title='Measuring rage clicks'
        tag='JavaScript'
        date='25/01/2022'
        to='/concepts/measuring-rage-clicks'
      />
      <ConceptCard
        content={<MouseTrackingSlowpokeCover />}
        title='Mouse Tracking Slowpoke'
        tag='JavaScript'
        date='29/05/2021'
        to='/concepts/mouse-tracking-slowpoke'
      />
      <ConceptCard
        content={<TwitterHeaderCover />}
        title='I built a Twitter header using CSS'
        tag='CSS'
        date='17/03/2021'
        to='/concepts/twitter-header'
      />
      <ConceptCard
        content={<ShakersBrandCover />}
        title='The rebranding of Shakers XYZ'
        tag='Agile'
        date='08/03/2021'
        to='/concepts/shakers-xyz-brand'
      />
      <ConceptCard
        content={<TypewriterEffectCover />}
        title='Creating a Typewriter effect in React'
        tag='JavaScript'
        date='20/02/2021'
        to='/concepts/typewriter-effect'
      />
      <ConceptCard
        content={<MongoTimestampsCover />}
        title='Getting MongoDB timestamps from an ObjectId'
        tag='MongoDB'
        date='05/02/2021'
        to='/concepts/mongo-timestamps'
      />
      <ConceptCard
        content={<DestructuringJavascriptCover />}
        title='How destructuring works in JavaScript'
        tag='JavaScript'
        date='30/01/2021'
        to='/concepts/destructuring-in-javascript'
      />
      <ConceptCard
        content={<BoxShadowGeneratorCover />}
        title='Box shadow generator'
        tag='CSS'
        date='25/01/2021'
        to='/concepts/box-shadow-generator'
      />
      <ConceptCard
        content={<FontsMatterCover />}
        title='Picking the right font for your project'
        tag='CSS'
        date='10/01/2021'
        to='/concepts/fonts-matter'
      />
      <ConceptCard
        content={<ProductionWorksCover />}
        title='How production works'
        tag='Agile'
        date='27/12/2020'
        to='/concepts/how-production-works'
      />
      <ConceptCard
        content={<DrawingCanvasCover />}
        title='Freestyle drawing in a canvas'
        tag='JavaScript'
        date='20/12/2020'
        to='/concepts/drawing-in-canvas'
      />
      <ConceptCard
        content={<MayTheForceCover />}
        title='May the 4th be with you'
        tag='JavaScript'
        date='13/12/2020'
        to='/concepts/may-the-force-be-with-you'
      />
      <ConceptCard
        content={<FlipTextCover />}
        title='Flip a text upside down!'
        tag='JavaScript'
        date='06/12/2020'
        to='/concepts/flip-text'
      />
      <ConceptCard
        content={<ScrollableFilterCover />}
        title='Apply a CSS filter on an image by scrolling'
        tag='CSS'
        date='29/11/2020'
        to='/concepts/scrollable-css-filter'
      />
      <ConceptCard
        content={<SpeechParserCover />}
        title='Speech Parser using Web APIs'
        tag='JavaScript'
        date='22/11/2020'
        to='/concepts/speech-parser'
      />
      <ConceptCard
        content={<SpeechSynthesiserCover />}
        title='Speech Synthesiser using Web APIs'
        tag='JavaScript'
        date='15/11/2020'
        to='/concepts/speech-synthesiser'
      />
      <ConceptCard
        content={<NeonLightsCover />}
        title='Neon Lights Effect with CSS and a custom font'
        tag='CSS'
        date='08/11/2020'
        to='/concepts/neon-lights'
      />
      <ConceptCard
        content={<ArtASCIICover />}
        title='ASCII art with the name "Jorge Aguirre"'
        tag='CSS'
        date='01/11/2020'
        to='/concepts/ascii-art-jorge'
      />
      <ConceptCard
        content={<CopyingClipboardCover />}
        title='Copying to the clipboard with JS'
        tag='JavaScript'
        date='25/10/2020'
        to='/concepts/copying-to-clipboard-js'
      />
      <ConceptCard
        content={<VideoASCIICover />}
        title='Transforming video to ASCII characters'
        tag='JavaScript'
        date='18/10/2020'
        to='/concepts/video-to-ascii-characters'
      />
      <ConceptCard
        content={<ImageASCIICover />}
        title='Transforming image to ASCII characters'
        tag='JavaScript'
        date='11/10/2020'
        to='/concepts/image-to-ascii-characters'
      />
      <ConceptCard
        content={<MongoExtractorCover />}
        title='Extracting data from MongoDB with a script'
        tag='MongoDB'
        date='04/10/2020'
        to='/concepts/mongodb-extractor-script'
      />
      <ConceptCard
        content={<SliderImageTransitionCover />}
        title='Using a slider for image transition'
        tag='CSS'
        date='27/09/2020'
        to='/concepts/using-slider-for-image-transition'
      />
      <ConceptCard
        content={<ReactPortalsCover />}
        title='Connecting data with portals in React'
        tag='JavaScript'
        date='20/09/2020'
        to='/concepts/connecting-data-react-portals'
      />
      <ConceptCard
        content={<ReactHooksCover />}
        title='Getting started with React hooks'
        tag='JavaScript'
        date='13/09/2020'
        to='/concepts/react-hooks-intro'
      />
      <ConceptCard
        content={<TextCSSCover />}
        title='Amazing text decoration effects using only CSS'
        tag='CSS'
        date='06/09/2020'
        to='/concepts/text-effects-using-css-only'
      />
      <ConceptCard
        content={<MernStackCover />}
        title='My reason for choosing the MERN stack'
        tag='JavaScript'
        date='30/08/2020'
        to='/concepts/reason-mern-stack'
      />
      <ComingSoonCard />
    </div>
  </div>
)

export default Concepts