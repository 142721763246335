import React, { useState }  from 'react'
import ContactForm          from './resources/ContactForm'
import ContactFormIcon      from './resources/ContactFormIcon'
import { encode }           from '../../utils/form'
import styles               from './Contact.module.css'


const ContactRobot = ({ className })=> (
  <svg className={className} viewBox="0 0 464.976 464.976">
    <path d="M177.928,140.176c-19.131,0-34.64,15.509-34.64,34.64c0,19.131,15.509,34.64,34.64,34.64s34.64-15.509,34.64-34.64 C212.568,155.685,197.059,140.176,177.928,140.176z M178.008,193.456c-10.295,0-18.64-8.345-18.64-18.64 c0-10.295,8.345-18.64,18.64-18.64c10.295,0,18.64,8.345,18.64,18.64C196.648,185.111,188.303,193.456,178.008,193.456z"/>
    <path d="M286.968,140.176c-19.131,0-34.64,15.509-34.64,34.64s15.509,34.64,34.64,34.64c19.131,0,34.64-15.509,34.64-34.64 C321.608,155.685,306.099,140.176,286.968,140.176z M286.968,193.536c-10.295,0-18.64-8.345-18.64-18.64 c0-10.295,8.345-18.64,18.64-18.64c10.295,0,18.64,8.345,18.64,18.64C305.608,185.191,297.263,193.536,286.968,193.536z"/>
    <path d="M286.968,245.936h-108.96c-4.418,0-8,3.582-8,8c0,4.418,3.582,8,8,8h108.96c4.418,0,8-3.582,8-8 C294.968,249.518,291.386,245.936,286.968,245.936z"/>
    <path d="M359.448,378.016l0-0.56h-52.4v-53.84h34.72c9.323,0,16.88-7.557,16.88-16.88v-44 c27.106-4.251,45.634-29.672,41.382-56.778c-3.343-21.317-20.065-38.039-41.382-41.382v-44.08c0-9.323-7.557-16.88-16.88-16.88 h-72l25.84-48c15.16,3.471,30.263-6.006,33.734-21.166c3.471-15.16-6.006-30.263-21.166-33.734 c-15.16-3.47-30.263,6.006-33.734,21.166c-2.111,9.224,0.551,18.891,7.086,25.734l-29.92,56h-38.24l-30-55.52 c10.862-11.315,10.495-29.293-0.82-40.155c-11.315-10.862-29.293-10.495-40.155,0.82c-10.862,11.315-10.495,29.293,0.82,40.155 c6.921,6.644,16.729,9.345,26.075,7.18l25.84,48h-72c-9.323,0-16.88,7.557-16.88,16.88v0v44.08 c-27.106,4.251-45.634,29.672-41.382,56.778c3.343,21.317,20.065,38.039,41.382,41.382v44.08c0,9.323,7.557,16.88,16.88,16.88h0 h34.72v53.84h-52.32c-24.013,0-43.48,19.467-43.48,43.48c0,24.013,19.467,43.48,43.48,43.48h253.92 c24.013,0,43.48-19.467,43.48-43.48C402.928,397.483,383.461,378.016,359.448,378.016z M358.568,180.816 c18.115,4.415,29.221,22.679,24.806,40.794c-2.986,12.253-12.553,21.82-24.806,24.806V180.816z M290.796,22.668 c2.078-4.016,6.211-6.547,10.733-6.572c6.716-0.037,12.19,5.376,12.227,12.092s-5.377,12.19-12.092,12.227 c-1.967,0.011-3.908-0.456-5.655-1.36C290.043,35.97,287.71,28.633,290.796,22.668z M169.688,38.656v-0.32 c-5.574,3.746-13.129,2.265-16.876-3.309s-2.265-13.129,3.309-16.876c2.159-1.451,4.727-2.172,7.327-2.056 c6.716,0.034,12.133,5.505,12.099,12.221C175.526,32.548,173.307,36.464,169.688,38.656z M106.408,246.336 c-18.115-4.415-29.221-22.679-24.806-40.794c2.986-12.253,12.553-21.82,24.806-24.806V246.336z M123.288,307.616v-0.08 c-0.486,0-0.88-0.394-0.88-0.88v-186.16c0-0.486,0.394-0.88,0.88-0.88h218.4c0.486,0,0.88,0.394,0.88,0.88v186.24 c0,0.486-0.394,0.88-0.88,0.88H123.288z M291.288,323.536v53.84h-117.36v-53.76L291.288,323.536z M359.448,448.096h-253.92 c-15.177,0-27.48-12.303-27.48-27.48c0-15.177,12.303-27.48,27.48-27.48h253.92c15.177,0,27.48,12.303,27.48,27.48 C386.928,435.793,374.625,448.096,359.448,448.096z"/>
  </svg>
)
const ContactFormResult = ({ formStatus })=> {
  if (formStatus === 'idle') return null
  return (
    <div className={styles.options}>
      <ContactRobot className={styles.resultRobot} />
      <p className={styles.result}>
        {formStatus === 'success' && 'Your message was successfully delivered'}
        {formStatus === 'error' && 'There was an error delivering your message'}
        {formStatus === 'pending' && 'Your message is on it\'s way'}
      </p>
    </div>
  )
}

const Contact = ()=> {

  const [form, setForm] = useState({ email: '', message: '' })
  const [formStatus, setFormStatus] = useState('idle')

  const handleContactFormSubmit = event=> {
    event.preventDefault()
    if (formStatus !== 'idle') return
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...form })
    })
      .then(()=> {
        setFormStatus('success')
        setForm({ email: '', message: '' })
      })
      .catch(error => {
        setFormStatus('error')
        console.error(error)
      })
    setFormStatus('pending')
  }

  return (
    <div className={styles.contact}>
      <h1 className={styles.title}>
        <ContactRobot className={styles.robot} />
        Let's talk
      </h1>
      <div className={styles.content}>
        <div className={styles.form}>
          <div className={styles.main}>
            <ContactForm 
              name={form.name}
              email={form.email}
              message={form.message}
              status={formStatus}
              onChange={(field, value)=> setForm({...form, [field]: value })}
              onSubmit={handleContactFormSubmit}
            />
          </div>
          <ContactFormResult formStatus={formStatus} />
        </div>
        <div className={styles.social}>
          <ContactFormIcon icon='twitter' to='https://twitter.com/ja_codes' />
          <ContactFormIcon icon='linkedin' to='https://www.linkedin.com/in/the-dev/' />
          <ContactFormIcon icon='github' to='https://github.com/code-jorge' />
          <ContactFormIcon icon='instagram' to='https://www.instagram.com/jorch_aguirre' />
          <ContactFormIcon icon='mail' to='mailto:jorge@aguirre.sexy' />
        </div>
      </div>
    </div>
  )
}

export default Contact