import React, { useEffect, useState }           from 'react'
import ConceptHeader                            from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts                           from '../../components/BackToConcepts/BackToConcepts'
import Slowpoke                                 from './resources/Slowpoke'
import cursor                                   from './resources/cursor.png'
import css                                      from './MouseTrackingSlowpoke.module.css'

const MouseTrackingSlowpoke = ()=> {

  const [mousePosition, setMousePosition] = useState({ x: 50, y: 50})

  const getMetrics = ()=> {

    const left_x_data = { start: -5, size: .3 }
    const left_y_data = { start: -6, size: .58 }
    const right_x_data = { start: -12, size: 1 }
    const right_y_data = { start: -6, size: .58 }

    const leftX = (mousePosition.x/4)*left_x_data.size + left_x_data.start
    const leftY = (mousePosition.y/4)*left_y_data.size + left_y_data.start
    const rightX = (mousePosition.x/4)*right_x_data.size + right_x_data.start
    const rightY = (mousePosition.y/4)*right_y_data.size + right_y_data.start

    return { leftX, leftY, rightX, rightY }
  }

  useEffect(()=> {
    const handler = e=> {
      const { clientX, clientY } = e
      const { top, left, width, height } = document.body.getBoundingClientRect()
      const left_position = Math.max(0, clientX-left)
      const top_position = Math.max(0, clientY-top)
      const norm_left = Math.floor(Math.min(100, (100*left_position)/width))
      const norm_top = Math.floor(Math.min(100, (100*top_position)/height))
      setMousePosition({ x: norm_left, y: norm_top })
    }
    document.body.addEventListener("mousemove", handler)
    document.body.addEventListener("click", handler)
    document.body.style.cursor = `url(${cursor}), auto`
    return ()=> {
      document.body.removeEventListener("mousemove", handler)
      document.body.removeEventListener("click", handler)
      document.body.style.cursor = ''
    }
  }, [])

  return (
    <React.Fragment>
      <ConceptHeader title='Eye tracking Slowpoke' />
      <div className={css.container}>
        <Slowpoke className={css.slowpoke} {...getMetrics()} />
      </div>
      <BackToConcepts />
    </React.Fragment>
  )
}

export default MouseTrackingSlowpoke