import React  from 'react'
import styles from './Cover.module.css'

const Cover = () => (
  <div className={styles.main}>
    <div className={styles.text}>
      <span className={styles.letter}>{'{'}</span>
      <span className={styles.dot} />
      <span className={styles.dot} />
      <span className={styles.dot} />
      <span className={styles.letter}>{'}'}</span>
    </div>
  </div>
)

export default Cover