export const getMedia = ()=> {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    return navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' }, audio: false })
  }
  else {
    const getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia)
    if (!getUserMedia) return Promise.reject(new Error('Webcam not supported'))
    else return new Promise((resolve, reject)=> {
      getUserMedia.call(navigator, { video: { facingMode: 'user' }, audio: false }, resolve, reject)
    })
  }
}

export const isSupported = ()=> {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) return true
  const getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia)
  if (!getUserMedia) return false
  return true
}

export const getMediaImage = (video, canvas)=> {
  const MAX_WIDTH = 600
  // Actual processing
  if (!video || video.readyState === 0) return
  const ratio = Math.min(1, MAX_WIDTH / video.videoWidth)
  canvas.setAttribute('width', ratio * video.videoWidth)
  canvas.setAttribute('height', ratio * video.videoHeight)
  const context = canvas.getContext('2d')
  context.translate(0, 0)
  context.drawImage(video, 0, 0, ratio * video.videoWidth, ratio * video.videoHeight)
}