import { formatNumber }   from '../numbers'
import css                from './HireTalent.module.css'

const HireTalent = ({ 
  name, 
  current=0, 
  description,
  cost, 
  lines, 
  onClick 
})=> (
  <div className={css.main}>
    <div className={css.info}>
      <p className={css.job} title={description}>
        {current} {name}
      </p>
    </div>
    {Number.isFinite(cost) && (
      <button
        className={css.button}
        onClick={onClick}
        disabled={cost > lines+1}
      >
        Hire<wbr/> ({formatNumber(Math.floor(cost))}λ)
      </button>
    )}
  </div>
)

export default HireTalent