import React                  from 'react'
import css                    from './Cover.module.css'

const getRandomColor = ()=> {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
}

const paintBackground = ({ element, color })=> {
  element.style.backgroundColor = color;
  setTimeout(() => {
    element.style.backgroundColor = "initial";
  }, 800);
}

const Cover = ()=> {

  const handleMouseEnter = (event) => {
    paintBackground({ 
      element: event.currentTarget, 
      color: getRandomColor() 
    });
  }

  return (
    <div className={css.content}>
      <div className={css.grid}>
        {new Array(9).fill(0).map((_, i)=> (
          <div 
            key={i} 
            className={css.cell} 
            onMouseEnter={handleMouseEnter}
            onTouchStart={handleMouseEnter}
          /> 
        ))}
      </div>
    </div>
  )
}

export default Cover