import React  from 'react'
import css    from './CodeArea.module.css'

const CodeArea = ({ children, ...props })=> (
  <code className={css.area} {...props}>
    {children}
  </code>
)

const CodeLine = ({indent=0, fragments=[], content, strong, italic, children})=> {

  const indents = [
    undefined,
    <React.Fragment>&nbsp;&nbsp;</React.Fragment>,
    <React.Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</React.Fragment>,
    <React.Fragment>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</React.Fragment>,
    <React.Fragment>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</React.Fragment>,
    <React.Fragment>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</React.Fragment>,
    <React.Fragment>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</React.Fragment>,
  ]

  let line = content || children

  if (fragments.length > 0) {
    line = fragments.map((fragment, index)=> (
      <span
        key={index}
        data-color={fragment.color}
        data-modifier={fragment.modifier}
        className={css.fragment}
      >
        {fragment.content}
      </span>
    ))
  }

  return (
    <span 
      className={css.line} 
      data-weight={strong ? 'strong' : 'normal'}
      data-style={italic ? 'italic' : 'normal'}
    >
      {indents[indent]}{line}
      <br />
    </span>
  )
}

const FragmentUtils = {
  Colors: ({contents, colors})=> {
    return contents.map((content, i)=> {
      const color = colors[i] 
      if (color) return { content, color }
      return { content }
    })
  }, 
  JS: {
    import: (what, from)=> ([
      { content: 'import ', color: 'yellow' },
      { content: what },
      { content: ' from ', color: 'yellow' },
      { content: `'${from}'` },
    ]),
    comment: comment=> ([
      { content: `// ${comment}`, color: 'grey' }
    ])
  },
  CSS: {
    property: (property, value, comment)=> {
      const data = [
        { content: property, color: 'blue' },
        { content: ':', color: 'grey-2' },
        { content: ` ${value}`, color: 'wood' },
        { content: ';', color: 'grey-2' },
      ]
      if (comment) data.push({ content: ` // ${comment}`, color: 'grey' })
      return data
    }
  },
  HTML: {
    tag: (tag, properties)=> {
      const properties_data = []
      properties.forEach(prop=> {
        properties_data.push({content: ' '})
        properties_data.push({content: prop.key, color: 'green'})
        properties_data.push({content: '="'})
        properties_data.push({content: prop.value, color: 'wood'})
        properties_data.push({content: '"'})
      })
      const data = [
        { content: '<', color: 'violet-3'},
        { content: tag, color: 'violet-3'},
        { content: '', color: 'violet-3'},
        ...properties_data,
        { content: '>', color: 'violet-3'},
      ]
      return data
    },
    selfClosedTag: (tag, properties)=> ([
      ...FragmentUtils.HTML.tag(tag, properties).slice(0, -1),
      { content: ' />', color: 'violet-3'},
    ]),
    closedTag: (tag, properties)=> ([
      ...FragmentUtils.HTML.tag(tag, properties),
      { content: '</', color: 'violet-3'},
      { content: tag, color: 'violet-3'},
      { content: '>', color: 'violet-3'}
    ]),
    tagClose: tag=> ([
      { content: '</', color: 'violet-3'},
      { content: tag, color: 'violet-3'},
      { content: '>', color: 'violet-3'}
    ]),
    comment: comment=> ([
      { content: `<!-- ${comment} -->`, color: 'grey' }
    ])
  }
}

export { CodeLine, FragmentUtils }

export default CodeArea