import React                                  from 'react'
import ConceptHeader                          from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts                         from '../../components/BackToConcepts/BackToConcepts'
import CodeArea, { CodeLine, FragmentUtils }  from '../../components/CodeArea/CodeArea'
import css                                    from './DestructuringJavascript.module.css'

const DestructuringJavascript = ()=> (
  <React.Fragment>
    <ConceptHeader title='Destructuring in JavaScript' />
    <div className={css.main}>
      <p className={css.block}>
        In the good old days of JavaScript, when we used <code>var</code> instead of <code>let/const</code>, extracting
        data from objects was very verbose. You pretty much had to do something like this
      </p>
      <CodeArea>
        <CodeLine fragments={FragmentUtils.JS.comment('The old way (It\'s still valid)')} />
        <CodeLine fragments={[
          { content: 'var', color: 'orange' },
          { content: ' data', color: 'blue' },
          { content: ' = ' },
          { content: '{ ', color: 'pink' },
          { content: 'id', color: 'yellow' },
          { content: ': ' },
          { content: '1', color: 'green' },
          { content: ', ' },
          { content: 'name', color: 'yellow' },
          { content: ': ' },
          { content: '"Jorge"', color: 'green' },
          { content: ', ' },
          { content: 'email', color: 'yellow' },
          { content: ': ' },
          { content: '"jorge@aguirre.sexy"', color: 'green' },
          { content: ' }', color: 'pink' },
        ]} />
        <CodeLine />
        <CodeLine fragments={[
          { content: 'var', color: 'orange' },
          { content: ' id', color: 'wood' },
          { content: ' = ' },
          { content: 'data', color: 'blue' },
          { content: '.' },
          { content: 'id', color: 'yellow' },
        ]} />
        <CodeLine fragments={[
          { content: 'var', color: 'orange' },
          { content: ' name', color: 'wood' },
          { content: ' = ' },
          { content: 'data', color: 'blue' },
          { content: '.' },
          { content: 'name', color: 'yellow' },
        ]} />
        <CodeLine fragments={[
          { content: 'var', color: 'orange' },
          { content: ' email', color: 'wood' },
          { content: ' = ' },
          { content: 'data', color: 'blue' },
          { content: '.' },
          { content: 'email', color: 'yellow' },
        ]} />
      </CodeArea>
      <h2 className={css.header}>Basic object destructuring</h2>
      <p className={css.block}>
        Object destructuring came along and made things simpler, you can now get all the data from
        the object in a single line. This is called <strong>syntactic sugar</strong> (a feature that is 
        designed to make things easier but is not a key aspect of the language).
      </p>
      <CodeArea>
        <CodeLine fragments={FragmentUtils.JS.comment('The new way')} />
        <CodeLine fragments={[
          { content: 'let', color: 'orange' },
          { content: ' data', color: 'blue' },
          { content: ' = ' },
          { content: '{ ', color: 'pink' },
          { content: 'id', color: 'yellow' },
          { content: ': ' },
          { content: '1', color: 'green' },
          { content: ', ' },
          { content: 'name', color: 'yellow' },
          { content: ': ' },
          { content: '"Jorge"', color: 'green' },
          { content: ', ' },
          { content: 'email', color: 'yellow' },
          { content: ': ' },
          { content: '"jorge@aguirre.sexy"', color: 'green' },
          { content: ' }', color: 'pink' },
        ]} />
        <CodeLine />
        <CodeLine fragments={[
          { content: 'let ', color: 'orange' },
          { content: '{ ', color: 'pink' },
          { content: ' id', color: 'wood' },
          { content: ', ' },
          { content: ' name', color: 'wood' },
          { content: ', ' },
          { content: ' email', color: 'wood' },
          { content: ' }', color: 'pink' },
          { content: ' = ' },
          { content: 'data', color: 'blue' }
        ]} />
      </CodeArea>
      <p className={css.block}>
        But that's just the tip of the iceberg, there are a lot of things that came with this syntax, 
        such as renaming, the spread operator, default values... Let's go over a few of them
      </p>
      <h2 className={css.header}>The spread operator</h2>
      <p className={css.block}>
        This allows you to destructure a few properties from a JSON object, but keep the rest of the data in 
        a single field. It's useful if yo want to process specific data, but keep all the other information.
        It can also be used to eliminate data from a field, destructure the fields you don't want, and keep the rest.
      </p>
      <CodeArea>
        <CodeLine fragments={FragmentUtils.JS.comment('The spread operator')} />
        <CodeLine fragments={[
          { content: 'let', color: 'orange' },
          { content: ' data', color: 'blue' },
          { content: ' = ' },
          { content: '{ ', color: 'pink' },
          { content: 'id', color: 'yellow' },
          { content: ': ' },
          { content: '1', color: 'green' },
          { content: ', ' },
          { content: 'name', color: 'yellow' },
          { content: ': ' },
          { content: '"Jorge"', color: 'green' },
          { content: ', ' },
          { content: 'email', color: 'yellow' },
          { content: ': ' },
          { content: '"jorge@aguirre.sexy"', color: 'green' },
          { content: ' }', color: 'pink' },
        ]} />
        <CodeLine />
        <CodeLine fragments={[
          { content: 'let ', color: 'orange' },
          { content: '{ ', color: 'pink' },
          { content: ' id', color: 'wood' },
          { content: ', ...' },
          { content: 'details', color: 'wood' },
          { content: ' }', color: 'pink' },
          { content: ' = ' },
          { content: 'data', color: 'blue' }
        ]} />
        <CodeLine fragments={FragmentUtils.JS.comment('details = { name: "Jorge", email: "jorge@aguirre.sexy" }')} />
      </CodeArea>
      <p className={css.block}>
        It can also be used in arrays! (Most destructuring works with arrays as well)
      </p>
      <CodeArea>
        <CodeLine fragments={FragmentUtils.JS.comment('The spread operator')} />
        <CodeLine fragments={[
          { content: 'let', color: 'orange' },
          { content: ' list', color: 'blue' },
          { content: ' = ' },
          { content: '[ ', color: 'pink' },
          { content: '"Spain"', color: 'green' },
          { content: ', ' },
          { content: '"France"', color: 'green' },
          { content: ', ' },
          { content: '"Italy"', color: 'green' },
          { content: ', ' },
          { content: '"Greece"', color: 'green' },
          { content: ', ' },
          { content: '"Germany"', color: 'green' },
          { content: ' ]', color: 'pink' },
        ]} />
        <CodeLine />
        <CodeLine fragments={[
          { content: 'let ', color: 'orange' },
          { content: '[ ', color: 'pink' },
          { content: ' first', color: 'wood' },
          { content: ', ' },
          { content: 'second', color: 'wood' },
          { content: ', ...' },
          { content: 'others', color: 'wood' },
          { content: ' ]', color: 'pink' },
          { content: ' = ' },
          { content: 'list', color: 'blue' }
        ]} />
        <CodeLine fragments={FragmentUtils.JS.comment('others = [ "Italy", "Greece", "Germany" ]')} />
      </CodeArea>
      <h2 className={css.header}>Default values</h2>
      <p className={css.block}>
        If destructuring fails (because the object does not have the destructured property), the variable will be
        set to <code>undefined</code>. But you can provide a default value directly, and the variable will be assigned
        to that value, instead of <code>undefined</code>!
      </p>
      <CodeArea>
        <CodeLine fragments={FragmentUtils.JS.comment('Assigning default values')} />
        <CodeLine fragments={[
          { content: 'let', color: 'orange' },
          { content: ' data', color: 'blue' },
          { content: ' = ' },
          { content: '{ ', color: 'pink' },
          { content: 'id', color: 'yellow' },
          { content: ': ' },
          { content: '1', color: 'green' },
          { content: ', ' },
          { content: 'name', color: 'yellow' },
          { content: ': ' },
          { content: '"Jorge"', color: 'green' },
          { content: ', ' },
          { content: 'email', color: 'yellow' },
          { content: ': ' },
          { content: '"jorge@aguirre.sexy"', color: 'green' },
          { content: ' }', color: 'pink' },
        ]} />
        <CodeLine />
        <CodeLine fragments={[
          { content: 'let ', color: 'orange' },
          { content: '{ ', color: 'pink' },
          { content: ' id', color: 'wood' },
          { content: ', ' },
          { content: ' name', color: 'wood' },
          { content: ', ' },
          { content: ' email', color: 'wood' },
          { content: '=' },
          { content: '"No email"', color: 'green-2' },
          { content: ', ' },
          { content: ' phone', color: 'wood' },
          { content: '=' },
          { content: '"No phone"', color: 'green-2' },
          { content: ' }', color: 'pink' },
          { content: ' = ' },
          { content: 'data', color: 'blue' }
        ]} />
        <CodeLine fragments={FragmentUtils.JS.comment('email = "jorge@aguirre.sexy" (Correctly destructured)')} />
        <CodeLine fragments={FragmentUtils.JS.comment('phone = "No phone" (Destructuring returned undefined)')} />
      </CodeArea>
      <h2 className={css.header}>Renaming properties</h2>
      <p className={css.block}>
        Sometimes you may have already defined a variable in your function, prior to destructuring a JSON object.
        In such cases, you can still apply the destructuring with the following syntax
      </p>
      <CodeArea>
        <CodeLine fragments={FragmentUtils.JS.comment('Assigning default values')} />
        <CodeLine fragments={[
          { content: 'let', color: 'orange' },
          { content: ' id', color: 'blue' },
          { content: ' = ' },
          { content: '6', color: 'green' },
        ]} />
        <CodeLine fragments={[
          { content: 'let', color: 'orange' },
          { content: ' client_data', color: 'blue' },
          { content: ' = ' },
          { content: '{ ', color: 'pink' },
          { content: 'id', color: 'yellow' },
          { content: ': ' },
          { content: '1', color: 'green' },
          { content: ', ' },
          { content: 'name', color: 'yellow' },
          { content: ': ' },
          { content: '"Jorge"', color: 'green' },
          { content: ' }', color: 'pink' },
        ]} />
        <CodeLine />
        <CodeLine fragments={[
          { content: 'let ', color: 'orange' },
          { content: '{ ', color: 'pink' },
          { content: ' id', color: 'wood' },
          { content: ': ' },
          { content: 'client_id', color: 'violet-2' },
          { content: ', ' },
          { content: ' name', color: 'wood' },
          { content: ', ' },
          { content: ' email', color: 'wood' },
          { content: '=' },
          { content: '"No email"', color: 'green-2' },
          { content: ' }', color: 'pink' },
          { content: ' = ' },
          { content: 'data', color: 'blue' }
        ]} />
        <CodeLine fragments={FragmentUtils.JS.comment('client_id = 1 (Destructured "id" and renamed it to "client_id")')} />
      </CodeArea>
      <p className={css.block}>
        So there you have it, destructuring allows you extract data from JSON objects (and arrays) with a simple, clean syntax.
        It's supported in all major browsers and it can also be used in <strong>node.js</strong>. Give it a shot it you are not 
        already using it!
      </p>
    </div>
    <BackToConcepts />
  </React.Fragment>
)

export default DestructuringJavascript