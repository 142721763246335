import github               from './icons/github.png'
import linkedin             from './icons/linkedin.png'
import twitter              from './icons/twitter.png'
import css                  from './Social.module.css'

const Social = ({ className })=> (
  <div className={className}>
    <a className={css.logo} href='https://github.com/code-jorge' target='_blank' rel='noopener noreferrer'>
      <img src={github} className={css.logo} alt='Github' />
    </a>
    <a className={css.logo} href='https://www.linkedin.com/in/the-dev' target='_blank' rel='noopener noreferrer'>
      <img src={linkedin} className={css.logo} alt='LinkedIn' />
    </a>
    <a className={css.logo} href='https://twitter.com/ja_codes' target='_blank' rel='noopener noreferrer'>
      <img src={twitter} className={css.logo} alt='Twitter' />
    </a>
  </div>
)

export default Social
