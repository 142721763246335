import React, { useEffect, useState } from 'react'
import { ethers } from "ethers"
import { useWeb3Identity, Web3IdentityProvider } from '../../contexts/Web3IdentityContext'
import NFTContract from './MyNFT.abi.json'
import css from './MintMyNFT.module.css'

// Polygon MATIC - Main Net
const CONTRACT_CHAIN = "137" 
const CONTRACT_CHAIN_HEX = "0x89"

// The Polygon NFT contract address
const CONTRACT_ADDRESS = "0x03F35eC7715AF8604274936920E0856531B592a5" 

const MetamaskIcon = ({ status })=> (
  <svg data-status={status} className={css.metamask} viewBox="0 0 318.6 318.6">
    <g data-group="0">
      <polygon points="274.1,35.5 174.6,109.4 193,65.8 "/>
    </g>
    <g data-group="1">
      <polygon points="44.4,35.5 143.1,110.1 125.6,65.8"/>
      <polygon points="238.3,206.8 211.8,247.4 268.5,263 284.8,207.7"/>
      <polygon points="33.9,207.7 50.1,263 106.8,247.4 80.3,206.8"/>
      <polygon points="103.6,138.2 87.8,162.1 144.1,164.6 142.1,104.1"/>
      <polygon points="214.9,138.2 175.9,103.4 174.6,164.6 230.8,162.1"/>
      <polygon points="106.8,247.4 140.6,230.9 111.4,208.1"/>
      <polygon points="177.9,230.9 211.8,247.4 207.1,208.1"/>
    </g>
    <g data-group="2">
      <polygon points="211.8,247.4 177.9,230.9 180.6,253 180.3,262.3"/>
      <polygon points="106.8,247.4 138.3,262.3 138.1,253 140.6,230.9"/>
    </g>
    <g data-group="3">
      <polygon points="138.8,193.5 110.6,185.2 130.5,176.1 "/>
      <polygon points="179.7,193.5 188,176.1 208,185.2 "/>
    </g>
    <g data-group="4">
      <polygon points="106.8,247.4 111.6,206.8 80.3,207.7"/>
      <polygon points="207,206.8 211.8,247.4 238.3,207.7"/>
      <polygon points="230.8,162.1 174.6,164.6 179.8,193.5 188.1,176.1 208.1,185.2"/>
      <polygon points="110.6,185.2 130.6,176.1 138.8,193.5 144.1,164.6 87.8,162.1"/>
    </g>
    <g data-group="5">
      <polygon points="87.8,162.1 111.4,208.1 110.6,185.2"/>
      <polygon points="208.1,185.2 207.1,208.1 230.8,162.1"/>
      <polygon points="144.1,164.6 138.8,193.5 145.4,227.6 146.9,182.7"/>
      <polygon points="174.6,164.6 171.9,182.6 173.1,227.6 179.8,193.5"/>
    </g>
    <g data-group="6">
      <polygon points="179.8,193.5 173.1,227.6 177.9,230.9 207.1,208.1 208.1,185.2 "/>
      <polygon points="110.6,185.2 111.4,208.1 140.6,230.9 145.4,227.6 138.8,193.5 "/>
      <polygon points="267.2,153.5 214.9,138.2 230.8,162.1 207.1,208.1 238.3,207.7 284.8,207.7 "/>
      <polygon points="103.6,138.2 51.3,153.5 33.9,207.7 80.3,207.7 111.4,208.1 87.8,162.1 "/>
      <polygon points="174.6,164.6 177.9,106.9 193.1,65.8 125.6,65.8 140.6,106.9 144.1,164.6 145.3,182.8 145.4,227.6 173.1,227.6 173.3,182.8 "/>
    </g>
    <g data-group="7">
      <polygon points="180.3,262.3 180.6,253 178.1,250.8 140.4,250.8 138.1,253 138.3,262.3 106.8,247.4 117.8,256.4 140.1,271.9 178.4,271.9 200.8,256.4 211.8,247.4 "/>
    </g>
    <g data-group="8">
      <polygon points="177.9,230.9 173.1,227.6 145.4,227.6 140.6,230.9 138.1,253 140.4,250.8 178.1,250.8 180.6,253 "/>
    </g>
    <g data-group="9">
      <polygon points="278.3,114.2 286.8,73.4 274.1,35.5 177.9,106.9 214.9,138.2 267.2,153.5 278.8,140 273.8,136.4 281.8,129.1 275.6,124.3 283.6,118.2"/>
      <polygon points="31.8,73.4 40.3,114.2 34.9,118.2 42.9,124.3 36.8,129.1 44.8,136.4 39.8,140 51.3,153.5 103.6,138.2 140.6,106.9 44.4,35.5"/>
    </g>
  </svg>

)

const MetamaskLink = ()=> (
  <a
   href="https://metamask.io/"
   target="_blank"
   rel="noopener noreferrer"
  >
    Metamask
  </a>
)

const PolygonMetamaskLink = ()=> (
  <a
    href="https://coinmarketcap.com/alexandria/article/connect-metamask-to-polygon-network"
    target="_blank"
    rel="noopener noreferrer"
  >
    how to connect Metamask to the Polygon Network
  </a>
)

const PolygonScanLink = ({ hash })=> (
  <a
    href={`https://polygonscan.com/tx/${hash}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    Identity NFT
  </a>
)

const OpenSeaLink = ({ account='' })=> (
  <a
    href={`https://opensea.io/${account}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    Open Sea
  </a>
)

const MintingResult = ({ mintingResult, account, onClose })=> (
  <div className={css.result} data-status={mintingResult.status}>
    <div className={css.resultClose} onClick={onClose} />
    {mintingResult.status === "success" && (
      <>
        <p className={css.resultMessage}>
          Your <PolygonScanLink hash={mintingResult.hash} /> has been successfully minted! 
        </p>
        <p className={css.resultMessage}>
          Check out what it looks like in <OpenSeaLink account={account} />.
        </p>
      </>
    )}
    {mintingResult.status === "error" && (
      <>
        <p className={css.resultMessage}>
          An error occurred while minting your <strong>Identity NFT</strong>.
        </p>
        <p className={css.resultMessage}>
          <code>{mintingResult.message}</code>
        </p>
      </>
    )}
  </div> 
)

const MetamaskNotAvailable = ()=> (
  <div className={css.unavailable}>
    <MetamaskIcon status="disabled" />
    <h2 className={css.subtitle}>
      Metamask is required
    </h2>
    <p className={css.block}>
      To mint the <strong>Identity NFT</strong> you need a <MetamaskLink /> account and the browser 
      extension active and running! Metamask is a <strong>web3</strong> wallet that allows you to manage 
      your tokens (cryptocurrencies) and digital assets (like <code>NFTs</code>). It's 100% free and compatible with most
      of the web3 ecosystem (including sites like <strong>OpenSea</strong>, <strong>Axie Infinity</strong>, <strong>Rarible</strong>...)!
    </p>
    <a 
      className={css.buttonLink} 
      href="https://metamask.io/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Install MetaMask
    </a>
  </div>
)

const MetamaskAvailable = ()=> {

  const { 
    account,
    chain,
    connectWallet
  } = useWeb3Identity()

  const [accountTokens, setAccountTokens] = useState(0)
  const [isMinting, setMinting] = useState(false)
  const [mintingResult, setMintingResult] = useState(null)

  useEffect(()=> {
    if (account && chain === CONTRACT_CHAIN && !isMinting) {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, NFTContract.abi, signer)
      connectedContract.balanceOf(account).then(data=> {
        setAccountTokens(data.toString())
      })
    }
    else if (!isMinting) {
      setAccountTokens(0)
    }
  }, [account, chain, isMinting])

  const askContractToMintNft = async () => {
    if (isMinting) return
    try {
      setMinting(true)
      setMintingResult(null)
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, NFTContract.abi, signer)
      let nftTxn = await connectedContract.mintNFT({ value: ethers.utils.parseEther("1") })
      await nftTxn.wait()
      setMintingResult({ status: "success", hash: nftTxn.hash })
    } catch (error) {
      setMintingResult({ status: "error", message: error.message })
    } finally {
      setMinting(false)
    }
  }

  const switchChain = async ()=> {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: CONTRACT_CHAIN_HEX }]
    })
  }

  const hasAccount = !!account
  const hasRightChain = chain === CONTRACT_CHAIN
  
  return (
    <div className={css.available}>
      <MetamaskIcon status="enabled" />
      <h2 className={css.subtitle}>
        {!hasAccount && "You need to connect your wallet"}
        {hasAccount && !hasRightChain && "Your wallet is in the wrong blockchain"}
        {hasAccount && hasRightChain && "Your wallet is ready"}
      </h2>
      {!hasAccount && (
        <button 
          className={css.button} 
          onClick={connectWallet}
        >
          Connect Wallet
        </button>
      )}
      {hasAccount && !hasRightChain && (
        <>
          <p className={css.block}>
            This <strong>Smart Contract</strong> is written on the <strong>Polygon</strong> network. Which means 
            your <strong>Metamask</strong> account should be connected to it for the transaction to go through.
            Check out <PolygonMetamaskLink /> to set it up in Metamask (and make sure to switch the network afterwards).
          </p>
          <button
            className={css.button}
            onClick={switchChain}
          >
            Change network
          </button>
        </>
      )}
      {hasAccount && hasRightChain && (
        <>
          <p className={css.block}>
            You can mint a new <strong>Identity NFT</strong> token clicking the button below. The NFT will cost 
            you <code>1 MATIC + gas fees</code>. The exact amount changes as the market value of the token changes,
            and gas fees vary depending on the network status, but, as of today, it's <strong>less than 1 USD</strong>.
          </p>
          <p className={css.block}>
            The transaction can take from 10 seconds to a couple of minutes to go through. Don't worry, we will notify you
            when it's completed (or if it fails).
          </p>
          {accountTokens > 0 && (
            <p className={css.block}>
              You currently own <strong>{accountTokens} Identity NFT</strong> tokens. Thank you for your support!
            </p>
          )}
          {accountTokens === 0 && (
            <p className={css.block}>
              You don't currently own any <strong>Identity NFT</strong> tokens. Click the button to mint your first!
            </p>
          )}
          {mintingResult && (
            <MintingResult 
              mintingResult={mintingResult} 
              account={account} 
              onClose={()=> setMintingResult(null)}
            />
          )}
          <button 
            className={css.button} 
            data-status={isMinting ? "loading" : "enabled"}
            disabled={isMinting}
            onClick={askContractToMintNft}
          >
            {isMinting ? 'Minting NFT...' : 'Mint Identity NFT'}
          </button>
        </>
      )}
    </div>
  )
}

const MintMyNFT = ()=> {

  const { 
    isWeb3WalletAvailable,
  } = useWeb3Identity()

  return (
    <div className={css.main}>
      <h1 className={css.title}>Identity NFT Mint</h1>
      {!isWeb3WalletAvailable && <MetamaskNotAvailable />}
      {isWeb3WalletAvailable && <MetamaskAvailable />}
    </div>
  )
}

const MintMyNFTContainer = ()=> (
  <Web3IdentityProvider>
    <MintMyNFT />
  </Web3IdentityProvider>
)

export default MintMyNFTContainer