import { CHESS_SYMBOLS }  from '../chess'
import css                from './ChessBoard.module.css'

const isErrorCell = ({ errors, rowIndex: row, cellIndex: cell })=> {
  return errors.some((error)=> error.row === row && error.cell === cell)
}

const ChessBoard = ({ board, onClick, errors=[] })=> (
  <div className={css.chessBoard}>
    {board.map((row, rowIndex)=> (
      <div 
        key={rowIndex}
        className={css.chessRow} 
      >
        {row.map((cell, cellIndex)=> (
          <div 
            key={cellIndex}
            className={css.chessCell}
            data-color={rowIndex % 2 === cellIndex % 2 ? 'white' : 'black'}
            onClick={()=> onClick({ row: rowIndex, cell: cellIndex })}
            data-error={isErrorCell({ errors, rowIndex, cellIndex }) ? 'true' : 'false'}
          >
            {CHESS_SYMBOLS[cell]}
          </div>
        ))}
      </div>
    ))}
  </div>
)

export default ChessBoard