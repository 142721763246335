import React, { useState, useEffect }                       from 'react'
import ConceptHeader                                        from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts                                       from '../../components/BackToConcepts/BackToConcepts'
import UnsplashedCredit                                     from '../../elements/UnsplashedCredit/UnsplashedCredit'
import { getColorFromImage, getBrightness }                 from '../../utils/image'
import { generateBrightnessImage, sampleImageVertical }     from './resources/utils'
import { generateImageBRG, generateGrayscaleImage }         from './resources/utils'
import CodeArea                                             from '../../components/CodeArea/CodeArea'
import { CodeLine }                                         from '../../components/CodeArea/CodeArea'
import { FragmentUtils }                                    from '../../components/CodeArea/CodeArea'
import spiderman                                            from './resources/spiderman.jpg'
import styles                                               from './ImageASCII.module.css'

const getCharacter = brightness=> {
  const ASCII = ' .,:;i1tfLCG08@'
  const end = ASCII.length -1
  return ASCII.charAt(end - Math.round(brightness * end))
}

const getASCII = ()=> {
  const canvas = document.createElement('canvas')
  const source = document.getElementById('sample-image')
  if (!source || !source.width || !source.height) return ''
  canvas.setAttribute('width', source.width)
  canvas.setAttribute('height', source.height)
  const context = canvas.getContext('2d')
  context.drawImage(source, 0, 0)
  const image_data = context.getImageData(0, 0, source.width, source.height)
  const raw_data = image_data.data
  let result = ''
  for (let y=0; y<source.height; y+=2) {
    for (let x=0; x<source.width; x++) {
      const offset = 4*(x + source.width*y)
      const color = getColorFromImage(raw_data, offset)
      const brightness = getBrightness(color)
      const ascii = getCharacter(brightness)
      result += ascii
    }
    result += '\n'
  }
  return result
}

const ImageASCII = () => {

  const [imageReady, setImageReady] = useState(false)
  const [transformedImages, setTransformedImages] = useState([])
  const [imageASCII, setImageASCII] = useState('')

  useEffect(()=> {
    if (!imageReady) return
    const image_BRG = generateImageBRG()
    const image_grayscale = generateGrayscaleImage()
    const image_brightness = generateBrightnessImage()
    const sample_image = sampleImageVertical()
    const ascii = getASCII()
    setTransformedImages([image_BRG, image_grayscale, image_brightness, sample_image])
    setImageASCII(ascii)
  }, [imageReady])

  return (
    <React.Fragment>
      <ConceptHeader title='Transforming an image to ASCII characters' />
      <div className={styles.main}>
        <h2 className={styles.header}>Step 1: Image manipulation in the canvas</h2>
        <CodeArea>
          <CodeLine fragments={[
            { content: 'const', color: 'orange' }, 
            { content: ' canvas', color: 'blue' }, 
            { content: ' = '},
            { content: 'document', color: 'yellow'},
            { content: '.createElement('},
            { content: '"canvas"', color: 'green'},
            { content: ')'},
          ]} />
          <CodeLine fragments={[
            { content: 'const', color: 'orange' }, 
            { content: ' canvas', color: 'blue' }, 
            { content: ' = '},
            { content: 'document', color: 'yellow'},
            { content: '.getElementById('},
            { content: '<source>', color: 'green'},
            { content: ')'},
          ]} />
          <CodeLine fragments={[
            { content: 'if ', color: 'orange' }, 
            { content: '(', color: 'wood' }, 
            { content: '!', color: 'red' }, 
            { content: 'source' }, 
            { content: ') ', color: 'wood' }, 
            { content: 'return ', color: 'orange' }, 
            { content: 'null', color: 'pink' }, 
          ]} />
          <CodeLine fragments={[
            { content: 'canvas.setAttribute(' }, 
            { content: '"width"', color: 'green' }, 
            { content: ', ' }, 
            { content: 'source.width)' }, 
          ]} />
          <CodeLine fragments={[
            { content: 'canvas.setAttribute(' }, 
            { content: '"height"', color: 'green' }, 
            { content: ', ' }, 
            { content: 'source.height)' }, 
          ]} />
          <CodeLine fragments={[
            { content: 'const', color: 'orange' }, 
            { content: ' context', color: 'blue' }, 
            { content: ' = '},
            { content: 'canvas.getContext('},
            { content: '"2d"', color: 'green'},
            { content: ')'},
          ]} />
          <CodeLine content='context.drawImage(source, 0, 0)' />
          <CodeLine fragments={[
            { content: 'const', color: 'orange' }, 
            { content: ' image_data', color: 'blue' }, 
            { content: ' = context.getImageData(0, 0, source.width, source.height)'},
          ]} />
          <CodeLine fragments={[
            { content: 'const', color: 'orange' }, 
            { content: ' raw_data', color: 'blue' }, 
            { content: ' = image_data.data'},
          ]} />
        </CodeArea>
        <p className={styles.block}>
          This snippet creates a <code>&lt;canvas&gt;</code> element and paints an image on it. 
          The function <code>getImageData</code> returns the raw data for the image. It's an array containing 4 bytes
          per image pixel (the red, green, blue and alpha channel respectively). It's possible to modify the array
          (named <code>raw_data</code> in the snippet) to modify the image, like in the example below. For the purpose
          of converting to ASCII we don't need to modify it, but to read pixel color values from it to know what ASCII
          character to write.
        </p>
        <div className={styles.imagesBlock}>
          <div className={styles.images}>
            <img 
              onLoad={()=> setImageReady(true)} 
              id='sample-image' 
              src={spiderman} 
              alt='Spiderman (Original)' 
            />
            <img src={transformedImages[0]} alt='Spiderman (Blue - Red - Green)' />
          </div>
          <UnsplashedCredit author='Raj Eiamworakul' handle='@roadtripwithraj' />
        </div>
        <p className={styles.block}>
          In this example we have taken the RGB values and switched them up. The red has been moved to the green, 
          the green to the blue, and the blue to the red. The result is a green-red costume, insted of a red-blue one.
        </p>
        <h2 className={styles.header}>Step 2: Getting the brightness and mapping the pixels</h2>
        <p className={styles.block}>
          We are going to map every pixel to a specific ASCII character using it's <strong>brightness</strong>. 
          Brightness can be calculated with the formula below, which returns a number from 0 to 1, (0 being 
          a black pixel and 1 being a white pixel).
        </p>
        <CodeArea>
          <CodeLine fragments={FragmentUtils.JS.comment('Traditional grayscale value')} />
          <CodeLine fragments={[
            { content: 'const', color: 'orange' }, 
            { content: ' getGrayscale = ', color: 'blue' }, 
            { content: 'color', color: 'violet-2'},
            { content: '=> ', color: 'pink' },
            { content: '(', color: 'wood' },
            { content: 'color.red + color.green + color.blue'},
            { content: ')', color: 'wood' },
            { content: ' / '},
            { content: '(', color: 'wood' },
            { content: '255', color: 'green'},
            { content: ' * ' },
            { content: '3', color: 'green'},
            { content: ')', color: 'wood' },
          ]} />
          <CodeLine fragments={FragmentUtils.JS.comment('Brightness value adjusts better to the humans eye\'s perception of color')} />
          <CodeLine fragments={[
            { content: 'const', color: 'orange' }, 
            { content: ' getBrightness = ', color: 'blue' }, 
            { content: 'color', color: 'violet-2'},
            { content: '=> ', color: 'pink' },
            { content: '(', color: 'wood' },
            { content: '0.299', color: 'green'},
            { content: ' * color.red + ' },
            { content: '0.587', color: 'green'},
            { content: ' * color.green + ' },
            { content: '0.114', color: 'green'},
            { content: ' * color.blue' },
            { content: ')', color: 'wood' },
            { content: ' / '},
            { content: '255', color: 'green'},
          ]} />
        </CodeArea>
        <div className={styles.images}>
          <img src={transformedImages[1]} alt='Spiderman (Grayscale)' />
          <img src={transformedImages[2]} alt='Spiderman (Brigthness)' />
        </div>
        <p className={styles.block}>
          We are going to calculate the brightness of each pixel to map it to it's corresponding ASCII character.
          In our case, we are going to use the following ASCII values for the different brightness levels:
        </p>
        <p className={styles.characters}>&nbsp;.,:;i1tfLCG08@</p>
        <p className={styles.block}>
          Please note that we are going to use a total of 15 characters, there is a whitespace for the 
          brightest colors (it may be tough to spot). The mapping is actually pretty straightforward
        </p>
        <CodeArea>
          <CodeLine fragments={FragmentUtils.JS.comment('Traditional grayscale value')} />
          <CodeLine fragments={[
            { content: 'const', color: 'orange' }, 
            { content: ' getCharacter = ', color: 'blue' }, 
            { content: 'brightness', color: 'violet-2'},
            { content: '=> {', color: 'pink' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'const', color: 'orange' }, 
            { content: ' ASCII', color: 'blue' }, 
            { content: ' = '},
            { content: '" .,:;i1tfLCG08@"', color: 'green'},
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'const', color: 'orange' }, 
            { content: ' end', color: 'blue' }, 
            { content: ' = ASCII.length - 1'},
          ]} />

          <CodeLine indent={1} fragments={[
            { content: 'return', color: 'orange' }, 
            { content: ' ASCII.charAt(end - Math.round(brightness * end))'},
          ]} />
          <CodeLine fragments={[{ content: '}', color: 'pink' }]} />
        </CodeArea>
        <h2 className={styles.header}>Step 3: Sampling the image</h2>
        <p className={styles.block}>
          Now we have all the tools for converting an image to ASCII, we need to fix the contrast,
          calculate the brightness, and map it to it's equivalent character.
        </p>
        <p className={styles.block}>
          There is, however, a small issue here: pixels are square, characters are not, they are larger. 
          To fix this, instead of mapping every row of pixels we are going to map every other row. The 
          resulting image (see below) has the same width, but half the height compared to the original.
        </p>
        <img className={styles.image} src={transformedImages[3]} alt='Spiderman (Sampled)' />
        <p className={styles.block}>
          With this final touch, we are ready to extract the characters
        </p>
        <h2 className={styles.header}>Step 4: Putting it all together</h2>
        <p className={styles.block}>
          Let's see the magic in action.
        </p>
        <p className={styles.result}>
          {imageASCII.split('\n').map(row=> (
            <span key={row} className={styles.row}>
              {row.split('').map((char, i) => char !== ' ' ? char : <span key={i}>&nbsp;</span>)}
            </span>
          ))}
        </p>
      </div>
      <BackToConcepts />
    </React.Fragment>
  )
}

export default ImageASCII