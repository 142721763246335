import React                  from 'react'
import DeveloperResourcesIcon from './DeveloperResourcesIcon'
import styles                 from './DeveloperResources.module.css'


const randomColor = ()=> {
  const colors = ['yellow', 'pink', 'green', 'blue', 'orange']
  return colors[Math.floor(Math.random()*colors.length)]
}

const ResourceCategory = ({ category, children })=> (
  <>
    <div className={styles.category}>
      <h3 className={styles.categoryTitle}>{category}</h3>
    </div>
    <div className={styles.categoryContent}>
      {children}
    </div>
  </>
)

const ResourceLink = ({ to, icon='star', label })=> (
  <a 
    className={styles.link}
    data-color={randomColor()}
    href={to} 
    title={label} 
    target='_blank' 
    rel='noopener noreferrer'
  >
    <DeveloperResourcesIcon icon={icon} />
    <p className={styles.linkText}>{label}</p>
  </a>
)

const DeveloperResourcesCoolLink = ()=> (
  <a 
    className={styles.coolLink}
    href={'https://pointerpointer.com/'} 
    title='Pointer pointer'
    target='_blank' 
    rel='noopener noreferrer'
  >
    cool
  </a>
  
)

const DeveloperResources = ()=> {

  return (
    <div className={styles.main}>
      <div className={styles.intro}>
        <h1 className={styles.title}>Resources and useful links</h1>
        <p className={styles.block}>
          If you spend a long time in the Internet, chances are, you might find something useful, or,
          at the very least, something <DeveloperResourcesCoolLink />. I usually bookmark my findings in 
          the browser, but I figured if they helped me, maybe they could help someone else.
        </p>
        <p className={styles.block}>
          Some are super basic, some are less known. It matters not (at least not to me). All were useful to me
          at some point, and I'm sharing my humble findings here. May they help you as they have helped me!
        </p>
      </div>
      <ResourceCategory category='Web Development'>
        <ResourceLink to='https://caniuse.com/' label='Can I use' icon='can-i-use' />
        <ResourceLink to='https://node.green/' label='Node Green' icon='node-green' />
        <ResourceLink to='http://animista.net/' label='Animista' icon='animista' />
        <ResourceLink to='https://hero-generator.netlify.app/' label='Hero Generator' icon='hero' />
        <ResourceLink to='https://cssgradient.io/' label='CSS Gradient' icon='gradient' />
        <ResourceLink to='https://emilkowalski.github.io/css-effects-snippets/' label='CSS Effects' icon='emil-kowalski' />
        <ResourceLink to='https://csslayout.io' label='CSS Layout' icon='css-layout' />
      </ResourceCategory>
      <ResourceCategory category='Web 3.0'>
        <ResourceLink to='https://buildspace.so/' label='Buildspace' icon='unicorn' />
        <ResourceLink to='https://www.pointer.gg/' label='Pointer' icon='pointer' />
        <ResourceLink to='https://faucet.egorfine.com/' label='Ropsten ETH' icon='ethereum' />
      </ResourceCategory>
      <ResourceCategory category='React'>
        <ResourceLink to='https://reacttraining.com/react-router/' label='React Router' icon='react-router' />
        <ResourceLink to='https://material-ui.com/' label='Material UI' icon='material-ui' />
        <ResourceLink to='https://nivo.rocks/' label='Nivo' icon='chart' />
        <ResourceLink to='https://marmelab.com/react-admin/' label='React Admin' icon='react-admin' />
        <ResourceLink to='https://treact.owaiskhan.me' label='Treact' icon='treact' />
      </ResourceCategory>
      <ResourceCategory category='Image & Video'>
        <ResourceLink to='https://www.loom.com/' label='Loom' icon='loom' />
        <ResourceLink to='https://tinyjpg.com/' label='Tiny JPG' icon='panda' />
        <ResourceLink to='https://undraw.co' label='Undraw' icon='doodle' />
        <ResourceLink to='https://www.flaticon.com/' label='Flat Icon' icon='flaticon' />
        <ResourceLink to='https://delesign.com/free-designs' label='Delesign' icon='delesign' />
        <ResourceLink to='https://worldvectorlogo.com/' label='World Vector Logo' icon='world' />
      </ResourceCategory>
      <ResourceCategory category='Hosting'>
        <ResourceLink to='https://github.com/' label='Github' icon='github' />
        <ResourceLink to='https://www.digitalocean.com/' label='Digital Ocean' icon='digital-ocean' />
        <ResourceLink to='https://aws.amazon.com/es/lightsail/' label='Lightsail' icon='lightsail' />
        <ResourceLink to='https://www.netlify.com/' label='Netlify' icon='netlify' />
        <ResourceLink to='https://squarespace.com' label='Squarespace' icon='squarespace' />
        <ResourceLink to='https://codepen.io/' label='CodePen' icon='codepen' />
      </ResourceCategory>
      <ResourceCategory category='Learning'>
        <ResourceLink to='https://egghead.io/' label='Egghead' icon='egghead' />
        <ResourceLink to='https://flexboxzombies.com/p/flexbox-zombies' label='Flexbox Zombies' icon='zombie' />
        <ResourceLink to='https://codingcoach.io/' label='Coding Coach' icon='coading-coach' />
        <ResourceLink to='https://www.codewars.com/' label='Code wars' icon='code-wars' />
        <ResourceLink to='https://www.udemy.com/' label='Udemy' icon='udemy' />
        <ResourceLink to='https://dev.to' label='Dev To' icon='dev-to' />
        <ResourceLink to='https://css-tricks.com' label='CSS Tricks' icon='css-tricks' />
      </ResourceCategory>
      <ResourceCategory category='Social & Sharing'>
        <ResourceLink to='https://carbon.now.sh/' label='Carbon' icon='carbon' />
        <ResourceLink to='https://projector.com' label='Projector' icon='projector' />
        <ResourceLink to='https://umami.is' label='Umami' icon='umami' />
        <ResourceLink to='https://plausible.io/' label='Plausible' icon='plausible' />
      </ResourceCategory>
      <ResourceCategory category='JS Tools'>
        <ResourceLink to='https://www.electronjs.org/' label='Electron JS' icon='electron' />
        <ResourceLink to='https://phaser.io/' label='Phaser' icon='phaser' />
        <ResourceLink to='https://d3js.org/' label='D3' icon='d3' />
        <ResourceLink to='https://nextjs.org/' label='Next' icon='next' />
        <ResourceLink to='https://remix.run/' label='Remix' icon='remix' />
      </ResourceCategory>
      <ResourceCategory category='Organization'>
        <ResourceLink to='https://www.notion.so/' label='Notion' icon='notion' />
      </ResourceCategory>
      <ResourceCategory category='Hardware'>
        <ResourceLink to='https://www.wasdkeyboards.com/' label='WASD Keyboards' icon='keyboard' />
        <ResourceLink to='https://ergodox-ez.com/' label='Ergodox EZ' icon='split-keyboard' />
      </ResourceCategory>
    </div>
  )

}

export default DeveloperResources