import React, { useState }   from "react"
import { Link }              from "react-router-dom"
import ConceptHeader         from '../../components/ConceptHeader/ConceptHeader'
import DynamicNFT            from "../../components/DynamicNFT/DynamicNFT"
import BackToConcepts        from '../../components/BackToConcepts/BackToConcepts'
import css                   from './CreatingNFT.module.css'

const NFT_WORDS = {
  first: [
    "Neurotic", "Narrow", "New", "Named", "Neighbor", 
    "Noisy", "Ninja", "Negative", "Naked", "Noir", 
    "Noob", "Neutral", "Needy", "Naughty", "Naive",
    "Novel", "Natural", "Notable", "Notorious", "Numb",
    "Newbie", "Nervous", "Nice", "Nerdy", "Nightmarish"
  ],
  second: [
    "Financial", "Foolproof", "Free", "Former", "Faithful", 
    "Finalized", "Futile", "Fearless", "Fancy", "Failed",
    "Fantastic", "Feared", "Flaming", "Fragile", "Fallen",
    "Forgotten", "Fortunate", "Functional", "Funky", "Furious",
    "Forgiving", "Fast", "Fanatical", "Faithless", "Footloose"
  ],
  third: [
    "Theorist", "Technocrat", "Tyrant", "Troublemaker", "Trickster",
    "Trophy", "Teacher", "Thinker", "Tiger", "Trash", 
    "Traveler", "Turkey", "Tourist", "Transformer", "Technician",
    "Tactician", "Taskmaster", "Telepath", "Thunder", "Terminator"
  ]
}

const PointerLink = ()=> <a href='https://www.pointer.gg/' target='_blank' rel='noopener noreferrer'>Pointer</a>
const BuildspaceLink = ()=> <a href='https://buildspace.so/' target='_blank' rel='noopener noreferrer'>Buildspace</a>
const HardhatLink = ()=> <a href='https://hardhat.org/' target='_blank' rel='noopener noreferrer'>Hardhat</a>
const AlchemyLink = ()=> <a href='https://www.alchemy.com/' target='_blank' rel='noopener noreferrer'>Alchemy</a>
const PolygonScanLink = ()=> <a href='https://polygonscan.com/address/0x03F35eC7715AF8604274936920E0856531B592a5' target='_blank' rel='noopener noreferrer'>PolygonScan</a>
const MintMyNFTLink = ()=> <Link to='/mint-my-nft'>Mint my NFT</Link>

const BlockchainIcon = ()=> (
  <svg className={css.blockchain} viewBox="0 0 752 752">
    <g>
      <path fill="#fff" d="m532.28 148.68c-39.168 0-71.039 31.867-71.039 71.039 0 28.352 16.738 52.809 40.809 64.188l-23.824 23.824c-3.6992 3.6992-3.6992 9.6953 0 13.395 1.8555 1.8555 4.2734 2.7773 6.6992 2.7773s4.8438-0.92188 6.6953-2.7734l31.094-31.094c3.1406 0.41797 6.3164 0.71875 9.5664 0.71875 39.168 0 71.039-31.867 71.039-71.039 0-39.168-31.867-71.035-71.039-71.035zm0 123.13c-28.723 0-52.094-23.371-52.094-52.094s23.371-52.094 52.094-52.094 52.094 23.371 52.094 52.094c0.003906 28.723-23.371 52.094-52.094 52.094z"/>
      <path fill="#eee" d="m430.87 321.13c1.8516 1.8516 4.2695 2.7734 6.6953 2.7734 2.4258 0 4.8438-0.92188 6.6953-2.7734l33.152-33.152c3.6992-3.6992 3.6992-9.6953 0-13.395-3.6992-3.6992-9.6953-3.6992-13.395 0l-33.152 33.152c-3.6953 3.6992-3.6953 9.6953 0.003906 13.395z"/>
      <path fill="#ddd" d="m447.04 376c0-28.352-16.738-52.809-40.809-64.188l23.824-23.824c3.6992-3.6992 3.6992-9.6953 0-13.395-3.6992-3.6992-9.6953-3.6992-13.395 0l-31.094 31.094c-3.1367-0.42578-6.3125-0.72266-9.5664-0.72266-39.168 0-71.039 31.867-71.039 71.039 0 28.352 16.738 52.809 40.809 64.188l-23.824 23.824c-3.6992 3.6992-3.6992 9.6953 0 13.395 1.8516 1.8555 4.2734 2.7773 6.6992 2.7773s4.8438-0.92188 6.6953-2.7734l31.094-31.094c3.1406 0.41797 6.3125 0.71875 9.5664 0.71875 39.172 0 71.039-31.867 71.039-71.039zm-123.13 0c0-28.723 23.371-52.094 52.094-52.094s52.094 23.371 52.094 52.094-23.371 52.094-52.094 52.094-52.094-23.371-52.094-52.094z"/>
      <path fill="#ccc" d="m274.59 477.41c1.8516 1.8516 4.2695 2.7734 6.6953 2.7734 2.4258 0 4.8438-0.92188 6.6953-2.7734l33.152-33.152c3.6992-3.6992 3.6992-9.6953 0-13.395-3.6992-3.6992-9.6953-3.6992-13.395 0l-33.152 33.152c-3.6953 3.6992-3.6953 9.6953 0.003906 13.395z"/>
      <path fill="#bbb" d="m260.38 430.87-31.094 31.094c-3.1445-0.42188-6.3164-0.71875-9.5703-0.71875-39.168 0-71.035 31.867-71.035 71.035s31.867 71.039 71.039 71.039c39.168 0 71.039-31.867 71.039-71.039 0-28.352-16.738-52.809-40.809-64.188l23.824-23.824c3.6992-3.6992 3.6992-9.6953 0-13.395-3.7031-3.6992-9.6992-3.7031-13.395-0.003906zm11.43 101.41c0 28.723-23.371 52.094-52.094 52.094-28.723 0.003906-52.094-23.371-52.094-52.094s23.371-52.094 52.094-52.094 52.094 23.371 52.094 52.094z"/>
    </g>
  </svg>
)

const PolygonIcon = ()=> (
  <svg className={css.polygon} viewBox="0 0 38.4 33.5">
    <path d="M29,10.2c-0.7-0.4-1.6-0.4-2.4,0L21,13.5l-3.8,2.1l-5.5,3.3c-0.7,0.4-1.6,0.4-2.4,0L5,16.3   c-0.7-0.4-1.2-1.2-1.2-2.1v-5c0-0.8,0.4-1.6,1.2-2.1l4.3-2.5c0.7-0.4,1.6-0.4,2.4,0L16,7.2c0.7,0.4,1.2,1.2,1.2,2.1v3.3l3.8-2.2V7   c0-0.8-0.4-1.6-1.2-2.1l-8-4.7c-0.7-0.4-1.6-0.4-2.4,0L1.2,5C0.4,5.4,0,6.2,0,7v9.4c0,0.8,0.4,1.6,1.2,2.1l8.1,4.7   c0.7,0.4,1.6,0.4,2.4,0l5.5-3.2l3.8-2.2l5.5-3.2c0.7-0.4,1.6-0.4,2.4,0l4.3,2.5c0.7,0.4,1.2,1.2,1.2,2.1v5c0,0.8-0.4,1.6-1.2,2.1   L29,28.8c-0.7,0.4-1.6,0.4-2.4,0l-4.3-2.5c-0.7-0.4-1.2-1.2-1.2-2.1V21l-3.8,2.2v3.3c0,0.8,0.4,1.6,1.2,2.1l8.1,4.7   c0.7,0.4,1.6,0.4,2.4,0l8.1-4.7c0.7-0.4,1.2-1.2,1.2-2.1V17c0-0.8-0.4-1.6-1.2-2.1L29,10.2z"/>
  </svg>
)

const NFTIcon = ()=> (
  <svg className={css.nftIcon} viewBox="0 0 256 256">
    <g fill="white" transform="translate(128 128) scale(0.72 0.72)">
      <g transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)">
        <path d="M 45 69.563 c -1.226 0 -2.452 -0.315 -3.545 -0.946 L 26.32 59.879 c -2.187 -1.263 -3.545 -3.615 -3.545 -6.141 V 36.261 c 0 -2.524 1.358 -4.877 3.545 -6.14 l 15.135 -8.739 c 2.187 -1.262 4.904 -1.262 7.09 0 l 15.136 8.739 c 2.187 1.263 3.545 3.615 3.545 6.14 v 17.477 c 0 2.524 -1.358 4.878 -3.545 6.141 l -15.136 8.738 C 47.452 69.248 46.226 69.563 45 69.563 z M 45 22.436 c -0.88 0 -1.76 0.227 -2.545 0.68 L 27.32 31.854 c -1.57 0.906 -2.545 2.595 -2.545 4.407 v 17.477 c 0 1.813 0.975 3.502 2.545 4.408 l 15.135 8.738 c 1.571 0.906 3.521 0.906 5.09 0 l 15.136 -8.738 c 1.569 -0.906 2.545 -2.596 2.545 -4.408 V 36.261 c 0 -1.812 -0.976 -3.501 -2.545 -4.407 l -15.136 -8.739 C 46.76 22.662 45.88 22.436 45 22.436 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <path d="M 37.691 52.733 c -0.339 0 -0.664 -0.174 -0.85 -0.473 l -6.509 -10.487 v 9.959 c 0 0.553 -0.448 1 -1 1 s -1 -0.447 -1 -1 V 38.267 c 0 -0.447 0.296 -0.839 0.726 -0.962 c 0.431 -0.124 0.889 0.055 1.124 0.435 l 6.509 10.486 v -9.959 c 0 -0.552 0.448 -1 1 -1 s 1 0.448 1 1 v 13.467 c 0 0.446 -0.296 0.839 -0.726 0.962 C 37.875 52.721 37.783 52.733 37.691 52.733 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <path d="M 42.914 52.733 c -0.552 0 -1 -0.447 -1 -1 V 38.267 c 0 -0.552 0.448 -1 1 -1 h 5.97 c 0.553 0 1 0.448 1 1 s -0.447 1 -1 1 h -4.97 v 12.467 C 43.914 52.286 43.466 52.733 42.914 52.733 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <path d="M 47.66 46 h -4.747 c -0.552 0 -1 -0.448 -1 -1 s 0.448 -1 1 -1 h 4.747 c 0.553 0 1 0.448 1 1 S 48.213 46 47.66 46 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <path d="M 60.667 39.267 h -7.653 c -0.553 0 -1 -0.448 -1 -1 s 0.447 -1 1 -1 h 7.653 c 0.553 0 1 0.448 1 1 S 61.22 39.267 60.667 39.267 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <path d="M 56.841 52.733 c -0.553 0 -1 -0.447 -1 -1 V 38.267 c 0 -0.552 0.447 -1 1 -1 s 1 0.448 1 1 v 13.467 C 57.841 52.286 57.394 52.733 56.841 52.733 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <path d="M 45 10.816 c -2.982 0 -5.408 -2.426 -5.408 -5.408 S 42.018 0 45 0 c 2.982 0 5.408 2.426 5.408 5.408 S 47.982 10.816 45 10.816 z M 45 2 c -1.879 0 -3.408 1.529 -3.408 3.408 c 0 1.879 1.529 3.408 3.408 3.408 c 1.879 0 3.408 -1.529 3.408 -3.408 C 48.408 3.529 46.879 2 45 2 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <path d="M 45 90 c -2.982 0 -5.408 -2.426 -5.408 -5.408 s 2.426 -5.408 5.408 -5.408 c 2.982 0 5.408 2.426 5.408 5.408 S 47.982 90 45 90 z M 45 81.184 c -1.879 0 -3.408 1.529 -3.408 3.408 S 43.121 88 45 88 c 1.879 0 3.408 -1.529 3.408 -3.408 S 46.879 81.184 45 81.184 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <path d="M 8.236 57.007 c -2.982 0 -5.408 -2.426 -5.408 -5.408 s 2.426 -5.408 5.408 -5.408 s 5.408 2.426 5.408 5.408 S 11.218 57.007 8.236 57.007 z M 8.236 48.19 c -1.879 0 -3.408 1.529 -3.408 3.408 s 1.529 3.408 3.408 3.408 c 1.879 0 3.408 -1.529 3.408 -3.408 S 10.115 48.19 8.236 48.19 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <path d="M 81.764 43.809 c -2.981 0 -5.407 -2.426 -5.407 -5.408 s 2.426 -5.408 5.407 -5.408 c 2.982 0 5.408 2.426 5.408 5.408 S 84.746 43.809 81.764 43.809 z M 81.764 34.993 c -1.879 0 -3.407 1.529 -3.407 3.408 c 0 1.879 1.528 3.408 3.407 3.408 s 3.408 -1.529 3.408 -3.408 C 85.172 36.522 83.643 34.993 81.764 34.993 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
        <rect x="12.64" y="50.6" rx="0" ry="0" width="11.13" height="2" transform=" matrix(1 0 0 1 0 0) "/>
        <rect x="66.23" y="37.4" rx="0" ry="0" width="11.13" height="2" transform=" matrix(1 0 0 1 0 0) "/>
        <rect x="44" y="9.82" rx="0" ry="0" width="2" height="11.62" transform=" matrix(1 0 0 1 0 0) "/>
        <rect x="44" y="68.57" rx="0" ry="0" width="2" height="11.62" transform=" matrix(1 0 0 1 0 0) "/>
      </g>
    </g>
  </svg>
)

const CreatingNFT = ()=> {

  const [details, setDetails] = useState({
    firstWord: 'Non',
    secondWord: 'Fungible',
    thirdWord: 'Token',
    indicator: 0
  })

  const handleClick = ()=> {
    const newFirstWord = NFT_WORDS.first[Math.floor(Math.random() * NFT_WORDS.first.length)]
    const newSecondWord = NFT_WORDS.second[Math.floor(Math.random() * NFT_WORDS.second.length)]
    const newThirdWord = NFT_WORDS.third[Math.floor(Math.random() * NFT_WORDS.third.length)]
    setDetails(c=> ({
      firstWord: newFirstWord,
      secondWord: newSecondWord,
      thirdWord: newThirdWord,
      indicator: c.indicator + 1
    }))
  }

  return (
    <React.Fragment>
      <ConceptHeader title='Creating an NFT' />
      <div className={css.main}>
        <h2 className={css.header}>Blockhain and web3</h2>
        <p className={css.block}>
          I've been slowly getting into <strong>blockchain</strong> and <strong>web3</strong> to 
          understand what the hype is all about. After a few tutorials, walkthroughs, and a lot
          of reading, testing, and developing, I finally grasp the basic notions of what it has to
          offer.
        </p>
        <p className={css.block}>
          So, without going into too much theory, a blockchain is formed by a series of blocks, linked together.
          An active blockchain has nodes, which contain the history of the blockchain, and add new blocks to it.
          In the case of <strong>Ethereum</strong>, blocks can contain arbitrary data, and people use the storage for
          a number of purposes.
        </p>
        <p className={css.block}>
          <BlockchainIcon />
        </p>
        <h2 className={css.header}>Smart Contracts and NFTs</h2>
        <p className={css.block}>
          You can "pay" a bit of Ethereum, to have those nodes store data in the blockchain. That way
          your data is available in the public, it's safe from tampering and anyone can see it & audit it. The cool thing
          is that Ethereum (and many other blockchains) also support writing small programs within the
          blockchain, which are called <strong>Smart Contracts</strong>.
        </p>
        <p className={css.block}>
          NFTs are a type of data that people store in the blockchain. They typically consist of an image + some metadata, but
          that's not always the case, you can create an NFT with a video, an audio, or almost any content. People use them for 
          different purposes, assets in a game, membership in a club, or even as collectibles (similar to trading cards).
        </p>
        <p className={css.icons}>
          <NFTIcon />
          <NFTIcon />
          <NFTIcon />
        </p>
        <h2 className={css.header}>Learning resources</h2>
        <p className={css.block}>
          Getting started is complex, but luckily there are plenty of resources out there to get started. I can only say good
          things about <PointerLink />, and <BuildspaceLink />. Amazing courses, plenty of community and rewards for completing
          the courses.
        </p>
        <p className={css.block}>
          So, after a few courses, I knew my way around <HardhatLink />, to debug and deploy smart contracts. I was 
          familiar enough with <AlchemyLink /> to propagate the smart contract to the blockchain. I understood gas fees
          transaction costs... and I was able to deploy the contract to the blockchain.
        </p>
        <p className={css.block}>
          <PolygonIcon />
        </p>
        <h2 className={css.header}>Building the NFTs</h2>
        <p className={css.block}>
          After careful consideration, I switched from <strong>Ethereum</strong> to <strong>Polygon</strong>, which is built
          on top of Ethereum, but offers lower fees for transactions. The development is pretty much the same, as both 
          use <strong>Solidity</strong> as it's base language, and the tools mentioned above work identically for both.
        </p>
        <p className={css.block}>
          So I decided to build a random NFT image, that is stored on-chain. This means that the NFT is generated at random
          when a user mints it. It is an image (an SVG to be precise), where some parts of the image are generated at 
          random, making each NFT unique. The image is stored on-chain, which is not ideal, since storing data in the blockchain
          is expensive, but it saves me the trouble of storing it somewhere else. The data is <code>base64 encoded</code> and stored
          directly in the blockchain.
        </p>
        <p className={css.block}>
          <DynamicNFT 
            className={css.nft}
            firstWord={details.firstWord}
            secondWord={details.secondWord}
            thirdWord={details.thirdWord}
            indicator={details.indicator}
          />
        </p>
        <p className={css.block}>
          The final NFT image is completely random, if you want to see what it can look like, 
          you can <button className={css.button} onClick={handleClick}>click here</button>.
        </p>
        <h2 className={css.header}>The actual Smart Contract & NFT</h2>
        <p className={css.block}>
          So I deployed the Smart Contract with the help of Hardhat and Alchemy, and it's now visible 
          on <PolygonScanLink />. This is a service that provides a simple way to scan the blockchain for
          specific data. Since all the data in the blockchain is always online and public, it's a handy tool
          to find out what's going on in the world.
        </p>
        <p className={css.block}>
          And once that was there, all there was left was to allow people to mint an NFT interacting with my smart contract. 
          Luckily most of that work is just pure frontend work, although a user would need a <strong>Metamask</strong> account 
          (and the browser extension) to be able to interact with the blockchain. If you want to see how it ended up looking like, 
          you can <MintMyNFTLink /> (be warned, it has a symbolic cost of <strong>1 MATIC</strong> + the gas fees).
        </p>
      </div>
      <BackToConcepts />
    </React.Fragment>
  )
}

export default CreatingNFT