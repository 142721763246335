import { CHESS_SYMBOLS }   from '../chess'
import css                 from './ChessBoard.module.css'

const isVisitedCell = ({ visited, row, column })=> {
  return visited.some((visit)=> visit.row === row && visit.column === column)
}

const isKnightCell = ({ knight, row, column })=> {
  return knight.row === row && knight.column === column
}

const ChessBoard = ({ knight, visited=[], onClick })=> (
  <div className={css.chessBoard}>
    {Array(8).fill('').map((_row, row)=> (
      <div 
        key={row}
        className={css.chessRow} 
      >
        {Array(8).fill('').map((_column, column)=> (
          <div 
            key={column}
            className={css.chessCell}
            data-color={row % 2 === column % 2 ? 'white' : 'black'}
            onClick={()=> onClick({ row, column })}
            data-visited={isVisitedCell({ visited, row, column }) ? 'true' : 'false'}
          >
            <span className={css.piece}>
              {isKnightCell({ knight, row, column }) ? CHESS_SYMBOLS.bn : ''}
            </span>
          </div>
        ))}
      </div>
    ))}
  </div>
)

export default ChessBoard