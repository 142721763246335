import React  from 'react'
import styles from './Switch.module.css'

const Switch = ({ className, name, checked, disabled, onClick })=> (
  <label className={`${styles.switch} ${className}`}>
    <input
      type='checkbox'
      name={name}
      className={styles.input}
      checked={checked}
      disabled={disabled}
      onChange={onClick}
    />
    <div className={styles.toggle} />
  </label>
)

export default Switch