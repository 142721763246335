import css from './Cover.module.css'

const TwitterIcon = ()=> (
  <svg className={css.icon} viewBox="-20 -20 296 296">
    <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
      <path 
        fill='white'
        d="M 0.219 2.882 l 34.748 46.461 L 0 87.118 h 7.87 l 30.614 -33.073 l 24.735 33.073 H 90 L 53.297 38.043 L 85.844 2.882 h -7.87 L 49.781 33.341 L 27.001 2.882 H 0.219 z M 11.793 8.679 h 12.303 L 78.425 81.32 H 66.122 L 11.793 8.679 z"
      />
    </g>
  </svg>
)

const Cover = ()=> (
  <div className={css.cover}>
    <div className={css.monitor}>
      <div className={css.screen}>
        <TwitterIcon />
      </div>
      <div className={css.base} />
      <div className={css.foot} />
      <div className={css.shadow} />
    </div>
  </div>
)

export default Cover