import css from './HostEvent.module.css'

const HostEvent = ({ 
  name, 
  isAvailable=false,
  isCompleted=false,
  onClick 
})=> (
  <div className={css.main}>
    <div className={css.info}>
      <p className={css.job}>{name}</p>
    </div>
    {!isCompleted && (
      <button className={css.button} onClick={onClick} disabled={!isAvailable}>
        Enjoy
      </button>
    )}
  </div>
)

export default HostEvent