export const CHESS_SYMBOLS = {
  'br': '♜',
  'bn': '♞',
  'bb': '♝',
  'bq': '♛',
  'bk': '♚',
  'bp': '♟',
  'wr': '♖',
  'wn': '♘',
  'wb': '♗',
  'wq': '♕',
  'wk': '♔',
  'wp': '♙'
}

export const EMPTY_BOARD = [
  ['', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', '']
]

export const generateRandomBoardPosition = ()=> {
  const row = Math.floor(Math.random() * 8)
  const column = Math.floor(Math.random() * 8)
  return { row, column }
}

export const canKnightJumpThere = ({ start, end })=> {
  const { row: rowStart, column: columnStart } = start
  const { row: rowEnd, column: columnEnd } = end
  const rowDiff = Math.abs(rowStart - rowEnd)
  const colDiff = Math.abs(columnStart - columnEnd)
  return (rowDiff === 2 && colDiff === 1) || (rowDiff === 1 && colDiff === 2)
}

export const getKnightRemainingMoves = ({ row, column, visited })=> {
  return [
    { row: row - 2, column: column - 1 },
    { row: row - 2, column: column + 1 },
    { row: row - 1, column: column - 2 },
    { row: row - 1, column: column + 2 },
    { row: row + 1, column: column - 2 },
    { row: row + 1, column: column + 2 },
    { row: row + 2, column: column - 1 },
    { row: row + 2, column: column + 1 }
  ]
    // Check that we haven't overstepped the board
    .filter(move=> (
      move.row >= 0 && move.row <= 7 &&
      move.column >= 0 && move.column <= 7
    ))
    .filter(move=> !visited.some(v=> v.row === move.row && v.column === move.column))
}