import React, { useState }    from 'react'
import ConceptHeader          from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts         from '../../components/BackToConcepts/BackToConcepts'
import express                from './resources/express.png'
import mongo                  from './resources/mongo.png'
import node                   from './resources/node.png'
import react                  from './resources/react.png'
import developer              from './resources/developer.png'
import js                     from './resources/js.jpeg'
import css                    from './MERNStack.module.css'

const fragments = {
  none: 'Click on a logo to discover what it stands for',
  mongo: 'MongoDB is a general purpose, document-based, distributed database',
  express: 'Express is a web application framework for node.js',
  react: 'React is a library for writing user-interfaces',
  node: 'Node.js is a JavaScript runtime based on Chrome\'s V8 engine'
}

const MERNStack = () => {

  const [activeLogo, setActiveLogo] = useState('')

  return (
    <React.Fragment>
      <ConceptHeader title='My reason for choosing the MERN stack' />
      <div className={css.main}>
        <div className={css.logos}>
          <img 
            className={css.mongo} 
            src={mongo} 
            alt='Mongo DB' 
            onClick={()=> setActiveLogo(activeLogo === 'mongo' ? '' : 'mongo')} 
          />
          <img 
            className={css.express} 
            src={express} 
            alt='Express JS' 
            onClick={()=> setActiveLogo(activeLogo === 'express' ? '' : 'express')} 
          />
          <img 
            className={css.react} 
            src={react} 
            alt='React' 
            onClick={()=> setActiveLogo(activeLogo === 'react' ? '' : 'react')} 
          />
          <img 
            className={css.node} 
            src={node} 
            alt='Node JS' 
            onClick={()=> setActiveLogo(activeLogo === 'node' ? '' : 'node')} 
          />
        </div>
        <div 
          data-status={activeLogo ? 'active' : 'placeholder'}
          className={css.explain}>
          {activeLogo ? fragments[activeLogo] : fragments['none']}
        </div>
        <p className={css.block}>
          There is obviously some more to development (design, testing, deployment, hosting...), but as far as
          coding, MERN covers most of the coding needs of a web application.
        </p>
        <p className={css.block}>
          The common ground for each framework/technology is, of course <strong>JavaScript</strong>. Although best practices
          are different in each layer of the stack, a developer who is proficient with JS can easily help out in both front
          and back, as well as perform some operations on the database. Because all developers use the same language, code
          and business logic can be peer-reviewed by anyone, and teams find it easier to communicate and help each other out.
        </p>
        <div className={css.team}>
          <div className={css.member}>
            <img className={css.thought} src={js} alt='JavaScript' />
            <img className={css.head} src={developer} alt='Developer' />
          </div>
          <div className={css.member}>
            <img className={css.thought} src={js} alt='JavaScript' />
            <img className={css.head} src={developer} alt='Developer' />
          </div>
          <div className={css.member}>
            <img className={css.thought} src={js} alt='JavaScript' />
            <img className={css.head} src={developer} alt='Developer' />
          </div>
          <div className={css.member}>
            <img className={css.thought} src={js} alt='JavaScript' />
            <img className={css.head} src={developer} alt='Developer' />
          </div>
        </div>
        <p className={css.block}>
          This is not a one-size-fits-all kind of solution. Some applications are clearly not suited for JS only.
          While front-end development is pretty much bound to JS, both the back-end and the database should be changed
          to what fits the project best.
        </p>
        <p className={css.block}>
          Machine learning algorithms, for one, don't typically perform very well on JS. If your app relies heavily on those,
          or some other computation-expensive algorithms, it may be best to give other languages a go (such as Java or Python)
        </p>
      </div>
      <BackToConcepts />
    </React.Fragment>
  )

}

export default MERNStack