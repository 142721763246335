import React, { useState, useEffect } from 'react'
import ConceptHeader                  from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts                 from '../../components/BackToConcepts/BackToConcepts'
import CodeArea, { CodeLine }         from '../../components/CodeArea/CodeArea'
import styles                         from './SpeechSynthesiser.module.css'

const CanIUseLink = ()=> (
  <a
    className={styles.link}
    href='https://caniuse.com/#feat=speech-synthesis'
  >
    Can I Use
  </a>
)

const SpeechSynthesiser = ()=> {

  const [text, setText] = useState('')
  const [voices, setVoices] = useState([])
  const [selectedVoice, setSelectedVoice] = useState('')
  const [isSupported, setSupported] = useState(false)
  const [isPlaying, setPlaying] = useState(false)

  useEffect(()=> {
    if (!window.speechSynthesis) return 
    setSupported(true)
    const synth = window.speechSynthesis
    const populateVoices = ()=> {
      const voices = synth.getVoices()
        .filter(voice=> voice.lang.startsWith("en"))
        .map(voice=> {
          const { name, default: isDefault } = voice
          return { name, isDefault }
        })
      setVoices(voices)
      const voice = voices.find(voice=> voice.isDefault)
      if (voice) setSelectedVoice(voice.name)
    }
    synth.onvoiceschanged = populateVoices
    return ()=> synth.onvoiceschanged = null
  }, [])

  const handleButtonClick = ()=> {
    const synth = window.speechSynthesis
    if (synth.speaking || !text || !selectedVoice || !isSupported) return
    setPlaying(true)
    const utterance = new SpeechSynthesisUtterance(text)
    utterance.voice = synth.getVoices()
      .filter(voice=> voice.lang.startsWith("en"))
      .find(voice=> voice.name === selectedVoice)
    utterance.onend = ()=> setPlaying(false)
    utterance.onerror = ()=> setPlaying(false)
    synth.speak(utterance)
  }

  return (
    <React.Fragment>
      <ConceptHeader title='Speech Synthesiser using Web APIs' />
        <div className={styles.main}>
          <p className={styles.block}>
            Speech Synthesis is the process of having a voice read some text outloud. It's what Siri does
            to talk back to you (or any navigation software, for that matter). It would typically require a 
            lot of voice recording and a bit of machine learning (or a complex algorithm) to get a decent voice.
          </p>
          <p className={styles.block}>
            However, most browsers have already all that work done, and by using a native API it's easy to get a voice
            to play whatever sound you need. It's still a fairly new API, so it's wise to check for support here: <CanIUseLink />
          </p>
          <CodeArea>
            <CodeLine fragments={[
              { content: 'const ', color: 'violet' }, 
              { content: 'synth', color: 'blue'},
              { content: ' = '},
              { content: 'window', color: 'yellow' },
              { content: '.'},
              { content: 'speechSynthesis', color: 'pink' },
            ]} />
            <CodeLine />
            <CodeLine fragments={[
              { content: 'const ', color: 'violet' }, 
              { content: 'populateVoices', color: 'wood'},
              { content: ' = '},
              { content: '()=> {', color: 'orange' },
            ]} />
            <CodeLine indent={1} fragments={[
              { content: 'const ', color: 'violet' }, 
              { content: 'voices ', color: 'blue-2' }, 
              { content: '= ', color: 'blue-2' }, 
              { content: 'synth', color: 'blue' }, 
              { content: '.'},
              { content: 'getVoices()', color: 'pink' },
            ]} />
            <CodeLine indent={2} fragments={[
              { content: '.' }, 
              { content: 'filter(', color: 'yellow'},
              { content: 'voice', color: 'violet-2' },
              { content: '=> ', color: 'orange' },
              { content: 'voice', color: 'violet-2' },
              { content: '.' },
              { content: 'lang', color: 'pink' },
              { content: '.' },
              { content: 'startsWith(', color: 'orange-2' },
              { content: '"en"', color: 'green' },
              { content: ')', color: 'orange-2' },
              { content: ')', color: 'yellow' },
            ]} />
            <CodeLine indent={2} fragments={[
              { content: '.' }, 
              { content: 'map(', color: 'yellow'},
              { content: 'voice', color: 'violet-2' },
              { content: '=> {', color: 'orange' },
            ]} />
            <CodeLine indent={3} fragments={[
              { content: 'const ', color: 'violet' }, 
              { content: '{ ', color: 'yellow'},
              { content: 'name', color: 'violet-3' },
              { content: ', ', color: 'orange' },
              { content: 'default', color: 'grey' },
              { content: ': ' },
              { content: 'isDefault', color: 'violet-3' },
              { content: ' }', color: 'yellow' },
              { content: ' = ' },
              { content: 'voice', color: 'violet-2' }
            ]} />
            <CodeLine indent={3} fragments={[
              { content: 'return ', color: 'violet' }, 
              { content: '{ ', color: 'yellow'},
              { content: 'name', color: 'violet-3'},
              { content: ', ', color: 'orange' },
              { content: 'isDefault', color: 'violet-3' },
              { content: ' }', color: 'yellow' },
            ]} />
            <CodeLine indent={2} fragments={[
              { content: '}', color: 'orange' },
              { content: ')', color: 'yellow' },
            ]} />
            <CodeLine indent={1} fragments={[
              { content: 'setVoices(', color: 'yellow'},
              { content: 'voices', color: 'blue-2' },
              { content: ')', color: 'yellow' },
            ]} />
            <CodeLine fragments={[
              { content: '}', color: 'orange' },
            ]} />
          </CodeArea>
          <p className={styles.block}>
            The first thing to get this to work is to gather the available voices from the browser. Make 
            sure <code>speechSynthesis</code> is defined, otherwise this will throw an error. Also, filter the voices 
            to work only with the language you want, as each voice is tailored to a language.
          </p>
          <CodeArea>
            <CodeLine fragments={[
              { content: 'const ', color: 'violet' }, 
              { content: 'speak', color: 'wood'},
              { content: ' = '},
              { content: '()=> {', color: 'orange' },
            ]} />
            <CodeLine indent={1} fragments={[
              { content: 'if (', color: 'orange' }, 
              { content: 'synth', color: 'blue'},
              { content: '.', color: 'violet-3' },
              { content: 'speaking', color: 'yellow' },
              { content: ') ', color: 'orange' },
              { content: 'return', color: 'violet' }
            ]} />
            <CodeLine indent={1} fragments={[
              { content: 'const ', color: 'violet' }, 
              { content: 'text', color: 'yellow'},
              { content: ' = ' },
              { content: '"..."', color: 'green' }
            ]} />
            <CodeLine indent={1} fragments={[
              { content: 'const ', color: 'violet' }, 
              { content: 'utterance', color: 'blue-2'},
              { content: ' = '},
              { content: 'new ', color: 'orange' },
              { content: 'SpeechSynthesisUtterance(', color: 'pink'},
              { content: 'text', color: 'yellow' },
              { content: ')', color: 'pink' },
            ]} />
            <CodeLine indent={1} fragments={[
              { content: 'utterance', color: 'blue-2'},
              { content: '.' },
              { content: 'voice', color: 'violet-3'},
              { content: ' = '},
              { content: 'synth', color: 'blue' },
              { content: '.'},
              { content: 'getVoices()', color: 'pink' },
            ]} />
            <CodeLine indent={2} fragments={[
              { content: '.' }, 
              { content: 'filter(', color: 'yellow'},
              { content: 'voice', color: 'violet-2' },
              { content: '=> ', color: 'orange' },
              { content: 'voice', color: 'violet-2' },
              { content: '.' },
              { content: 'lang', color: 'pink' },
              { content: '.' },
              { content: 'startsWith(', color: 'orange-2' },
              { content: '"en"', color: 'green' },
              { content: ')', color: 'orange-2' },
              { content: ')', color: 'yellow' },
            ]} />
            <CodeLine indent={2} fragments={[
              { content: '.' }, 
              { content: 'find(', color: 'yellow'},
              { content: 'voice', color: 'violet-2' },
              { content: '=> ', color: 'orange' },
              { content: 'voice', color: 'violet-2' },
              { content: '.' },
              { content: 'name', color: 'pink' },
              { content: ' === ' },
              { content: 'selectedVoice', color: 'green-2' },
              { content: ')', color: 'yellow' },
            ]} />
            <CodeLine indent={1} fragments={[
              { content: 'utterance', color: 'blue-2'},
              { content: '.' },
              { content: 'onend', color: 'violet-3'},
              { content: ' = '},
              { content: 'event', color: 'wood' },
              { content: '=> '},
              { content: 'console', color: 'yellow' },
              { content: '.', },
              { content: 'log(', color: 'pink' },
              { content: '"Finished speaking"', color: 'green' },
              { content: ')', color: 'pink' },
            ]} />
            <CodeLine indent={1} fragments={[
              { content: 'utterance', color: 'blue-2'},
              { content: '.' },
              { content: 'onerror', color: 'violet-3'},
              { content: ' = '},
              { content: 'event', color: 'wood' },
              { content: '=> '},
              { content: 'console', color: 'yellow' },
              { content: '.', },
              { content: 'log(', color: 'pink' },
              { content: '"Error speaking"', color: 'green' },
              { content: ')', color: 'pink' },
            ]} />
            <CodeLine indent={1} fragments={[
              { content: 'synth', color: 'blue'},
              { content: '.' },
              { content: 'speak(', color: 'violet-3'},
              { content: 'utterance', color: 'blue-2' },
              { content: ')', color: 'violet-3' },
            ]} />
            <CodeLine  fragments={[
              { content: '}', color: 'orange' },
            ]} />
          </CodeArea>
          <p className={styles.block}>
            To get the sythesis to speak you need to create a <code>utterance</code>, a word that means "vocal sound", it's kinda 
            appropiate, don't let the name scare you. You can configure the voice here (or let it attempt to guess the best voice for
            you). After setting up a few events, it's ready to mumble. Let's see how it works (remember to turn up the volume)
          </p>
          <div className={styles.concept}>
            <input 
              className={styles.input} 
              type='text' 
              value={text} 
              onChange={e=> setText(e.target.value)} 
              placeholder='Whats on your mind?'
            />
            <div className={styles.container}>
              <select 
                className={styles.selector} 
                value={selectedVoice} 
                onChange={e=> setSelectedVoice(e.target.value)}
              >
                <option disabled value=''>[No voice selected]</option>
                {voices.map(voice=> (
                  <option key={voice.name} value={voice.name}>
                    {voice.name}
                    {voice.isDefault && '(Default)'}
                  </option>
                ))}
              </select>
            </div>
            {isSupported && (
              <button 
                type='button' 
                className={styles.button}
                onClick={handleButtonClick}
                disabled={!text || !selectedVoice || isPlaying}
              >
                {isPlaying ? `${selectedVoice} is talking` : "Let's hear it"}
              </button>
            )}
            {(!isSupported || voices.length === 0) && (
              <p className={styles.error}>
                This browser either doesn't support speech synthesis yet, or it doesn't have any English voices available. 
                Check the support details at the start of this page.
              </p>
            )}
          </div>
        </div>
      <BackToConcepts />
    </React.Fragment>
  )
}

export default SpeechSynthesiser