import React, { useState }  from 'react'
import BackToConcepts       from '../../components/BackToConcepts/BackToConcepts'
import ConceptHeader        from '../../components/ConceptHeader/ConceptHeader'
import styles               from './ShakersBrand.module.css'

const ShakersBubble = ({ text, emoji, isSelected, onClick })=> (
  <div 
    className={styles.bubble} 
    data-status={isSelected ? 'active' : 'inactive'}
    onClick={onClick}
  >
    <p className={styles.bubbleText}>{text}</p>
    <p className={styles.bubbleEmoji}>{emoji}</p>
  </div>
)

const ShakersLogo = ()=> (
  <svg className={styles.logo} viewBox="0 0 719 149">
    <path d="M159.651 72.3942C159.651 72.3942 137.062 83.5022 130.554 86.9495C130.171 94.6102 129.405 102.271 129.023 109.932C128.64 118.741 128.257 123.721 127.874 132.53C127.874 134.446 127.874 136.361 127.491 137.893C126.343 144.404 122.897 148.235 117.92 148.235C112.177 148.235 108.348 144.021 108.348 137.127C108.348 125.636 109.114 117.592 109.497 106.101C109.497 103.037 109.497 98.4405 109.497 94.9932C109.497 94.9932 106.051 96.5253 103.754 96.9084C99.9256 96.5253 96.8628 94.6102 95.7142 90.7798C94.5657 86.9495 95.7142 83.5022 98.7771 80.821C99.5428 80.4379 112.56 72.3942 112.56 72.3942C112.943 56.3068 113.708 33.7079 114.857 18.0035C115.24 14.9392 115.623 11.875 116.771 9.19372C117.92 5.74642 120.983 3.83125 124.811 4.21428C128.64 4.21428 131.32 6.51248 132.085 9.95978C132.851 13.0241 133.234 16.4713 133.234 19.9186C132.851 32.5588 132.468 40.9855 132.085 53.6256C132.085 56.6899 132.085 59.7541 132.085 64.3505C146.634 57.4559 148.165 55.5408 161.948 49.0292C162.714 37.5382 163.097 30.2606 163.862 18.7696C164.245 14.9392 164.628 10.7259 165.394 6.89552C166.542 2.68215 169.605 0.000915349 174.199 0.383949C178.794 0.766982 181.474 3.44822 182.239 7.66158C182.622 11.1089 182.622 14.1732 182.622 17.6205C182.622 25.2811 181.856 31.0266 181.856 39.8364C184.536 39.0703 187.982 35.623 190.662 35.24C196.022 33.7079 200.999 36.0061 202.913 40.6025C204.828 44.8158 203.296 49.4122 198.702 52.4765C195.256 54.0086 179.942 63.5845 179.942 63.5845C179.559 88.4816 178.794 106.101 178.028 131.381C178.028 133.68 178.028 136.361 177.645 138.659C176.496 144.404 172.668 147.852 167.691 147.469C162.714 147.086 159.268 143.638 158.502 137.893C158.119 135.595 158.885 102.654 159.651 80.821C159.651 77.7567 159.651 76.2246 159.651 72.3942Z" />
    <path d="M530.636 38.6882C526.042 39.0712 522.596 39.4543 519.534 39.4543C513.025 39.4543 509.197 37.1561 508.431 32.1766C507.665 27.1972 510.345 23.3669 516.471 21.4517C521.065 19.9196 526.042 19.1535 530.636 18.3874C546.333 16.4723 561.648 15.7062 576.962 20.3026C582.322 21.8347 587.299 24.1329 591.893 27.1972C603.379 34.8579 605.676 47.498 598.402 59.372C594.19 66.2666 587.682 71.246 580.79 75.0764C576.196 77.7576 571.602 80.0558 566.242 82.737C570.07 86.1843 605.293 112.231 608.356 116.061C611.419 119.891 611.036 125.254 607.973 128.701C604.91 132.148 600.699 132.914 596.104 130.999C593.424 129.467 591.127 127.552 588.447 125.637C578.11 117.593 560.116 104.57 549.013 95.7602C548.63 99.5905 546.333 124.488 545.951 133.297C545.951 135.596 545.951 138.277 545.185 140.575C544.036 145.172 539.825 147.853 535.613 147.47C531.019 147.087 527.956 144.788 526.808 140.575C526.042 137.128 526.042 133.297 526.042 129.85C527.574 104.57 530.636 42.9016 530.636 38.6882ZM550.162 67.4157C560.882 63.2023 570.836 59.372 578.493 51.7113C581.556 48.6471 580.79 43.6676 576.962 41.7525C568.539 37.9221 559.733 37.5391 550.928 36.773L550.162 67.4157Z" />
    <path d="M266.85 106.101C264.17 109.165 262.256 111.464 260.342 113.379C253.45 119.89 246.942 127.168 239.667 132.913C235.456 136.361 230.096 138.276 224.736 139.808C212.868 142.872 202.531 135.978 200.999 122.955C200.233 116.06 200.616 108.399 202.531 101.505C209.805 74.6924 224.353 52.0934 245.41 34.4739C250.387 30.2605 256.13 27.1963 261.873 24.8981C277.953 18.3865 290.587 31.7927 290.587 45.9649C289.821 54.0086 287.524 103.42 287.141 119.507C287.141 122.189 287.141 125.253 286.376 127.934C285.227 132.913 281.016 136.361 276.421 135.978C272.21 135.595 268.381 132.53 267.616 127.551C266.85 122.955 267.233 118.358 267.233 113.379C267.233 111.847 267.233 109.548 266.85 106.101ZM223.205 118.741C245.793 109.931 270.296 70.479 267.999 46.731C267.999 45.1988 266.467 44.4328 265.319 45.1988C245.793 54.0086 220.908 93.8441 220.908 116.826C220.908 117.975 222.056 119.124 223.205 118.741Z" />
    <path d="M334.615 65.5004C347.249 56.6906 357.969 49.0299 368.689 41.7523C371.369 39.8371 374.049 37.9219 377.112 36.3898C382.855 33.3255 388.215 34.4746 390.895 38.688C393.575 42.9014 392.809 47.4978 388.215 51.7111C385.152 54.3924 382.089 56.6906 378.643 58.9888C367.158 67.0325 355.672 75.0762 343.038 83.886C346.101 85.4181 347.632 86.9502 349.929 87.7163C363.329 94.2279 376.346 100.356 389.746 106.485C392.809 108.017 395.872 109.549 398.169 111.847C401.615 114.912 401.997 118.742 400.083 122.955C398.169 126.786 394.34 128.701 390.129 127.935C387.066 127.552 384.386 126.02 381.706 124.871C369.072 118.742 356.438 112.613 343.421 106.868C341.124 105.719 338.444 104.57 334.998 103.421C334.615 106.102 334.232 108.017 334.232 109.932C334.232 118.742 333.849 123.721 333.849 132.914C333.849 135.595 333.849 138.277 333.466 140.958C332.318 146.32 328.872 149.385 324.278 149.385C319.684 149.385 315.855 146.32 314.707 140.958C314.324 138.277 314.324 135.595 314.324 132.914C314.707 88.0993 315.472 62.8191 315.855 18.0042C315.855 14.5569 316.238 11.1096 316.621 8.04534C317.387 3.83198 320.449 1.15074 325.044 0.767709C329.255 0.00164217 333.084 3.06591 334.615 7.66231C335.381 10.7266 335.381 16.8551 335.381 16.8551C335.381 34.4746 334.615 37.1559 334.232 54.7754C334.232 57.4566 334.232 60.5209 334.615 65.5004Z" />
    <path d="M426.883 76.2251C420.375 73.5438 418.461 67.0323 422.289 60.9037C423.438 58.9886 425.352 57.0734 427.266 55.5413C446.026 37.1557 458.66 26.4307 477.42 8.42818C479.717 6.12998 482.014 4.21481 484.311 2.68268C488.523 0.00144219 493.117 0.384476 496.18 3.44874C499.243 6.51301 499.626 11.1094 496.946 15.3228C495.031 18.004 492.734 20.6852 490.437 22.9834C477.42 36.0066 470.912 40.986 457.894 54.0091C455.98 55.9243 453.683 58.2225 450.62 61.6698C460.192 63.585 467.849 65.5001 475.889 67.4153C479.717 68.1814 483.546 68.9474 487.374 70.0965C491.586 71.2456 495.031 73.9269 495.797 78.9063C496.563 83.5027 494.266 87.333 489.289 89.6312C479.717 93.8446 470.146 97.2919 460.192 101.122C454.449 103.42 448.323 105.719 441.049 108.783C444.112 110.698 465.169 121.806 474.74 127.169C477.037 128.701 479.717 129.85 481.631 131.765C485.46 135.212 486.609 140.192 484.311 144.022C482.014 147.852 477.42 149.767 472.443 148.235C470.529 147.469 468.614 146.703 466.7 145.554C449.855 135.978 434.158 128.701 417.312 118.742C416.163 117.976 414.632 117.21 413.483 116.444C406.209 110.698 406.592 102.271 415.015 98.058C421.523 94.6107 457.129 82.7366 457.129 82.7366C457.129 82.7366 429.946 77.7572 426.883 76.2251Z" />
    <path d="M687.223 82.7353C679.566 81.9693 674.206 81.5863 668.464 81.2032C660.041 80.4371 651.618 79.6711 643.195 78.905C640.898 78.522 638.601 78.1389 636.304 76.9898C625.584 72.7765 622.138 62.0515 629.412 52.0927C632.858 47.1132 637.07 42.8999 641.664 39.0695C657.361 25.6634 675.355 17.6197 693.732 9.95899C695.263 9.19292 697.178 8.42686 699.092 8.80989C701.389 9.19292 704.069 10.342 704.835 12.2572C705.6 14.5554 705.6 18.3857 704.452 20.3009C702.155 23.3652 699.092 25.2803 696.029 27.1955C687.223 32.1749 678.035 36.7713 669.612 41.7508C661.955 46.3472 654.681 50.9436 647.789 59.7533C652.767 60.1364 656.595 60.9024 660.424 61.2855C674.206 62.4346 687.989 63.2006 701.772 64.3497C704.835 64.7328 707.898 65.1158 710.578 66.2649C719.766 69.3292 722.063 78.1389 715.555 85.7996C712.109 90.013 707.515 93.8433 702.92 96.9076C692.966 103.802 682.246 110.314 672.292 117.591C664.252 123.337 656.212 129.082 648.172 134.828C645.11 136.743 642.047 139.041 638.601 140.19C634.39 141.722 630.944 139.807 628.647 135.977C626.35 132.147 626.733 128.316 629.412 124.869C631.327 122.571 634.007 120.656 636.304 118.74C651.618 108.016 666.932 97.6736 681.864 87.3317C683.012 86.5657 684.161 85.4166 687.223 82.7353Z" />
    <path d="M29.4802 68.9459C37.5202 70.478 43.263 71.6271 49.3887 72.3932C56.28 73.5423 63.1714 73.9253 70.4456 74.6914C76.1884 75.4574 81.1655 77.7556 83.0798 83.8842C84.9941 90.3957 81.9313 94.9921 77.337 98.8225C75.0399 100.738 72.3599 102.653 70.0628 104.568C52.8343 116.825 35.6059 129.082 18.3775 141.339C17.2289 142.105 16.0803 143.254 14.5489 144.02C10.3375 146.319 5.74329 145.553 2.68045 142.105C-0.382378 138.658 -0.765232 134.062 1.91475 129.848C3.82902 126.784 6.509 124.486 9.18897 122.571C21.8232 113.378 34.4573 104.568 48.6229 94.2261C44.4115 93.077 41.7316 92.6939 39.0516 91.9279C31.7774 90.3957 24.5031 89.2466 17.6118 86.9484C4.97758 82.352 1.91475 71.2441 10.7204 60.5191C16.4632 53.6245 23.3546 47.496 30.2459 41.7505C39.8173 34.4729 50.1544 27.9613 60.4914 21.4497C68.5314 16.0872 74.2742 16.4703 77.7199 21.8328C81.1655 27.1952 78.8684 32.5577 71.2113 38.3032C61.64 45.1978 51.6858 51.7094 42.1144 58.604C38.2859 61.2852 34.8402 64.3495 29.4802 68.9459Z" />
  </svg>
)

const ShakersLink = ()=> (
  <a
    className={styles.link}
    href='https://shakers.xyz'
    target='_blank'
    title='Shakers XYZ'
    rel='nofollow noopener noreferrer'
  >
    Shakers
  </a>
)

const ShakersBrand = ()=> {

  const [activeBubbles, setActiveBubbles] = useState([])

  return (
    <React.Fragment>
      <ConceptHeader title='Shakers XYZ rebranding' />
      <div className={styles.main}>
        <p className={styles.block}>
          As <ShakersLink /> began to focus on developing it's core idea, <strong>reinventing work</strong>, both marketing
          and design agreed that the current logo & overall identity was not aligned with what we wanted to communicate. We
          started working on redesigning our identity a few weeks ago, and we started to gradually update our public image.
        </p>
        <p className={styles.block}>
          Our logo got simpler and easier to read, and we added a few colors to the design system. I was specially pleased with
          the fact that the starting <code>S</code> resembles the high-voltage emoji <span role='img' aria-label='S thunder'>⚡</span>, which was the emoji we 
          worked with the most, since it's colorful, electric & fast. 
        </p>
        <div className={styles.newLogo}>
          <ShakersLogo />
        </div>
        <p className={styles.block}>
          We also started working on a new, friendlier UX. Although a lot of animations were not initially released, we re-did all
          the buttons in the website to be a little interactive on hover and click events. The arrow either expands to the right, or
          expands to the left, pushing the text a little to the left as well.
        </p>
        <div className={styles.button}>
          <button className={styles.arrowBack}>
            <div className={styles.content}>
              <span className={styles.text}>Shake It Up!</span> 
              <div className={styles.container}>
                <i className={styles.element} />
                <div className={styles.tail} />
              </div>
            </div>
          </button>
          <button className={styles.arrowForward}>
            <div className={styles.content}>
              <span className={styles.text}>Start living</span> 
              <div className={styles.container}>
                <i className={styles.element} />
                <div className={styles.tail} />
              </div>
            </div>
          </button>
        </div>
        <p className={styles.block}>
          For preference selection, we worked with <strong>bubbles</strong>! We thought it was a very visual way 
          to communicate a lot of available options for the user to choose. The basic bubble was also interactive, 
          its <code>box-shadow</code>, <code>border</code>, <code>background</code> and <code>color</code> change 
          if the user selects them.
        </p>
        <div className={styles.bubbles}>
          <ShakersBubble 
            text='Work' 
            emoji='👨‍💻' 
            isSelected={activeBubbles.includes('Work')}
            onClick={()=> setActiveBubbles(c=> c.includes('Work') ? c.filter(e=> e !== 'Work') : [...c, 'Work'])}
          />
          <ShakersBubble 
            text='Life' 
            emoji='🍹' 
            isSelected={activeBubbles.includes('Life')}
            onClick={()=> setActiveBubbles(c=> c.includes('Life') ? c.filter(e=> e !== 'Life') : [...c, 'Life'])}
          />
          <ShakersBubble 
            text='Balance' 
            emoji='⚖️' 
            isSelected={activeBubbles.includes('Balance')}
            onClick={()=> setActiveBubbles(c=> c.includes('Balance') ? c.filter(e=> e !== 'Balance') : [...c, 'Balance'])}
          />
        </div>
        <p className={styles.block}>
          If you want to know more about how Shakers is working to reinvent work, let us tell you all about it by 
          visiting our website. You can do our onboarding process to stay tuned for updates regarding the product 
          development and how the company is doing!
        </p>
      </div>
      <BackToConcepts />
    </React.Fragment>
  )
}

export default ShakersBrand