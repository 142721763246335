import React, { useState }                    from 'react'
import AboutMeIcon, { AboutMeSimpleIcon }     from './AboutMeIcon'
import avatar                                 from './resources/avatar.png'
import avatar_gray                            from './resources/avatar-gray.png'
import TypedContent                           from '../../elements/TypedContent/TypedContent'
import css                                    from './AboutMe.module.css'

import sentisis                               from './resources/sentisis.png'
import mundoestudiante                        from './resources/mundoestudiante.png'
import paradigma                              from './resources/paradigma.png'
import shakers                                from './resources/shakers.png'
import axis                                   from './resources/axis.png'
import netlify                                from './resources/netlify.png'

const Flipper = ()=> {
  const [turned, setTurned] = useState(false)
  return (
    <div 
      className={css.avatar}
      data-status={turned ? 'turned' : 'default'}
      onClick={()=> setTurned(!turned)}
    >
      <div className={css.flip}>
        <img className={css.avatarFront} src={avatar} alt='Jorge Aguirre' />
        <img className={css.avatarBack} src={avatar_gray} alt='Jorge Aguirre' />
      </div>
    </div>
  )
}

const Hexagon = ({ icon, description })=> (
  <div className={css.hexagon}>
    <div className={css.hexagonContent}>
      <AboutMeSimpleIcon icon={icon} className={css.hobbiesIcon} />
      <p className={css.hobbiesDescription}>{description}</p>
    </div>
  </div>
)

const Job = ({ company, image, title, url })=> (
  <div className={css.job}>
    <a
      href={url} 
      target="_blank" 
      rel="noopener noreferrer" 
      className={css.jobLogoContainer}
    >
      <img src={image} alt={company} className={css.jobLogo} />
    </a>
    <div className={css.jobDescription}>
      <p className={css.jobCompany}>{company}</p>
      <p className={css.jobRole}>{title}</p>
    </div>
  </div>
)

const Social = ({ icon, link, title, subtitle })=> (
  <div className={css.socialElement}>
    <AboutMeIcon icon={icon} to={link} />
    <div className={css.socialDescription}>
      <p className={css.socialEntry}>{title}</p>
      <p className={css.socialDetail}>{subtitle}</p>
    </div>
  </div>
)

const BioElement = ({ icon, text })=> (
  <div className={css.bioElement}>
    <AboutMeSimpleIcon icon={icon} className={css.bioIcon} />
    <p className={css.bioText}>{text}</p>
  </div>
)

const AboutMe = ()=> (
  <div className={css.main}>
    <div className={css.intro}>
      <Flipper />
      <div className={css.text}>
        <p className={css.textContent}>I'm <strong>Jorge Aguirre</strong>,</p>
        <p className={css.textContent}>
          I'm{' '}
          <strong>
            <TypedContent sentences={[
              'a software developer',
              'always learning',
              'a pretty cool guy',
              'reinventing work',
            ]} />
          </strong>
        </p>
      </div>
    </div>
    <h2 className={css.title}>About me</h2>
    <div className={css.bio}>
      <BioElement
        icon='location'
        text='Living in Madrid, Spain'
      />
      <BioElement
        icon='language'
        text='Fluent in Spanish and English'
      />
      <BioElement
        icon='telecom'
        text='Telecommunications Engineering Degree'
      />
      <BioElement
        icon='agile'
        text='Professional Scrum Master Certification (Level I)'
      />
      <BioElement
        icon='team'
        text='Co-founded Shakers with the purpose of reinventing work'
      />
    </div>
    <h2 className={css.title}>Social profiles</h2>
    <div className={css.social}>
      <Social
        icon='twitter'
        link='https://twitter.com/ja_codes'
        title='I have a Twitter account'
        subtitle="- I don't tweet much, though"
      />
      <Social
        icon='instagram'
        link='https://www.instagram.com/jorch_aguirre'
        title='I have an Instagram account'
        subtitle="- Not tech-related, though"
      />
      <Social
        icon='linkedin'
        link='https://www.linkedin.com/in/the-dev/'
        title='I have a LinkedIn account'
        subtitle="- All my professional adventures are there"
      />
      <Social
        icon='github'
        link='https://github.com/code-jorge'
        title='I have a Github account'
        subtitle="- With, like, a lot of dead side-projects and ideas"
      />
      <Social
        icon='polywork'
        link='https://polywork.com/jorgea'
        title='I have a Polywork account'
        subtitle="- All my developer adventures are in my timeline"
      />
      <Social
        icon='mail'
        link='mailto:jorge@aguirre.sexy'
        title='I have an email account'
        subtitle="- You can, totally write an email and I'll do my best to reply"
      />
    </div>
    <h2 className={css.title}>Current Stack</h2>
    <div className={css.stack}>
      <AboutMeSimpleIcon icon='react' className={css.stackIcon} data-icon='react' />
      <AboutMeSimpleIcon icon='nodejs' className={css.stackIcon} data-icon='nodejs' />
      <AboutMeSimpleIcon icon='aws' className={css.stackIcon} data-icon='aws' />
      <AboutMeSimpleIcon icon='mongodb' className={css.stackIcon} data-icon='mongodb' />
    </div>
    <h2 className={css.title}>Professional career</h2>
    <div className={css.jobs}>
      <Job
        company='Netlify'
        image={netlify}
        title='Front-end Developer'
        url='https://www.netlify.com/'
      />
      <div className={css.jobConnector} />
      <Job
        company='Axis'
        image={axis}
        title='Front-end Developer'
        url='https://axis.xyz'
      />
      <div className={css.jobConnector} />
      <Job
        company='Shakers'
        image={shakers}
        title='Co-Founder & Developer'
        url='https://shakers.xyz'
      />
      <div className={css.jobConnector} />
      <Job
        company='Paradigma Digital'
        image={paradigma}
        title='Back End Developer'
        url='https://www.paradigmadigital.com/'
      />
      <div className={css.jobConnector} />
      <Job
        company='mundoestudiante'
        image={mundoestudiante}
        title='Full Stack Developer'
        url='https://www.mundoestudiante.com/'
      />
      <div className={css.jobConnector} />
      <Job
        company='Sentisis'
        image={sentisis}
        title='Full Stack Developer'
        url='https://sentisis.com/'
      />
    </div>
    <h2 className={css.title}>Hobbies & Interests</h2>
    <div className={css.hobbies}>
      <Hexagon
        icon='crossfit'
        description='Crossfit'
      />
      <Hexagon
        icon='netflix'
        description='Netflix'
      />
      <Hexagon
        icon='beer'
        description='Beer'
      />
      <Hexagon
        icon='cat'
        description='Cats'
      />
      <Hexagon
        icon='running'
        description='Running'
      />
      <Hexagon
        icon='scuba'
        description='Diving'
      />
      <Hexagon
        icon='bow'
        description='Archery'
      />
      <Hexagon
        icon='chess'
        description='Chess'
      />
    </div>
  </div>
)

export default AboutMe