const utils = {

  Color: {

    hexToRgb: hex=> {
      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
      hex = hex.replace(shorthandRegex, (m, r, g, b)=> r + r + g + g + b + b)
      // Process the regex
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})`
    },

    rgbToHex: rgb=> {
      const componentToHex = c=> c.toString(16).length === 1 ? `0${c.toString(16)}` : c.toString(16)
      const result = /^rgb\(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5]), ?([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5]), ?([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\)$/i.exec(rgb)
      return `#${componentToHex(+result[1])}${componentToHex(+result[2])}${componentToHex(+result[3])}`
    }

  },

  Date: {
    now: ()=> {
      const now = new Date()
      const hour = now.getHours() < 10 ? `0${now.getHours()}` : now.getHours()
      const minutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes()
      return `Its ${hour}:${minutes}`
    },
    today: ()=> {
      const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      const now = new Date()
      const weekday = now.getDay()
      const day = now.getDate()
      const month = (now.getMonth() + 1) < 10 ? `0${(now.getMonth() + 1)}` : (now.getMonth() + 1)
      const year = now.getFullYear()
      if (weekday === 3) return `It is Wednesday, my dudes. (${day}/${month}/${year})`
      else return `It's ${weekdays[weekday]} - ${day}/${month}/${year}`
    }
  },

  Link: {

    linkify: link=> {
      if (link.startsWith("http://") || link.startsWith("https://")) return link
      if (link.startsWith('www.')) return link
      if (link.startsWith('/')) return link
      else return `/${link}`
    }

  }

}

export default utils