import React  from 'react'
import styles from './Cover.module.css'

const Cover = ()=> (
  <div className={styles.cover}>
    <div className={styles.image} data-position='top' />
    <div className={styles.image} data-position='bottom' />
  </div>
)

export default Cover