import employeesData from './data/employees.json'
import researchData from './data/research.json'
import perksData from './data/perks.json'

const getMultiplier = (existing=0)=> {
  return (1.1 ** existing)
}

export const getTeamMembers = ({ group, team })=> {
  return employeesData
    .filter(e=> e.team === group)
    .reduce((acc, member)=> {
      const total = team[member.id] || 0
      return acc + total
    }, 0);
}

export const getTeamSize = ({ team })=> {
  return Object.keys(team).reduce((acc, member)=> {
    const total = team[member] || 0
    return acc + total
  }, 0);
}

export const getTeamEfficiency = ({ team={}, perks=[] })=> {
  const base = Object.keys(team).reduce((acc, member)=> {
    const total = team[member] || 0
    const efficiency = employeesData.find(e=> e.id === member).efficiency || 0
    return acc + (total * efficiency)
  }, 0)
  const boost = perksData
    .filter(perk=> perks.find(p=> p.id === perk.id))
    .filter(perk=> perk.type === 'boost')
    .reduce((acc, perk)=> {
      const effect = perk.multiplier || 1
      return acc * effect
    }, 1)
  return base * boost
}

export const getTeamMemberCost = ({ type, team={}, perks=[] })=> {
  const existing = team[type] || 0
  const base = employeesData.find(e=> e.id === type).cost
  const multiplier = getMultiplier(existing)
  const perkReduction = perksData
    .filter(perk=> perks.find(p=> p.id === perk.id))
    .filter(perk=> perk.type === 'benefit')
    .reduce((acc, perk)=> {
      const effect = perk.multiplier || 1
      return acc * effect
    }, 1)
  return base * multiplier * perkReduction
}

export const getResearchCost = id=> researchData.find(e=> e.id === id).cost

export const getPerkCost = id=> perksData.find(e=> e.id === id).cost