export const isSynthesisSupported = ()=> window.speechSynthesis

export const isListeningSupported = ()=> {
  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
  const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList
  return (SpeechRecognition && SpeechGrammarList)
}


export const synthesizeText = (text, voice)=> {
  return new Promise((resolve, reject)=> {
    const synth = window.speechSynthesis
    if (synth.speaking) return reject('Already Speaking')
    if (!text) return reject('No text given')
    if (!voice) return reject('No voice given')
    const utterance = new SpeechSynthesisUtterance(text)
    const available_voices = synth.getVoices().filter(voice=> voice.lang.startsWith("en"))
    utterance.voice = available_voices.find(v=> v.name === voice) || available_voices[0]
    utterance.onend = ()=> resolve('OK')
    utterance.onerror = ()=> reject('Error speaking')
    synth.speak(utterance)
  })
}

export const recognizeSpeech = ()=> {
  return new Promise((resolve, reject)=> {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
    const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList
  
    const recognition = new SpeechRecognition()
    recognition.grammars = new SpeechGrammarList()
    recognition.lang = 'en-US'
    recognition.interimResults = false
    recognition.maxAlternatives = 1
    recognition.start()
    let recognition_result = false
    // Actual operation - When we have an actionable result
    recognition.onresult = event=> {
      const result = event.results[0][0]
      const { transcript } = result
      recognition_result = true
      resolve(transcript)
    }
    recognition.onnomatch = ()=> {
      if (!recognition_result) resolve('<inaudible speech>')
    }
    recognition.onsoundend = ()=> {
      setTimeout(()=> {
        if (!recognition_result) resolve('<inaudible gibberish>')
      }, 2500)
    }
    // Actual operation - When we no longer have a speech
    recognition.onspeechend = ()=> {
      recognition.stop()
    }
    // Actual operation - Error handler
    recognition.onerror = e=> {
      if (e.error === 'no-speech') resolve('<remains silent>')
      else reject('Error listening')
    }
  })
}