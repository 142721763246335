export const COLORS = [
  'orange',
  'red',
  'blue',
  'pink',
  'purple',
  'green'
]

export const getRandomHint = ()=> {
  const hints = [
    'I thought humans were smarter than computers.',
    'I think you should try again.',
    'Don\'t you think you can do better?',
    'You are terrible at this game.',
    'I\'m sure you can do better.',
    'Do you want me to call for help?',
    'I\'m not sure I believe in you.',
    'My code is just uncrackable.',
    'This is just painful to watch'
  ]
  return hints[Math.floor(Math.random() * hints.length)]
}

export const generateRandomPattern = ()=> {
  const options = [...COLORS]
  const pattern = []
  for (let i = 0; i < 4; i++) {
    const index = Math.floor(Math.random() * options.length)
    pattern.push(options[index])
    options.splice(index, 1)
  }
  return pattern
}

export const evaluateAttempt = ({ solution, attempt })=> {
  const result = { correct: 0, misplaced: 0, incorrect: 4 }
  for (let i = 0; i < attempt.length; i++) {
    if (attempt[i] === solution[i]) {
      result.correct = result.correct + 1
      result.incorrect = result.incorrect - 1
    } else if (solution.includes(attempt[i])) {
      result.misplaced = result.misplaced + 1
      result.incorrect = result.incorrect - 1
    }
  }
  return result
}