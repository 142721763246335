
import { useState }                     from 'react'
import GameHeader                       from '../../components/GameHeader/GameHeader'
import MoreGames                        from '../../components/MoreGames/MoreGames'
import { checkProgress, countQueens }   from './resources/chess'
import { CHESS_SYMBOLS, EMPTY_BOARD }   from './resources/chess'
import ChessBoard                       from './resources/ChessBoard/ChessBoard'
import css                              from './TheEightQueens.module.css'

const GameRules = ()=> (
  <>
    <p className={css.rulesBlock}>
      The <strong>queen</strong> is the most powerful piece in the game of chess. It can move
      vertically, horizontally and diagonally as many squares as it wants. It's similar to
      combining the <strong>rook</strong> and <strong>bishop</strong> in one piece.
    </p>
    <p className={css.rulesBlock}>
      It is therefore possible to place 8 queens on a chess board without any of them being able to
      capture any other. But it's not very simple to do so!
    </p>
    <p className={css.rulesBlock}>
      If two queens can capture each other, their corresponding squares will be highlighted in red!
    </p>
  </>
)

const GameOptions = ({ onResetClick })=> (
  <div className={css.options}>
    <button onClick={onResetClick} className={css.optionsButton}>
      Restart
    </button>
  </div>
)

const TheEightQueens = () => {

  const [board, setBoard] = useState(EMPTY_BOARD)

  const progress = checkProgress(board)
  const totalQueens = countQueens(board)

  const isSolved = progress.status === 'ok'
  const isConflict = progress.status === 'error'

  const handleBoardClick = ({ row, cell })=> {
    if (isSolved) return
    // Clone the board
    const newBoard = board.map(row => [...row])
    const hasQueen = newBoard[row][cell] === 'bq'
    if (totalQueens === 8 && !hasQueen) return
    newBoard[row][cell] = hasQueen ? '' : 'bq'
    setBoard(newBoard)
  }

  const handleResetBoard = ()=> {
    setBoard([...EMPTY_BOARD])
  }

  return (
    <>
      <GameHeader 
        title='The 8 Chess Queens' 
        rules={<GameRules />}
        options={<GameOptions onResetClick={handleResetBoard} />}
      />
      <div className={css.main}>
        <div className={css.intro}>
          {totalQueens < 8 && !isConflict && (
            <p className={css.progressText}>
              Queens to place: {' '}
              <span className={css.progressQueens}>
                {new Array(8 - totalQueens).fill('').map((_) => CHESS_SYMBOLS['bq']).join(' ')}
              </span>
            </p>
          )}
          {isConflict && (
            <p className={css.errorText}>
              <strong className={css.errorTitle}>That doesn't look right!</strong>
              Move the queens to different positions.
            </p> 
          )}
          {isSolved && (
            <p className={css.successText}>
              <strong className={css.successTitle}>You did it! 🎉🎉🎉</strong> 
              You placed all the queens in the board.
            </p>
          )}
        </div>
        <ChessBoard
          board={board}
          onClick={handleBoardClick}
          errors={progress.queens}
        />
      </div>
      <MoreGames />
    </>
  )
}

export default TheEightQueens