import { formatNumber }   from '../numbers'
import css                from './UpdateCompany.module.css'

const UpdateCompany = ({ 
  name, 
  cost, 
  isCompleted,
  lines, 
  onClick 
})=> (
  <div className={css.main}>
    <div className={css.info}>
      <p className={css.job}>{name}</p>
    </div>
    {!isCompleted && (
      <button
        className={css.button}
        onClick={onClick}
        disabled={cost > lines+1}
      >
        Unlock ({formatNumber(Math.floor(cost))} λ)
      </button>
    )}
  </div>
)

export default UpdateCompany