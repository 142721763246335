import React  from 'react'
import styles from './Cover.module.css'

const Cover = ()=> (
  <div className={styles.cover}>
    <div className={styles.portal} data-portal='1' />
    <div className={styles.portal} data-portal='2' />
    <div className={styles.area}>
      <div className={styles.ball} />
    </div>
  </div>
)

export default Cover