import utils                from './utils'

import simple_regex         from './data/simple-regex.json'
import links_regex          from './data/links-regex.json'
import apps_regex           from './data/apps-regex.json'
import voice_regex          from './data/voice-regex.json'
import directory            from './data/directory.json'

const normalMessage = message=> ({
  message,
  special: false,
  action: 'none'
})

const linkMessage = (message, link)=> ({
  message,
  special: true,
  action: 'link',
  to: link
})

const linksMessage = (links)=> ({
  special: true,
  action: 'links',
  links
})

const interaction = message=> {
  if (message.match(/^clear$/i)) {
    return {
      special: true,
      action: 'clear'
    }
  }
  if (message.match(/^activate voice interface$/i)) {
    return {
      special: true,
      action: 'voice'
    }
  }
  if (message.match(/^deactivate voice interface$/i)) {
    return {
      special: true,
      action: 'voice-disable'
    }
  }
  if (message.match(/^dir$/i) || message.match(/^ls$/i)) {
    return linksMessage(directory)
  }
  if (message.match(/^show me (.+)$/i)) {
    const link = message.match(/show me (.+)/i)[1]
    const url = utils.Link.linkify(link)
    setTimeout(()=> window.location = url, 300)
    return normalMessage("Let's go, then")
  }
  if (message.match(/^#[0-9a-f]{6}$/i)) {
    return normalMessage(`That's hex for: ${utils.Color.hexToRgb(message)}`)
  }
  if (message.match(/^rgb\(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5]), ?([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5]), ?([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\)$/i)) {
    return normalMessage(`That's RGB for: ${utils.Color.rgbToHex(message)}`)
  }
  if (message.match(/^heads or tails\??$/i)) {
    return normalMessage(Math.random() > 0.5 ? 'heads' : 'tails')
  }
  if (message.match(/^live or die\??$/i)) {
    return normalMessage(Math.random() > 0.5 ? '👍' : '👎')
  }
  if (message.match(/^what time is it\??$/i)) {
    return normalMessage(utils.Date.now())
  }
  if (message.match(/^what (day|date) is it\??$/i)) {
    return normalMessage(utils.Date.today())
  }

  for (let i=0; i<simple_regex.length; i++) {
    const regex = new RegExp(simple_regex[i].pattern, 'i')
    if (message.match(regex)) return normalMessage(simple_regex[i].message)
  }

  for (let i=0; i<links_regex.length; i++) {
    const regex = new RegExp(links_regex[i].pattern, 'i')
    if (message.match(regex)) return linkMessage(links_regex[i].message, links_regex[i].link)
  }

  for (let i=0; i<apps_regex.length; i++) {
    const regex = new RegExp(apps_regex[i].pattern, 'i')
    if (message.match(regex)) {
      window.open(apps_regex[i].link, '_self')
      return normalMessage(apps_regex[i].message)
    }
  }

  for (let i=0; i<voice_regex.length; i++) {
    const regex = new RegExp(voice_regex[i].pattern, 'i')
    if (message.match(regex)) return normalMessage(voice_regex[i].message)
  }

  // If everything else fails
  return normalMessage('Sorry. I didn\'t understand you :(')
}

export default interaction