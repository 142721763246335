import React, { useState }    from 'react'
import ConceptHeader          from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts         from '../../components/BackToConcepts/BackToConcepts'
import CodeArea               from '../../components/CodeArea/CodeArea'
import { CodeLine }           from '../../components/CodeArea/CodeArea'
import { FragmentUtils }      from '../../components/CodeArea/CodeArea'
import Modal                  from '../../components/Modal/Modal'
import TwitterCredit          from '../../elements/TwitterCredit/TwitterCredit'
import video                  from './resources/video.mp4'
import styles                 from './ReactPortals.module.css'

const ReactPortalsLink = ()=> (
  <a 
    className={styles.link}
    target='_blank' 
    rel='noopener noreferrer' 
    href='https://reactjs.org/docs/portals.html'
  >
    React Portals
  </a>
)

const ReactPortals = ()=> {

  const [modal, setModal] = useState(false)

  return (
    <React.Fragment>
      <ConceptHeader title='Connecting data with Portals in React' />
      <div className={styles.main}>
        <p className={styles.block}>
          Portal allow you to render React components outside the DOM hierarchy. This is very very useful
          with modals and popovers and tooltips, since, although they are in a different DOM node, React keeps
          the data connected, and properly binds the event listeners. In this example:
        </p>
        <CodeArea>
          <CodeLine fragments={FragmentUtils.HTML.tag('body', [])} />
          <CodeLine indent={1} fragments={FragmentUtils.HTML.closedTag('div', [{key: 'id', value: 'root'}])} />
          <CodeLine indent={1} fragments={FragmentUtils.HTML.closedTag('div', [{key: 'id', value: 'modal'}])} />
          <CodeLine fragments={FragmentUtils.HTML.tagClose('body')} />
        </CodeArea>
        <p className={styles.block}>
          So, to make this work, I've set up a React component as follows:
        </p>
        <CodeArea>
          <CodeLine fragments={[
            { content: 'const ', color: 'orange' },
            { content: 'Modal', color: 'yellow' },
            { content: ' = ', color: 'blue' },
            { content: '(', color: 'pink' },
            { content: '{ ', color: 'yellow' },
            { content: 'visible', color: 'blue-2' },
            { content: ', ', color: 'yellow' },
            { content: 'onClose', color: 'blue-2' },
            { content: ', ', color: 'yellow' },
            { content: 'children', color: 'blue-2' },
            { content: ' }', color: 'yellow' },
            { content: ')=> { ', color: 'pink' },
          ]} />
          <CodeLine />
          <CodeLine indent={1} fragments={[
            { content: 'useEffect ', color: 'yellow' },
            { content: '(', color: 'pink' },
            { content: '()=> { ', color: 'violet' },
          ]} />
          <CodeLine indent={2} fragments={[
            { content: 'if ', color: 'orange' },
            { content: '(', color: 'yellow' },
            { content: 'visible', color: 'blue-2' },
            { content: ') ', color: 'yellow' },
            { content: 'document', color: 'orange' },
            { content: '.' },
            { content: 'body', color: 'orange' },
            { content: '.' },
            { content: 'classList', color: 'orange-2' },
            { content: '.' },
            { content: 'add', color: 'orange-2' },
            { content: '(', color: 'yellow' },
            { content: '"modal-active"', color: 'green' },
            { content: ') ', color: 'yellow' },
          ]} />
          <CodeLine indent={2} fragments={[
            { content: 'else ', color: 'orange' },
            { content: 'document', color: 'orange' },
            { content: '.' },
            { content: 'body', color: 'orange' },
            { content: '.' },
            { content: 'classList', color: 'orange-2' },
            { content: '.' },
            { content: 'remove', color: 'orange-2' },
            { content: '(', color: 'yellow' },
            { content: '"modal-active"', color: 'green' },
            { content: ') ', color: 'yellow' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: '}', color: 'violet' },
            { content: ', ' },
            { content: '[', color: 'violet-2' },
            { content: 'visible', color: 'blue-2' },
            { content: ']', color: 'violet-2' },
            { content: ') ', color: 'pink' },
          ]} />
          <CodeLine />
          <CodeLine indent={1} fragments={[
            { content: 'const ', color: 'orange' },
            { content: 'handleContentClick', color: 'blue' },
            { content: ' = ' },
            { content: 'event', color: 'wood' },
            { content: '=> {', color: 'pink' },
          ]} />
          <CodeLine indent={2} fragments={[
            { content: 'event', color: 'wood' },
            { content: '.' },
            { content: 'preventDefault()', color: 'yellow' },
          ]} />
          <CodeLine indent={2} fragments={[
            { content: 'event', color: 'wood' },
            { content: '.' },
            { content: 'stopPropagation()', color: 'yellow' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: '}', color: 'pink' },
          ]} />
          <CodeLine />
          <CodeLine indent={1} fragments={[
            { content: 'const ', color: 'orange' },
            { content: 'handleModalClick', color: 'blue' },
            { content: ' = ' },
            { content: '()=> {', color: 'pink' },
          ]} />
          <CodeLine indent={2} fragments={[
            { content: 'onClose()', color: 'blue-2' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: '}', color: 'pink' },
          ]} />
          <CodeLine />
          <CodeLine indent={1} fragments={[
            { content: 'return ', color: 'orange' },
            { content: 'ReactDOM', color: 'yellow' },
            { content: '.' },
            { content: 'createPortal', color: 'orange-2' },
            { content: '(', color: 'pink' },
            { content: '(', color: 'yellow' },
          ]} />
          <CodeLine indent={2} fragments={[
            { content: '<div ', color: 'pink' },
            { content: 'className', color: 'orange-2' },
            { content: '=' },
            { content: '"Modal-backstage" ', color: 'green' },
            { content: 'onClick', color: 'orange-2' },
            { content: '=' },
            { content: '{', color: 'red' },
            { content: 'handleModalClick', color: 'blue' },
            { content: '}', color: 'red' },
            { content: '>', color: 'pink' },
          ]} />
          <CodeLine indent={3} fragments={[
            { content: '<div ', color: 'pink' },
            { content: 'className', color: 'orange-2' },
            { content: '=' },
            { content: '"Modal-content" ', color: 'green' },
            { content: 'onClick', color: 'orange-2' },
            { content: '=' },
            { content: '{', color: 'red' },
            { content: 'handleContentClick', color: 'blue' },
            { content: '}', color: 'red' },
            { content: '>', color: 'pink' },
          ]} />
          <CodeLine indent={4} fragments={[
            { content: '{', color: 'red' },
            { content: 'children', color: 'blue-2' },
            { content: '}', color: 'red' },
          ]} />
          <CodeLine indent={3} fragments={[
            { content: '</div>', color: 'pink' },
          ]} />
          <CodeLine indent={2} fragments={[
            { content: '</div>', color: 'pink' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: ')', color: 'yellow' },
            { content: ', ' },
            { content: 'document', color: 'orange' },
            { content: '.' },
            { content: 'getElementById', color: 'orange-2' },
            { content: '(', color: 'yellow' },
            { content: '"modal"', color: 'green' },
            { content: ')', color: 'yellow' },
            { content: ')', color: 'pink' },
          ]} />
          <CodeLine />
          <CodeLine fragments={[
            { content: '}', color: 'pink' },
          ]} />
        </CodeArea>
        <p className={styles.block}>
          The idea is that this is just the shell, the modal's contents will change depending on what I want to render.
          Thus, the <code>children</code> property, which is rendered directly. I've also set up 
          some <strong>CSS</strong> to make it look kind of like a modal. It's not perfect, but it does the job. Note that
          some of the styles are tailored to this site (like the colors).
        </p>
        <CodeArea>
          <CodeLine fragments={[
            { content: '#modal', color: 'pink', modifiers: 'strong' }, 
            { content: ' {' } 
          ]} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('display', 'none')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('width', '100%')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('height', '100%')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('margin', '0px')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('position', 'fixed')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('top', '0px')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('bottom', '0px')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('left', '0px')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('right', '0px')} />
          <CodeLine content='}' />
          <CodeLine />
          <CodeLine fragments={[
            { content: 'body', color: 'orange', modifiers: 'strong' }, 
            { content: '.modal--active', color: 'yellow', modifiers: 'strong' }, 
            { content: ' {' } 
          ]} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('overflow', 'hidden')} />
          <CodeLine content='}' />
          <CodeLine />
          <CodeLine fragments={[
            { content: 'body', color: 'orange', modifiers: 'strong' }, 
            { content: '.modal--active', color: 'yellow', modifiers: 'strong' }, 
            { content: ' #modal', color: 'pink', modifiers: 'strong' }, 
            { content: ' {' } 
          ]} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('display', 'block')} />
          <CodeLine content='}' />
          <CodeLine />
          <CodeLine fragments={[{ content: '.Modal-backstage', color: 'yellow', modifiers: 'strong' }, { content: ' {' } ]} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('width', '100%')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('height', '100%')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('display', 'flex')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('align-items', 'center')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('justify-content', 'center')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('background', 'rgba(0, 0, 0, .75)')} />
          <CodeLine content='}' />
          <CodeLine />
          <CodeLine fragments={[{ content: '.Modal--content', color: 'yellow', modifiers: 'strong' }, { content: ' {' } ]} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('color', 'white')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('border', '1px solid black')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('background', '#282c37')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('width', 'calc(100% - 40px)')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('max-width', '750px')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('padding', '10px')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('border-radius', '5px')} />
          <CodeLine indent={1} fragments={FragmentUtils.CSS.property('box-shadow', '3px 2px 2px 3px rgba(40, 44, 55, .35)')} />
          <CodeLine content='}' />
          <CodeLine />
        </CodeArea>
        <p className={styles.block}>
          What's interesting here is that, thanks to <code>ReactDOM.createPortal</code> I can capture 
          the <code>onClose</code> event, and update the state accordingly, regardless of the "real" DOM hierarchy. 
          The portal connects the two external nodes, and allows bubbling up the events.
        </p>
        <p className={styles.block}>
          That way, the final parent component, in my case, looks like this:
        </p>
        <CodeArea>
          <CodeLine fragments={[
            { content: 'const ', color: 'orange' },
            { content: 'View', color: 'yellow' },
            { content: ' = ', color: 'blue' },
            { content: '()=> { ', color: 'pink' },
          ]} />
          <CodeLine />
          <CodeLine indent={1} fragments={[
            { content: 'const ', color: 'orange' },
            { content: '[', color: 'yellow' },
            { content: 'modal', color: 'blue' },
            { content: ', ' },
            { content: 'setModal', color: 'blue' },
            { content: ']', color: 'yellow' },
            { content: ' = ' },
            { content: 'useState', color: 'blue-2' },
            { content: '(', color: 'yellow' },
            { content: 'false', color: 'orange' },
            { content: ')', color: 'yellow' },
          ]} />
          <CodeLine />
          <CodeLine indent={1} fragments={[
            { content: 'return ', color: 'orange' },
            { content: '(', color: 'yellow' },
          ]} />
          <CodeLine indent={2} fragments={[
            { content: '<React.Fragment>', color: 'blue-2' },
          ]} />
          <CodeLine indent={3} fragments={[
            { content: '{', color: 'red' },
            { content: '/* MAIN CONTENT */', color: 'grey' },
            { content: '}', color: 'red' },
          ]} />
          <CodeLine indent={3} fragments={[
            { content: '<Modal', color: 'violet' },
          ]} />
          <CodeLine indent={4} fragments={[
            { content: 'visible', color: 'pink' },
            { content: '=', color: 'yellow' },
            { content: '{', color: 'red' },
            { content: 'modal', color: 'blue' },
            { content: '}', color: 'red' },
          ]} />
          <CodeLine indent={4} fragments={[
            { content: 'onClose', color: 'pink' },
            { content: '=', color: 'yellow' },
            { content: '{', color: 'red' },
            { content: '()=> ', color: 'wood' },
            { content: 'setModal', color: 'blue' },
            { content: '(', color: 'yellow' },
            { content: 'false', color: 'orange' },
            { content: ')', color: 'yellow' },
            { content: '}', color: 'red' },
          ]} />
          <CodeLine indent={3} fragments={[
            { content: '>', color: 'violet' },
          ]} />
          <CodeLine indent={4} fragments={[
            { content: '{', color: 'red' },
            { content: '/* MODAL CONTENT */', color: 'grey' },
            { content: '}', color: 'red' },
          ]} />
          <CodeLine indent={3} fragments={[
            { content: '</Modal>', color: 'violet' },
          ]} />
          <CodeLine indent={2} fragments={[
            { content: '</React.Fragment>', color: 'blue-2' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: ')', color: 'yellow' },
          ]} />
          <CodeLine fragments={[
            { content: '}', color: 'pink' },
          ]} />
        </CodeArea>
        <p className={styles.block}>
          You can see it in action right here, right now:
        </p>
        <div className={styles.buttonArea}>
          <button 
            onClick={()=> setModal(true)}
            className={styles.button}
          >
            Click me!
          </button>
        </div>
        <p className={styles.block}>
          Pretty sweet, huh? Note that, since the modal is <code>children</code> agnostic, you can render pretty much
          anything inside it. As many React components as you wish. No limits.
        </p>
        <p className={styles.block}>
          Check out the official React documentation for more information on <ReactPortalsLink />
        </p>
      </div>
      <BackToConcepts />
      <Modal 
        visible={modal}
        onClose={()=> setModal(false)}
        className={styles.modal}
      >
        <h1>Let's Go!</h1>
        <video 
          className={styles.video}
          src={video} 
          controls 
          controlsList="nodownload nofullscreen"
        />
        <div className={styles.credit}>
          <TwitterCredit link='https://twitter.com/THEBLUEHAMHAM' handle='ブルーハムハム' />
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ReactPortals