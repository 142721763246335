import React  from 'react'
import tags   from './tags.json'
import styles from './TerminalConcepts.module.css'

const Tag = ({ children })=> <code className={styles.tag}>{children}</code>

const Tags = ({ tags })=> tags.map((tag, i)=> <Tag key={i}>{tag}</Tag>)

const Tab = ({ id, title, children })=> (
  <div className={styles.tab}>
    <input className={styles.tabInput} type="checkbox" id={`terminal-concepts-${id}`} />
    <label className={styles.tabLabel} htmlFor={`terminal-concepts-${id}`}>{title}</label>
    <div className={styles.tabContent}>{children}</div>
  </div>
)

const TerminalConcepts = ()=> (
  <div className={styles.main}>
    <h1 className={styles.title}>Terminal concepts</h1>
    <p className={styles.intro}>
      The terminal is probably my favourite component in the website. It's still a work in progress, but 
      you can see all the details about its implementation and the commands it knows below.
    </p>
    <div className={styles.tabs}>
      <Tab id='1' title='Simple commands'>
        <p className={styles.text}>
          Simple commands I've put into the terminal. Typing this directly (case insensitive) will produce a simple text
          answer. I will be adding more over time. They are usually references to movies, games, songs or memes!
        </p>
        <Tags tags={tags.simple} />
      </Tab>
      <Tab id='2' title='Developer commands'>
        <p className={styles.text}>
          Are you a developer? Have you worked with a unix terminal before? I've put some commands that should be familiar.
        </p>
        <Tags tags={tags.developer} />
      </Tab>
      <Tab id='3' title='Friend commands'>
        <p className={styles.text}>
          Simple commands I've put into the terminal. Typing this directly (case insensitive) will produce a simple text
          answer. However, these are very contextual for friends & family and don't really make much sense otherwise
        </p>
        <Tags tags={tags.friends} />
      </Tab>
      <Tab id='4' title='Commands with links'>
        <p className={styles.text}>
          Simple commands I've put into the terminal, but the terminal will output a link to a website!
        </p>
        <Tags tags={tags.links} />
        <p className={styles.text}>
          Then there's the magic keyword that gets you here, if you want to know more!
        </p>
        <Tag>help</Tag>
      </Tab>
      <Tab id='5' title='Application openers'>
        <p className={styles.text}>
          These commands will attempt to open a program in your computer (if you have it installed!)
        </p>
        <Tags tags={tags.apps} />
      </Tab>
      <Tab id='6' title='Uncertain responses'>
        <p className={styles.text}>
          These commands output a different message depending on things (random things, or the date/time, or whatever I've decided)
        </p>
        <Tags tags={tags.uncertain} />
      </Tab>
      <Tab id='7' title='Special commands'>
        <ul>
          <li className={styles.element}>
            You can use <code>clear</code> to clear the previous messages from the terminal!
          </li>
          <li className={styles.element}>
            If you type a hex color (like <code>#213217</code>) the terminal will output its RGB 
            equivalent (e.g. <code>rgb(33, 50, 23)</code>). This goes both ways, so if you type 
            the RGB it will output the hex value!
          </li>
          <li className={styles.element}>
            One of my proudest days is when I got <code>activate voice interface</code> to work. 
            It combines speech synthesis and voice recognition to make the terminal understand 
            your voice. It can be deactivated with <code>deactivate voice interface</code>.
          </li>
          <li className={styles.element}>
            Do you know where you are going? Then you can type <code>show me &lt;url&gt;</code> and
            it will redirect you to that URL. If you type an external URL, it will take you there too.
            Try typing something like <code>show me pixel-art-generator</code>
          </li>
        </ul>
      </Tab>
    </div>
  </div>
)

export default TerminalConcepts