import React              from 'react'
import ConceptHeader      from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts     from '../../components/BackToConcepts/BackToConcepts'
import CodeArea           from '../../components/CodeArea/CodeArea'
import { CodeLine }       from '../../components/CodeArea/CodeArea'
import { FragmentUtils }  from '../../components/CodeArea/CodeArea'
import styles             from './MongoExtractor.module.css'

const MongoExtractor = () => (
  <React.Fragment>
    <ConceptHeader title='The MongoDB extractor utility' />
    <div className={styles.main}>
      <p className={styles.block}>
        I usually use this little script to extract a CSV file with the contents of
        a <strong>MongoDB</strong> collection. Now, for this to make sense, the collection
        should have similar documents, otherwise, the exported data won't make much sense.
      </p>
      <p className={styles.block}>
        The script iterates through the document keys and prints a CSV with the content. It's
        only suitable if the collection is somewhat manageable.
      </p>
      <CodeArea>
        <CodeLine fragments={FragmentUtils.JS.comment('Version 3.0 (01-02-2020)')} />
        <CodeLine />
        <CodeLine fragments={[
          { content: 'let', color: 'orange' },
          { content: ' datos', color: 'blue' },
          { content: ' = ' },
          { content: '[]', color: 'yellow' },
        ]} />
        <CodeLine />
        <CodeLine fragments={[
          { content: 'const', color: 'orange' },
          { content: ' scanRecursive', color: 'blue' },
          { content: ' = ' },
          { content: '(', color: 'yellow' },
          { content: 'prefix, element', color: 'wood', modifiers: 'italic' },
          { content: ') => {', color: 'yellow' },
        ]} />
        <CodeLine indent={1} fragments={[
          { content: 'let', color: 'orange' },
          { content: ' object_keys', color: 'blue-2' },
          { content: ' = ' },
          { content: '[]', color: 'yellow' },
        ]} />
        <CodeLine indent={1} fragments={[
          { content: 'if ', color: 'orange' },
          { content: '(', color: 'yellow' },
          { content: '!', color: 'orange' },
          { content: 'element', color: 'wood' },
          { content: ')', color: 'yellow' },
          { content: ' return ', color: 'orange' },
          { content: 'object_keys', color: 'blue-2' },
        ]} />
        <CodeLine indent={1} fragments={[
          { content: 'Object.keys' },
          { content: '(', color: 'yellow' },
          { content: 'element', color: 'wood' },
          { content: ')', color: 'yellow' },
          { content: '.forEach' },
          { content: '(', color: 'yellow' },
          { content: 'key', color: 'wood' },
          { content: '=> ', color: 'yellow' },
          { content: '{', color: 'pink' },
        ]} />
        <CodeLine indent={2} fragments={[
          { content: 'if ', color: 'orange' },
          { content: '(', color: 'yellow' },
          { content: 'typeof ', color: 'orange' },
          { content: 'element', color: 'wood' },
          { content: '[', color: 'yellow' },
          { content: 'key', color: 'wood' },
          { content: ']', color: 'yellow' },
          { content: ' !== ', color: 'orange' },
          { content: '"object"', color: 'green', modifiers: 'italic' },
          { content: ') ', color: 'yellow' },
          { content: 'object_keys', color: 'blue-2' },
          { content: '.push' },
          { content: '(', color: 'yellow' },
          { content: 'prefix', color: 'wood' },
          { content: ' + ', color: 'orange' },
          { content: 'key', color: 'wood' },
          { content: ')', color: 'yellow' },
        ]} />
        <CodeLine indent={2} fragments={[
          { content: 'else ', color: 'orange' },
          { content: 'scanRecursive', color: 'blue' },
          { content: '(', color: 'yellow' },
          { content: 'prefix', color: 'wood' },
          { content: ' + ', color: 'orange' },
          { content: 'key', color: 'wood' },
          { content: ' + ', color: 'orange' },
          { content: '"."', color: 'green', modifiers: 'italic' },
          { content: ', ' },
          { content: 'element', color: 'wood' },
          { content: '[', color: 'yellow' },
          { content: 'key', color: 'wood' },
          { content: ']', color: 'yellow' },
          { content: ')', color: 'yellow' },
          { content: '.forEach' },
          { content: '(', color: 'yellow' },
          { content: 'i_key', color: 'wood' },
          { content: '=> ', color: 'yellow' },
          { content: 'object_keys', color: 'blue-2' },
          { content: '.push' },
          { content: '(', color: 'yellow' },
          { content: 'i_key', color: 'wood' },
          { content: ')', color: 'yellow' },
          { content: ')', color: 'yellow' },
        ]} />
        <CodeLine indent={1} fragments={[
          { content: '}', color: 'pink' },
          { content: ')', color: 'yellow' },
        ]} />
        <CodeLine indent={1} fragments={[
          { content: 'return ', color: 'orange' },
          { content: 'object_keys', color: 'blue-2' },
        ]} />
        <CodeLine fragments={[
          { content: '}', color: 'yellow' },
        ]} />
        <CodeLine />
        <CodeLine fragments={[
          { content: 'const', color: 'orange' },
          { content: ' getValue', color: 'blue' },
          { content: ' = ' },
          { content: '(', color: 'yellow' },
          { content: 'key, object', color: 'wood', modifiers: 'italic' },
          { content: ') => {', color: 'yellow' },
        ]} />
        <CodeLine indent={1} fragments={[
          { content: 'const', color: 'orange' },
          { content: ' path', color: 'blue-2' },
          { content: ' = ' },
          { content: 'key', color: 'wood' },
          { content: '.split' },
          { content: '(', color: 'yellow' },
          { content: '"."', color: 'green' },
          { content: ')', color: 'yellow' },
        ]} />
        <CodeLine indent={1} fragments={[
          { content: 'let', color: 'orange' },
          { content: ' current_object', color: 'blue-2' },
          { content: ' = ' },
          { content: 'object', color: 'wood' },
        ]} />
        <CodeLine indent={1} fragments={[
          { content: 'for', color: 'orange' },
          { content: ' (', color: 'yellow' },
          { content: 'let ', color: 'orange' },
          { content: 'i', color: 'wood' },
          { content: '=', color: 'orange' },
          { content: '0' },
          { content: '; ', color: 'grey-3' },
          { content: 'i', color: 'wood' },
          { content: '<', color: 'orange' },
          { content: 'path', color: 'blue-2' },
          { content: '.length' },
          { content: '; ', color: 'grey-3' },
          { content: 'i', color: 'wood' },
          { content: '++', color: 'orange' },
          { content: ') {', color: 'yellow' },
        ]} />
        <CodeLine indent={2} fragments={[
          { content: 'if', color: 'orange' },
          { content: ' (', color: 'yellow' },
          { content: 'current_object ', color: 'blue-2' },
          { content: ' && ', color: 'yellow' },
          { content: 'current_object', color: 'blue-2' },
          { content: '[', color: 'yellow' },
          { content: 'path', color: 'blue-2' },
          { content: '[', color: 'yellow' },
          { content: 'i', color: 'wood' },
          { content: ']]) ', color: 'yellow' },
          { content: 'current_object', color: 'blue-2' },
          { content: ' = ', color: 'yellow' },
          { content: 'current_object', color: 'blue-2' },
          { content: '[', color: 'yellow' },
          { content: 'path', color: 'blue-2' },
          { content: '[', color: 'yellow' },
          { content: 'i', color: 'wood' },
          { content: ']]) ', color: 'yellow' },
        ]} />
        <CodeLine indent={2} fragments={[
          { content: 'else ', color: 'orange' },
          { content: 'return ', color: 'orange' },
          { content: 'undefined', color: 'pink' },
        ]} />
        <CodeLine indent={1} fragments={[
          { content: '}', color: 'yellow' },
        ]} />
        <CodeLine indent={1} fragments={[
          { content: 'if ', color: 'orange' },
          { content: '(', color: 'yellow' },
          { content: 'typeof ', color: 'orange' },
          { content: 'current_object', color: 'blue-2' },
          { content: ' === ', color: 'orange' },
          { content: '"string"', color: 'green' },
          { content: ') {', color: 'yellow' },
        ]} />
        <CodeLine indent={2} fragments={[
          { content: 'return ', color: 'orange' },
          { content: 'current_object', color: 'blue-2' },
        ]} />
        <CodeLine indent={3} fragments={[
          { content: '.replace' },
          { content: '(', color: 'yellow' },
          { content: '/', color: 'orange-2' },
          { content: '(\\r\\n|\\n|\\r)', color: 'violet' },
          { content: '/', color: 'orange-2' },
          { content: 'gm', color: 'orange-2' },
          { content: ', ' },
          { content: '""', color: 'green' },
          { content: ')', color: 'yellow' },
        ]} />
        <CodeLine indent={3} fragments={[
          { content: '.replace' },
          { content: '(', color: 'yellow' },
          { content: '/', color: 'orange-2' },
          { content: ';', color: 'violet' },
          { content: '/', color: 'orange-2' },
          { content: 'g', color: 'orange-2' },
          { content: ', ' },
          { content: '""', color: 'green' },
          { content: ')', color: 'yellow' },
        ]} />
        <CodeLine indent={1} fragments={[
          { content: '}', color: 'yellow' },
        ]} />
        <CodeLine indent={1} fragments={[
          { content: 'return ', color: 'orange' },
          { content: 'current_object', color: 'blue-2' },
        ]} />
        <CodeLine fragments={[
          { content: '}', color: 'yellow' },
        ]} />
        <CodeLine />
        <CodeLine fragments={[
          { content: 'db', color: 'pink' },
          { content: '.' },
          { content: '<collection-name>', color: 'grey-2' },
          { content: '.find' },
          { content: '(', color: 'yellow' },
          { content: '{}', color: 'green-2' },
          { content: ')', color: 'yellow' },
          { content: '.forEach' },
          { content: '(', color: 'yellow' },
          { content: 'el', color: 'wood' },
          { content: '=> {', color: 'yellow' },
        ]} />
        <CodeLine indent={1} fragments={[
          { content: 'const ', color: 'orange' },
          { content: 'keys', color: 'blue-2' },
          { content: ' = ', color: 'orange' },
          { content: 'scanRecursive', color: 'blue' },
          { content: '(', color: 'yellow' },
          { content: '""', color: 'green' },
          { content: ', ' },
          { content: 'el', color: 'wood' },
          { content: ')', color: 'yellow' },
        ]} />
        <CodeLine indent={1} fragments={[
          { content: 'keys', color: 'blue-2' },
          { content: '.forEach' },
          { content: '(', color: 'yellow' },
          { content: 'key', color: 'wood' },
          { content: '=> ', color: 'yellow' },
          { content: '!', color: 'orange' },
          { content: 'datos', color: 'blue' },
          { content: '.includes' },
          { content: '(', color: 'yellow' },
          { content: 'key', color: 'wood' },
          { content: ')', color: 'yellow' },
          { content: ' && ', color: 'orange' },
          { content: 'datos', color: 'blue' },
          { content: '.push' },
          { content: '(', color: 'yellow' },
          { content: 'key', color: 'wood' },
          { content: ')', color: 'yellow' },
          { content: ')', color: 'yellow' },
        ]} />
        <CodeLine fragments={[
          { content: '})', color: 'yellow' },
        ]} />
        <CodeLine />
        <CodeLine fragments={[
          { content: 'print', color: 'pink' },
          { content: '(', color: 'yellow' },
          { content: 'datos', color: 'blue' },
          { content: '.sort' },
          { content: '()', color: 'yellow' },
          { content: '.join' },
          { content: '(', color: 'yellow' },
          { content: '";"', color: 'green' },
          { content: '))', color: 'yellow' },
        ]} />
        <CodeLine />
        <CodeLine fragments={[
          { content: 'db', color: 'pink' },
          { content: '.' },
          { content: '<collection-name>', color: 'grey-2' },
          { content: '.find' },
          { content: '(', color: 'yellow' },
          { content: '{}', color: 'green-2' },
          { content: ')', color: 'yellow' },
          { content: '.forEach' },
          { content: '(', color: 'yellow' },
          { content: 'el', color: 'wood' },
          { content: '=> ', color: 'yellow' },
          { content: 'print', color: 'pink' },
          { content: '(', color: 'yellow' },
          { content: 'datos', color: 'blue' },
          { content: '.map' },
          { content: '(', color: 'yellow' },
          { content: 'key', color: 'violet' },
          { content: '=> ', color: 'yellow' },
          { content: 'getValue', color: 'blue' },
          { content: '(', color: 'yellow' },
          { content: 'key', color: 'violet' },
          { content: ', ' },
          { content: 'el', color: 'wood' },
          { content: '))', color: 'yellow' },
          { content: '.join' },
          { content: '(', color: 'yellow' },
          { content: '";"', color: 'green' },
          { content: ')))', color: 'yellow' },
        ]} />
      </CodeArea>
      <p className={styles.block}>
        Make sure to substitute <strong>&lt;collection-name&gt;</strong> with the collection name 
        that you want to export to CSV.
      </p>
    </div>
    <BackToConcepts />
  </React.Fragment>
)

export default MongoExtractor