export const grayscale = canvas=> {
  const context = canvas.getContext('2d')
  context.filter = "grayscale(100%)"
  context.drawImage(canvas, 0, 0)  
}

export const sepia = canvas=> {
  const context = canvas.getContext('2d')
  context.filter = "sepia(100%)"
  context.drawImage(canvas, 0, 0)
}

export const lighten = canvas=> {
  const context = canvas.getContext('2d')
  context.filter = "brightness(110%)"
  context.drawImage(canvas, 0, 0)
}

export const darken = canvas=> {
  const context = canvas.getContext('2d')
  context.filter = "brightness(90%)"
  context.drawImage(canvas, 0, 0)
}

export const blur = canvas=> {
  const context = canvas.getContext('2d')
  context.filter = "blur(3px)"
  context.drawImage(canvas, 0, 0)
}

export const invert = canvas=> {
  const context = canvas.getContext('2d')
  context.filter = "invert(100%)"
  context.drawImage(canvas, 0, 0)
}

export const hueRotate = canvas=> {
  const context = canvas.getContext('2d')
  context.filter = "hue-rotate(45deg)"
  context.drawImage(canvas, 0, 0)
}
