import React        from 'react'
import { Link }     from 'react-router-dom'
import styles       from './MoreGames.module.css'

const MoreGames = ({ label='More games' })=> (
  <div className={styles.container}>
    <Link to='/games' className={styles.link}>
      {label}
    </Link>
  </div>
)

export default MoreGames