import React    from 'react'
import hooks    from './resources/hook.png'
import styles   from './Cover.module.css'

const Cover = ()=> (
  <div className={styles.main}>
    <img className={styles.hook} src={hooks} alt='React hooks' />
  </div>
)

export default Cover