import React, { useState, useEffect }     from 'react'
import Switch                             from '../../components/Switch/Switch'
import css                                from './Cover.module.css'

const noop = ()=> {}

const Cover = ()=> {

  const [active, setActive] = useState(false)

  useEffect(()=> {
    const interval = setInterval(()=> setActive(active=> !active), 3000)
    return ()=> clearInterval(interval)
  }, [])

  return (
    <div className={css.main}>
      <Switch className={css.switch} checked={active} onClick={noop} />
      <span className={css.label}>Production</span>
    </div>
  )
}

export default Cover