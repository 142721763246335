import { formatNumber }   from '../numbers'
import css                from './TrainTalent.module.css'

const TrainTalent = ({ 
  name, 
  description,
  cost, 
  lines, 
  onClick 
})=> (
  <div className={css.main}>
    <div className={css.info}>
      <p className={css.job} title={description}>
        {name}
      </p>
    </div>
    <button
      className={css.button}
      onClick={onClick}
      disabled={cost > lines+1}
    >
      Train ({formatNumber(Math.floor(cost))} λ)
    </button>
  </div>
)

export default TrainTalent