const _applyTransformation = (image_id, transformation) => {
  const canvas = document.createElement('canvas')
  const source = document.getElementById(image_id)
  if (!source || !source.width) return null
  canvas.setAttribute('width', source.width)
  canvas.setAttribute('height', source.height)
  const context = canvas.getContext('2d')
  context.drawImage(source, 0, 0)
  const image_data = context.getImageData(0, 0, source.width, source.height)
  const raw_data = image_data.data
  transformation(raw_data, [source.width, source.height])
  context.putImageData(image_data, 0, 0)
  return canvas.toDataURL()
}


export const generateBrightnessImage = () => {
  const transformation = raw_data => {
    for (let x = 0; x < raw_data.length; x += 4) {
      const avg = Math.round(0.299 * raw_data[x] + 0.587 * raw_data[x + 1] + 0.114 * raw_data[x + 2])
      raw_data[x] = avg
      raw_data[x + 1] = avg
      raw_data[x + 2] = avg
    }
  }
  return _applyTransformation('sample-image', transformation)
}

export const generateGrayscaleImage = () => {
  const transformation = raw_data => {
    for (let x = 0; x < raw_data.length; x += 4) {
      const avg = Math.round((raw_data[x] + raw_data[x + 1] + raw_data[x + 2]) / 3)
      raw_data[x] = avg
      raw_data[x + 1] = avg
      raw_data[x + 2] = avg
    }
  }
  return _applyTransformation('sample-image', transformation)
}

export const generateImageBRG = () => {
  const transformation = raw_data => {
    for (let x = 0; x < raw_data.length; x += 4) {
      const red = raw_data[x]
      raw_data[x] = raw_data[x + 2]
      raw_data[x + 2] = raw_data[x + 1]
      raw_data[x + 1] = red
    }
  }
  return _applyTransformation('sample-image', transformation)
}

export const generateFilteredImage = filter => {
  const transformation = raw_data => {
    for (let x = 0; x < raw_data.length; x += 4) {
      if (filter === 'red') raw_data[x] = 0
      if (filter === 'green') raw_data[x + 1] = 0
      if (filter === 'blue') raw_data[x + 2] = 0
    }
  }
  return _applyTransformation('sample-image', transformation)
}

export const generateCircleImage = radius => {
  const transformation = (raw_data, [width, height]) => {
    const center = [width / 2, height / 2]
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const distance_x = Math.abs(center[0] - x)
        const distance_y = Math.abs(center[1] - y)
        const total_distance = Math.sqrt(distance_x * distance_x + distance_y * distance_y)
        if (total_distance > radius) continue
        const offset = 4 * (x + y * width)
        const avg = Math.round((raw_data[offset] + raw_data[offset + 1] + raw_data[offset + 2]) / 3)
        raw_data[offset] = avg
        raw_data[offset + 1] = avg
        raw_data[offset + 2] = avg
      }
    }
  }
  return _applyTransformation('sample-image', transformation)
}

export const sampleImageVertical = () => {
  const canvas = document.createElement('canvas')
  const source = document.getElementById('sample-image')
  if (!source || !source.width || !source.height) return null
  canvas.setAttribute('width', source.width)
  canvas.setAttribute('height', source.height)
  const context = canvas.getContext('2d')
  context.drawImage(source, 0, 0)
  const image_data = context.getImageData(0, 0, source.width, source.height)
  const new_image_data = context.createImageData(source.width, Math.ceil(source.height / 2))
  const raw_data = image_data.data
  const new_raw_data = new_image_data.data
  for (let y = 0; y < source.height; y += 2) {
    raw_data
      .slice(4 * source.width * y, 4 * source.width * (y + 1))
      .forEach((value, i) => new_raw_data[4 * source.width * y / 2 + i] = value)
  }
  canvas.setAttribute('height', Math.ceil(source.height / 2))
  context.putImageData(new_image_data, 0, 0)
  return canvas.toDataURL()
}