import React, { useState }  from 'react'
import regions              from './regions.json'
import visited              from './visited.json'
import css                  from './WorldMap.module.css'

const getColorFromRegion = (visitedRegions, currentRegion, colorOffset=0) => {
  if (!visitedRegions.includes(currentRegion)) return '#ecececa0'
  const letter = currentRegion.substring(0, 1).toLowerCase()
  const colors = ['warning', 'info', 'code', 'link', 'shakers', 'polygon'].map(e=> `var(--color-${e})`)
  let base = 0
  
  if ('akpuz'.split('').includes(letter)) base = 0
  if ('bgqv'.split('').includes(letter)) base = 1
  if ('chrw'.split('').includes(letter)) base = 2
  if ('disx'.split('').includes(letter)) base = 3
  if ('ejty'.split('').includes(letter)) base = 4
  if ('flmno'.split('').includes(letter)) base = 5

  return colors[(base+colorOffset)%6]
}

const WorldMap = () => {

  const [offset, setOffset] = useState(0)

  const [zoom, setZoom] = useState(1)

  const increaseZoom = () => setZoom(Math.min(15, zoom + 1))
  const decreaseZoom = () => setZoom(Math.max(1, zoom - 1))

  return (
    <div className={css.main}>
      <h1 className={css.title}>The World Map</h1>
      <div className={css.container}>
        <div className={css.mapBox}>
          <div className={css.mapContainer} data-zoom={zoom}>
            <svg className={css.map} viewBox="0 0 2000 857" onClick={()=> setOffset(c=> (c+1)%5)}>
              {regions.map((region, i) => (
                <path
                  className={css.region}
                  key={i}
                  d={region.path}
                  data-name={region.name}
                  data-status={visited.includes(region.name) ? 'visited' : 'pending'}
                  fill={getColorFromRegion(visited, region.name, offset)}
                  title={region.name}
                />
              ))}
            </svg>
          </div>
        </div>
        <div className={css.legend}>
          <div className={css.controls}>
            <button onClick={increaseZoom} className={css.zoom}>🔎</button>
            <button onClick={decreaseZoom} className={css.zoom}>🔭</button>
          </div>
          <p className={css.subtitle}>
            Countries around the world I have visited in my life.
          </p>
        </div>
      </div>
    </div>
  )
}

export default WorldMap