import { useCallback, useEffect, useRef } from 'react'

const useAnimationFrame = callback => {
  const requestRef = useRef();
  const previousTimeRef = useRef();
  
  const animate = useCallback(time => {
    if (previousTimeRef.current) {
      const deltaTime = time - previousTimeRef.current;
      callback(deltaTime)
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  }, [callback]);
  
  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [animate]);

}

export default useAnimationFrame