import React                  from 'react'
import BackToConcepts         from '../../components/BackToConcepts/BackToConcepts'
import ConceptHeader          from '../../components/ConceptHeader/ConceptHeader'
import css                    from './ColorGrid.module.css'

const getRandomColor = ()=> {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
}

const paintBackground = ({ element, color })=> {
  element.style.backgroundColor = color;
  setTimeout(() => {
    element.style.backgroundColor = "initial";
  }, 500);
}

const ColorGrid = ()=> {

  const handleMouseEnter = (event) => {
    paintBackground({ 
      element: event.currentTarget, 
      color: getRandomColor() 
    });
  }

  return (
    <React.Fragment>
      <ConceptHeader title='Color Grid' />
      <div className={css.content}>
        <div className={css.grid}>
          {new Array(100).fill(0).map((_, i)=> (
            <div 
              key={i} 
              className={css.cell} 
              onMouseEnter={handleMouseEnter}
              onTouchStart={handleMouseEnter}
            /> 
          ))}
        </div>
        <p className={css.block}>
          Move your cursor across the grid to see the colors change. Works best on desktop.
        </p>
      </div>
      <BackToConcepts />
    </React.Fragment>
  )
}

export default ColorGrid