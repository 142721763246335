export const shuffle = data=> {
  const elements = [...data]
  let j, x, i
  for (i = elements.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1))
      x = elements[i]
      elements[i] = elements[j]
      elements[j] = x
  }
  return elements
}

export const bound = (value, interval) => Math.max(interval[0], Math.min(value, interval[1]))