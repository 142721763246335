import React    from 'react'
import styles   from './Cover.module.css'

const Note = ()=> (
  <svg className={styles.note} viewBox="0 0 25.8 25.8" >
    <path className={styles.path} d="M16.681,13.4c-0.7-0.8-1.7-1.3-2.9-1.2c-0.1-0.2-0.5-2.2-0.5-2.2s1.4-2.1,1.7-2.5   c1.5-2.4,1.6-4.3,0.7-6.2c-0.3-0.7-1.1-1.3-1.9-1.3c-0.6,0-1.1,0.5-1.4,1.1c-1,1.7-1,4.9-0.5,7.1c0,0-0.5,0.5-1,1.1   c-1,1.1-2.1,2.3-2.5,3.9c-0.5,1.8-0.1,3.5,1,4.8c1.2,1.5,3,2.2,5.2,2c0.4,1.9,0.7,3.1,0.1,4.2c-0.2,0.4-0.6,0.8-1.3,0.8   c-0.2,0-0.3,0-0.5-0.1c0.6-0.3,1.1-0.9,1.1-1.6c0-1-0.8-1.8-1.8-1.8c-1.7,0.1-1.8,1.8-1.8,1.8c0,0.6,0.2,1.2,0.6,1.6   c0.5,0.5,1.3,0.9,2.2,0.9c1.1,0,2.2-0.4,2.6-2c0.4-1.3-0.3-3.4-0.4-4.1c1.1-0.5,2.1-2,2.2-3C17.781,15.6,17.381,14.3,16.681,13.4z    M13.481,2.3c0.1-0.2,0.3-0.3,0.5-0.3c0.1,0,0.3,0.1,0.4,0.2c0.4,0.2,0.5,0.6,0.5,1.2c-0.1,1.5-1.4,3.3-2.1,3.9   C12.481,5.9,12.681,3.3,13.481,2.3z M10.581,18c-0.7-0.7-1-1.7-0.9-2.7c0.2-1.6,1.3-2.9,2.2-3.9c0.2-0.2,0.4-0.4,0.5-0.6   c0.1,0.5,0.4,1.3,0.5,1.7c-1.2,0.5-1.9,2.1-1.8,3.4c0,0.6,0.2,1,0.5,1.4c0.1,0.1,0.2,0.2,0.3,0.2s0.3-0.1,0.3-0.2l0,0   c0.1-0.1,0.1-0.3,0-0.4s-0.1-0.2-0.1-0.4c-0.1-0.9,0.5-1.6,1.2-1.9c0.1,0.6,0.8,3.7,0.9,4.4C12.881,19.4,11.481,19,10.581,18z    M15.081,18.6c-0.2-0.7-0.3-1.5-0.5-2.4c-0.1-0.6-0.2-1.3-0.4-1.9c1.3,0,1.7,1.1,1.8,1.5C16.281,16.9,15.981,18,15.081,18.6z" />
  </svg>
)

const Cover = ()=> (
  <div className={styles.cover}>
    <Note />
  </div>
)

export default Cover