import React  from 'react'
import styles from './Cover.module.css'

const Cover = () => (
  <div className={styles.main}>
    <div className={styles.scissors}>
      <div className={styles.half}>
        <div className={styles.blade} />
        <div className={styles.handle} />
      </div>
      <div className={styles.half}>
        <div className={styles.blade} />
        <div className={styles.handle} />
      </div>
      <div className={styles.joint} />
    </div>
  </div>
)

export default Cover