import React                            from 'react'
import { useLocation, Redirect, Link }  from 'react-router-dom'
import styles                           from './Recursion.module.css'

const Recursion = ()=> {

  const { pathname } = useLocation()
  const isRecursive = pathname.match(/^(\/recursion)+$/)
  if (!isRecursive) return <Redirect to='/recursion' />

  const level = Math.floor(pathname.length / 10)

  const depth = level < 2 ? 'not too deep' : <>{'d'}<strong>{(new Array(level).fill('e').join(''))}</strong>{'p'}</>
  const new_url = (new Array(level+1).fill('/recursion').join(''))
  const old_url = (new Array(Math.max(level-1, 1)).fill('/recursion').join(''))

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <p className={styles.level}>Level {level}</p>
        <p className={styles.depth}>That's {depth}</p>
        <p className={styles.url}>Just look at that URL</p>
        <p className={styles.arrows}>↑↑↑</p>
        <div className={styles.links}>
          <Link className={styles.link} to={new_url}>Go deeper ⤳</Link>
          {level > 1 && <Link className={styles.link} to={old_url}>⬿ Fuck, no, take me back</Link>}
        </div>
      </div>
    </div>
  )

}

export default Recursion