import css from './ResourceGroup.module.css'

const ResourceGroup = ({ title, total, labelHint, isExpanded, isUnlocked=true, onClick, children })=> {
  if (!isUnlocked) return null
  return (
    <div className={css.group}>
      <h1 className={css.title} onClick={onClick}>
        {isExpanded ? '-' : '+'} {title} 
        {Number.isFinite(total) && (
          <label className={css.label}>{total} {labelHint}</label>
        )}
      </h1>
      {isExpanded && (
        <div className={css.content}>
          {children}
        </div>
      )}
    </div>
  )
}

export default ResourceGroup