import React, { useState }                  from 'react'
import ConceptHeader                        from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts                       from '../../components/BackToConcepts/BackToConcepts'
import CodeArea                             from '../../components/CodeArea/CodeArea'
import { CodeLine }                         from '../../components/CodeArea/CodeArea'
import { getColorFromImage, getBrightness } from '../../utils/image'
import useInterval                          from '../../hooks/useInterval'
import swan                                 from './resources/swan.mp4'
import styles                               from './VideoASCII.module.css'

const getCharacter = brightness => {
  const ASCII = '.,:;i1tfLCG08@'
  const end = ASCII.length - 1
  return ASCII.charAt(end - Math.round(brightness * end))
}

const getASCII = () => {
  const canvas = document.createElement('canvas')
  const source = document.getElementById('sample-video')
  if (source.readyState === 0) return
  canvas.setAttribute('width', source.videoWidth)
  canvas.setAttribute('height', source.videoHeight)
  const context = canvas.getContext('2d')
  context.drawImage(source, 0, 0)
  const image_data = context.getImageData(0, 0, source.videoWidth, source.videoHeight)
  const raw_data = image_data.data
  let result = ''
  for (let y = 0; y < source.videoHeight; y += 2) {
    for (let x = 0; x < source.videoWidth; x++) {
      const offset = 4 * (x + source.videoWidth * y)
      const color = getColorFromImage(raw_data, offset)
      const brightness = getBrightness(color)
      const ascii = getCharacter(brightness)
      result += ascii
    }
    result += '\n'
  }
  return result
}

const getWebcamASCII = video=> {
  // Webcam can be very HD, let's limit it
  const MAX_WIDTH = 300
  // Actual processing
  if (!video || video.readyState === 0) return
  const canvas = document.createElement('canvas')
  const ratio = Math.min(1, MAX_WIDTH / video.videoWidth)
  canvas.setAttribute('width', ratio * video.videoWidth)
  canvas.setAttribute('height', ratio * video.videoHeight)
  const context = canvas.getContext('2d')
  context.translate(canvas.width, 0)
  context.scale(-1, 1)
  context.drawImage(video, 0, 0, ratio * video.videoWidth, ratio * video.videoHeight)
  const image_data = context.getImageData(0, 0, ratio * video.videoWidth, ratio * video.videoHeight)
  const raw_data = image_data.data
  let result = ''
  for (let y = 0; y < ratio * video.videoHeight; y += 2) {
    for (let x = 0; x < ratio * video.videoWidth; x++) {
      const offset = 4 * (x + ratio * video.videoWidth * y)
      const color = getColorFromImage(raw_data, offset)
      const brightness = getBrightness(color)
      const ascii = getCharacter(brightness)
      result += ascii
    }
    result += '\n'
  }
  return result
}

const VideoASCII = () => {

  const [sampleASCII, setSampleASCII] = useState('')
  const [active, setActive] = useState(false)

  const [webcamStatus, setWebcamStatus] = useState({ status: 'Pending', message: '' })
  const [webcamASCII, setWebcamASCII] = useState('')

  useInterval(()=> {
    if (!active) return
    const ascii = getASCII()
    if (ascii) setSampleASCII(ascii)
  }, 30)

  useInterval(()=> {
    if (webcamStatus.status !== 'OK') return
    const ascii = getWebcamASCII(webcamStatus.video)
    if (ascii) setWebcamASCII(ascii)
  }, 30)

  const handleWebcam = ()=> {
    if (webcamStatus.status === 'OK') {
      setWebcamStatus({ status: 'Pending', message: '' })
      return
    }
    if (!navigator.getUserMedia) {
      setWebcamStatus({ status: 'Error', message: 'No API available on this browser' })
    }
    else {
      navigator.mediaDevices.getUserMedia({ video: true, audio: false })
        .then(stream=> {
          const video = document.createElement('video')
          video.srcObject = stream
          video.play()
          setWebcamStatus({status: 'OK', message: 'OK', video})
        })
        .catch(()=> setWebcamStatus({status: 'Error', message: 'There was an error accessing the webcam'}))
    }
  }

  return (
    <React.Fragment>
      <ConceptHeader title='Transforming video to ASCII characters' />
      <div className={styles.main}>
        <p className={styles.block}>
          We have already discuss most of the procedure in an earlier concept, which involves
          transforming images to ASCII characters. Video is mostly the same, except we are going
          to have to make a few modifications to make the ASCII sync with the video.
        </p>
        <h2 className={styles.header}>Part I: Using a <code>&lt;video&gt;</code> tag</h2>
        <p className={styles.block}>
          The idea is mostly the same as with an image, the video is just images at a certain framerate. It's
          just a matter of loading in a canvas the current video frame, and doing the same work we were already
          doing for processing the image.
        </p>
        <p className={styles.block}>
          The ASCII characters' information will be updated using <code>setInterval</code>, so it's important
          to keep track of the video status through it's event system. The ASCII should not be updating if the
          video is paused or has ended.
        </p>
        <p className={styles.block}>
          Also note, that for performance / incompatiblity reasons, I've removed the whitespace from the available
          characters, as React has some issues rendering long blank strings.
        </p>
        <div className={styles.results}>
          <video 
            id='sample-video' 
            className={styles.video} 
            src={swan} 
            onPlay={()=> !active && setActive(true)}
            onPlaying={() => !active && setActive(true)}
            onPause={()=> active && setActive(false)}
            onSeeking={()=> !active && setActive(true)}
            onSeeked={()=> active && setActive(false)}
            controls 
            muted
            loop
          />
          <p className={styles.result}>
            {sampleASCII.split('\n').map((row, i) => (
              <span key={`${i}-${row}`} className={styles.row}>
                {row}
              </span>
            ))}
          </p>
        </div>
        <h2 className={styles.header}>Part II: Using a webcam (captured media)</h2>
        <p className={styles.block}>
          First things first, not every browser supports <code>getUserMedia</code> and not every device has a webcam
          available. To get this to work properly, make sure the user has a webcam and the browser allows webcam capturing.
        </p>
        <p className={styles.block}>
          Also note that getting data from the webcam (audio or video) requires a secure connection (yes, HTTPS). For development
          purposes, <code>localhost</code> is allowed to access the contents without said connection, but otherwise, the browser
          will refuse to produce a video stream.
        </p> 
        <p className={styles.block}>
          The easiest approach here is to create a faux <code>video</code> tag and set it's <strong>src</strong> attribute to the
          video stream produced by the webcam (The audio does not matter for this experiment). After doing so, we are in the first
          scenario, which is transforming a video to ASCII (and that's suuuper easy).
        </p>
        <p className={styles.block}>
          So, the idea here is to handle the webcam as follows:
        </p>
        <CodeArea>
          <CodeLine fragments={[
            { content: 'navigator', color: 'pink' },
            { content: '.' },
            { content: 'mediaDevices', color: 'wood' },
            { content: '.' },
            { content: 'getUserMedia', color: 'blue' },
            { content: '(', color: 'orange' },
            { content: '{ ', color: 'yellow' },
            { content: 'video', color: 'green-2' },
            { content: ': ' },
            { content: 'true', color: 'red' },
            { content: ', ' },
            { content: 'audio', color: 'green-2' },
            { content: ': ' },
            { content: 'false', color: 'red' },
            { content: ' }', color: 'yellow' },
            { content: ')', color: 'orange' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: '.' },
            { content: 'then', color: 'blue' },
            { content: '(', color: 'orange' },
            { content: 'stream', color: 'blue-2' },
            { content: '=> ', color: 'yellow' },
            { content: '{ ', color: 'orange' },
          ]} />
          <CodeLine indent={2} fragments={[
            { content: 'const ', color: 'orange' },
            { content: 'video', color: 'violet' },
            { content: ' = ' },
            { content: 'document', color: 'pink' },
            { content: '.' },
            { content: 'createElement', color: 'wood' },
            { content: '(', color: 'yellow' },
            { content: '"video"', color: 'green' },
            { content: ')', color: 'yellow' },
          ]} />
          <CodeLine indent={2} fragments={[
            { content: 'video', color: 'violet' },
            { content: '.' },
            { content: 'srcObject', color: 'wood' },
            { content: ' = ' },
            { content: 'stream', color: 'yellow' },
          ]} />
          <CodeLine indent={2} fragments={[
            { content: 'video', color: 'violet' },
            { content: '.' },
            { content: 'play', color: 'wood' },
            { content: '()', color: 'yellow' },
          ]} />
          <CodeLine indent={1} fragments={[
            { content: '}', color: 'orange' },
            { content: ')', color: 'orange' },
          ]} />
        </CodeArea>
        
        <div className={styles.status} data-status={webcamStatus.status}>
          <button className={styles.button} onClick={handleWebcam}>
            {webcamStatus.status === 'OK' ? 'STOP' : 'GO'}
          </button>
          <p className={styles.text}>
            {webcamStatus.status === 'OK' && 'LIVE'}
            {webcamStatus.status === 'Pending' && 'WAIT'}
            {webcamStatus.status === 'Error' && 'ERROR'}
          </p>
        </div>
        <p className={styles.result}>
          {webcamASCII.split('\n').map((row, i) => (
            <span key={`${i}-${row}`} className={styles.row}>
              {row}
            </span>
          ))}
        </p>
      </div>
      <BackToConcepts />
    </React.Fragment>
  )
}

export default VideoASCII