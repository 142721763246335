import React  from 'react'
import mongo  from './resources/mongo.png'
import css    from './Cover.module.css'

const Cover = () => (
  <div className={css.cover}>
    <img className={css.logo} src={mongo} alt='Mongo DB' />
  </div>
)

export default Cover