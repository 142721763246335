import React    from 'react'
import express  from './resources/express.png'
import mongo    from './resources/mongo.png'
import node     from './resources/node.png'
import react    from './resources/react.png'
import styles   from './Cover.module.css'

const Cover = ()=> (
  <div className={styles.main}>
    <img className={styles.mongo} src={mongo} alt='Mongo DB' />
    <img className={styles.express} src={express} alt='Express JS' />
    <img className={styles.react} src={react} alt='React' />
    <img className={styles.node} src={node} alt='Node JS' />
  </div>
)

export default Cover