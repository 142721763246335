import React, { useState, useEffect } from 'react'
import { Link }                       from 'react-router-dom'
import NotFoundIcon                   from './NotFoundIcon'
import css                            from './NotFound.module.css'

const pathMouthExpression = expression=> {
  if (expression === 'sad') return "M 55 180 H 145 C 145 130 55 130 55 180"
  if (expression === 'happy') return "M 55 150 H 145 C 145 200 55 200 55 150"
}

const fillGhostBody = type=> {
  if (type === 'white') return '#e2e2e2'
  else if (type === 'female') return '#F88B9D'
  else if (type === 'lgbt') return 'url(#lgbt)'
}

const NotFoundGhost = ({ type, expression, onClick })=> (
  <div className={css.ghost} onClick={onClick}>
    <svg className={css.avatar} viewBox="0 0 210 360">
      <defs>
        <linearGradient id="lgbt" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#f00000', stopOpacity: 1 }} />
          <stop offset="16.67%" style={{ stopColor: '#ff8000', stopOpacity: 1 }} />
          <stop offset="33.33%" style={{ stopColor: '#ffff00', stopOpacity: 1 }} />
          <stop offset="50%" style={{ stopColor: '#007940', stopOpacity: 1 }} />
          <stop offset="66.67%" style={{ stopColor: '#4040ff', stopOpacity: 1 }} />
          <stop offset="83.33%" style={{ stopColor: '#a000c0', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#a000c0', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <g className={css.ghostBody}>
        <path 
          fill={fillGhostBody(type)}
          d="M 5 100 C 0 -30 205 -30 205 100 V 270 C 205 300 165 300 165 270 C 165 300 125 300 125 270 C 125 300 85 300 85 270 C 85 300 45 300 45 270 C 45 300 5 300 5 270 V 100" 
        />
        <circle cx="65" cy="100" r="18" className={css.ghostEye}/>
        <circle cx="135" cy="100" r="18" className={css.ghostEye}/>
        <path d={pathMouthExpression(expression)} className={css.ghostMouth} />
      </g>
      <g className={css.ghostShadow}>
        <ellipse cx="105" cy="350" rx="100" ry="10" fill={fillGhostBody(type)} fillOpacity='.25' />
      </g>
    </svg>
  </div>
)

const NotFoundLink = ({ to, icon, label, onClick })=> {
  return (
     <Link className={css.link} to={to} onClick={onClick}>
       <NotFoundIcon icon={icon} />
       <p className={css.linkText}>{label}</p>
     </Link>
  )
 }


const NotFound = ()=> {

  const [variant, setVariant] = useState({ type: 'white', expression: 'happy' })

  const handleVariant = ()=> {
    const types = ['white', 'female', 'lgbt']
    const expressions = [ 'sad', 'happy' ]
    setVariant({
      type: types[Math.floor(Math.random()*types.length)],
      expression: expressions[Math.floor(Math.random()*expressions.length)]
    })
  }

  useEffect(handleVariant, [])

  return (
    <div className={css.main}>
      <h1 className={css.title}>404</h1>
      <h2 className={css.subtitle}>There's nothing here</h2>
      <div className={css.links}>
        <NotFoundLink to='/' icon='home' label='Home' />
        <NotFoundLink to='/concepts' icon='blog' label='Concepts' />
        <NotFoundLink to='/contact' icon='contact' label='Contact' />
      </div>
      <NotFoundGhost 
        expression={variant.expression} 
        type={variant.type}
        onClick={handleVariant}
      />
    </div>
  )
}

export default NotFound