export const isCellEmpty = (row, column, boardState)=> boardState[row][column] === ''

export const determineTurn = (boardState)=> {
  let total_x = 0
  let total_o = 0
  for (let i=0; i<3; i++) {
    for (let j=0; j<3; j++) {
      if (boardState[i][j] === 'X') total_x++
      if (boardState[i][j] === 'O') total_o++
    }
  }
  if (total_x > total_o) return 'O'
  else return 'X'
}

export const isGameOver = (boardState)=> {
  // Check rows
  for (let i=0; i<3; i++) {
    const row = boardState[i].join('')
    if (row === 'XXX' || row === 'OOO') return true
  }
  // Check columns
  for (let i=0; i<3; i++) {
    const column = [boardState[0][i], boardState[1][i], boardState[2][i]].join('')
    if (column === 'XXX' || column === 'OOO') return true
  }
  // Check two main diagonals
  const top_left_diagonal = [boardState[0][0], boardState[1][1], boardState[2][2]].join('')
  if (top_left_diagonal === 'XXX' || top_left_diagonal === 'OOO') return true

  const bottom_right_diagonal = [boardState[0][2], boardState[1][1], boardState[2][0]].join('')
  if (bottom_right_diagonal === 'XXX' || bottom_right_diagonal === 'OOO') return true

  // Check all board is full
  const board = [boardState[0].join(''), boardState[1].join(''), boardState[2].join('')].join('')
  if (board.length === 9) return true

  return false
}

export const gameWinner = (boardState)=> {
  // Check rows
  for (let i=0; i<3; i++) {
    const row = boardState[i].join('')
    if (row === 'XXX') return 'X'
    if (row === 'OOO') return 'O'
  }
  // Check columns
  for (let i=0; i<3; i++) {
    const column = [boardState[0][i], boardState[1][i], boardState[2][i]].join('')
    if (column === 'XXX') return 'X'
    if (column === 'OOO') return 'O'
  }
  // Check two main diagonals
  const top_left_diagonal = [boardState[0][0], boardState[1][1], boardState[2][2]].join('')
  if (top_left_diagonal === 'XXX') return 'X'
  if (top_left_diagonal === 'OOO') return 'O'

  const bottom_right_diagonal = [boardState[0][2], boardState[1][1], boardState[2][0]].join('')
  if (bottom_right_diagonal === 'XXX') return 'X'
  if (bottom_right_diagonal === 'OOO') return 'O'

  // Check all board is full
  return 'T'
}

export const determineBestMove = (boardState)=> {
  // If the middle square is available, take it
  if (!boardState[1][1]) return [1, 1]
  // Else take any of the edges
  if (!boardState[0][0]) return [0, 0]
  if (!boardState[2][0]) return [2, 0]
  if (!boardState[0][2]) return [0, 2]
  if (!boardState[2][2]) return [2, 2]
  // Else take any of the others
  for (let i=0; i<3; i++) {
    for (let j=0; j<3; j++) {
      if (!boardState[i][j]) return [i, j]
    }
  }
}