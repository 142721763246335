import React                            from 'react'
import { useState, useEffect, useRef }  from 'react'
import ConceptHeader                    from '../../components/ConceptHeader/ConceptHeader'
import BackToConcepts                   from '../../components/BackToConcepts/BackToConcepts'
import CodeArea                         from '../../components/CodeArea/CodeArea'
import { CodeLine }                     from '../../components/CodeArea/CodeArea'
import css                              from './FindTheMonkey.module.css'

const TalkLink = ()=> (
  <a
    href='https://github.com/code-jorge/jsdaycanarias-css-variables'
    target='_blank'
    rel='noopener noreferrer'
  >
    CSS Variables
  </a>
)

const CanariasJSLink = ()=> (
  <a
    href='https://twitter.com/canariasjs'
    target='_blank'
    rel='noopener noreferrer'
  >
    CanariasJS
  </a>
)

const FindTheMonkeyGame = ({ onClickBack })=> {

  const [changing, setChanging] = useState(false)

  const light = useRef()
  const monkey = useRef()

  useEffect(()=> {
    const movementDetection = e=> {
      const x = 100 * (e.clientX / window.innerWidth)
      const y = 100 * (e.clientY / window.innerHeight)
      if (light.current) {
        light.current.style.background = `
          radial-gradient(
            circle at ${x}% ${y}%, 
            transparent, 
            #000000 80px
          )
        `
      }
    }
    document.addEventListener("mousemove", movementDetection)
    return ()=> document.removeEventListener("mousemove", movementDetection);
  })

  const moveMonkey = ()=> {
    if (changing) return
    setChanging(true)
    setTimeout(()=> {
      if (monkey.current) {
        monkey.current.style.top = `${Math.random() * 100}%`
        monkey.current.style.left = `${Math.random() * 100}%`
      }
      setChanging(false)
    }, 800)
  }

  return (
    <>
      <div className={css.main}>
        <p 
          className={css.monkey} 
          ref={monkey}
          onClick={()=> moveMonkey()}
        >
          {changing ? "🐵" : "🙈"}
        </p>
        <button 
          onClick={onClickBack} 
          className={css.back}
        >
          Go back
        </button>
      </div>
      <div className={css.light} ref={light}></div>
    </>
  )
}

const FindTheMonkeyDetails = ({ onClickForward })=> {
  return (
    <>
      <ConceptHeader title='Find the monkey' />
      <div className={css.content}>
        <p className={css.block}>
          This month I gave a small talk on <TalkLink /> in Tenerife. It was an intro to the syntax and how 
          to use the feature. I had the chance to meet some awesome co-speakers and attendees, as
          well as the organizers of the event, the team behind <CanariasJSLink />.
        </p>
        <p className={css.block}>
          One of the last concepts I explored during the talk was using JS to update the CSS variables.
          It provides a quick way to separate <strong>logic</strong> and <strong>presentation</strong>.
          Using this approach JS doesn't have to override any CSS variables.
        </p>
        <p className={css.block}>
          With this idea in mind, I set up an event listener for the <code>mousemove</code> event.
          This allows me to update the CSS variables with the mouse position directly. By doing this,
          I'm not using inline styles, or overriding any CSS styles from JS. My code in CSS stays as it is,
          with only a change in the value of a variable!
        </p>
        <CodeArea>
          <CodeLine fragments={[
            { content: 'document', color: 'yellow' }, 
            { content: '.' }, 
            { content: 'addEventListener', color: 'blue' }, 
            { content: '(', color: 'blue' },
            { content: '"mousemove"', color: 'green' }, 
            { content: ', ' }, 
            { content: 'event', color: 'wood' }, 
            { content: '=> ', color: 'pink' }, 
            { content: '{ ', color: 'pink' }, 
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'const', color: 'violet' }, 
            { content: ' x', color: 'orange' }, 
            { content: ' = ' }, 
            { content: 'event', color: 'wood' }, 
            { content: '.' }, 
            { content: 'clientX', color: 'blue' }, 
            { content: ' / ' }, 
            { content: 'window', color: 'yellow' }, 
            { content: '.', color: 'yellow' }, 
            { content: 'innerWidth ', color: 'blue' }, 
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'const', color: 'violet' }, 
            { content: ' y', color: 'orange' }, 
            { content: ' = ' }, 
            { content: 'event', color: 'wood' }, 
            { content: '.' }, 
            { content: 'clientY', color: 'blue' }, 
            { content: ' / ' }, 
            { content: 'window', color: 'yellow' }, 
            { content: '.' }, 
            { content: 'innerHeight ', color: 'blue' }, 
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'document', color: 'yellow' }, 
            { content: '.' }, 
            { content: 'documentElement', color: 'blue' }, 
            { content: '.' }, 
            { content: 'style', color: 'red' }, 
            { content: '.' }, 
            { content: 'setProperty(', color: 'blue-2' }, 
            { content: '"--mouse-x"', color: 'green' }, 
            { content: ', ', color: 'yellow' }, 
            { content: 'x', color: 'orange' },  
            { content: ')', color: 'blue-2' }, 
          ]} />
          <CodeLine indent={1} fragments={[
            { content: 'document', color: 'yellow' }, 
            { content: '.' }, 
            { content: 'documentElement', color: 'blue' }, 
            { content: '.' }, 
            { content: 'style', color: 'red' }, 
            { content: '.' }, 
            { content: 'setProperty(', color: 'blue-2' }, 
            { content: '"--mouse-y"', color: 'green' }, 
            { content: ', ', color: 'yellow' }, 
            { content: 'y', color: 'orange' },  
            { content: ')', color: 'blue-2' }, 
          ]} />
          <CodeLine fragments={[
            { content: '}', color: 'pink' }, 
            { content: ')', color: 'blue' }, 
          ]} />
        </CodeArea>
        <p className={css.block}>
          With that done, all that's left is writing the CSS to utilize those variables.
          In this case, we are transforming them to a percentage, and using them as part
          of a radial background.
        </p>
        <CodeArea>
          <CodeLine fragments={[
              { content: '.light', color: 'pink', modifiers: 'strong' }, 
              { content: ' {' } 
            ]} />
            <CodeLine indent={1} fragments={[
              { content: '--x-coordinate', color: 'blue' },
              { content: ': ', color: 'grey-2' },
              { content: 'calc(100% * var(', color: 'wood' },
              { content: '--mouse-x', color: 'green' },
              { content: ', .5));', color: 'wood' },
            ]} />
            <CodeLine indent={1} fragments={[
              { content: '--y-coordinate', color: 'blue' },
              { content: ': ', color: 'grey-2' },
              { content: 'calc(100% * var(', color: 'wood' },
              { content: '--mouse-y', color: 'green' },
              { content: ', .5));', color: 'wood' },
            ]} />
            <CodeLine indent={1} fragments={[
              { content: 'background-image', color: 'blue' },
              { content: ': ', color: 'grey-2' },
              { content: 'radial-gradient(', color: 'blue-2' },
            ]} />
            <CodeLine indent={2} fragments={[
              { content: 'circle at var(', color: 'wood' },
              { content: '--x-coordinate', color: 'green' },
              { content: ') var(', color: 'wood' },
              { content: '--y-coordinate', color: 'green' },
              { content: '),', color: 'wood' },
            ]} />
            <CodeLine indent={2} fragments={[
              { content: 'transparent,', color: 'wood' },
            ]} />
            <CodeLine indent={2} fragments={[
              { content: 'black 80px', color: 'wood' },
            ]} />
            <CodeLine indent={1} fragments={[
              { content: ')', color: 'blue-2' },
              { content: ';', color: 'grey-2' },
            ]} />
            <CodeLine content='}' />
        </CodeArea>
        <p className={css.block}>
          This allows us to build a simple radial gradient that moves with the mouse.
          To see it in action, I have found a very shy monkey 🙈, which is hiding in the
          page & every time you find it, it will move to a random location on the page.{' '} 
          <span className={css.play} role='button' onClick={onClickForward}>Can you spot it?</span>.
        </p>
      </div>
      <BackToConcepts />
    </>
  )
}

const FindTheMonkey = ()=> {

  const [active, setActive] = useState(false)

  return (
    <>
      {active ? (
        <FindTheMonkeyGame onClickBack={()=> setActive(false)} />
      ) : (
        <FindTheMonkeyDetails onClickForward={()=> setActive(true)} />
      )}
    </>
  )

}

export default FindTheMonkey