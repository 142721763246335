import React, { useEffect, useState } from 'react'
import css                            from './PixelArtGenerator.module.css'

const DEFAULT_COLOR = '#00000000'
const tools = {
  ERASER: 'eraser',
  DRAW: 'draw',
  PICK: 'pick'
}

const generateGridStyles = ({ width, height })=> {
  const selectedDimension = Math.max(width, height)
  return {
    width: `calc(90vmin / ${selectedDimension/width})`,
    height: `calc(90vmin / ${selectedDimension/height})`
  }
}

const generateCellStyles = ({ width, height }, color)=> {
  const selectedDimension = Math.max(width, height)
  return {
    background: color,
    width: `calc(90vmin / ${selectedDimension})`,
    height: `calc(90vmin / ${selectedDimension})`
  }
}

const EraserIcon = ()=> (
  <svg className={css.icon} viewBox="0 0 512.121 512.121">
    <path d="m317.025 33.131-317.025 317.025 128.835 128.834h132.521l250.765-250.764zm152.67 195.095-84.233 84.233-152.669-152.669 84.232-84.232zm-105.446 105.446-21.247 21.247-152.67-152.669 21.248-21.248zm-115.318 115.318h-107.67l-98.835-98.834 126.693-126.693 152.67 152.669z"/>
  </svg>
)

const PickerIcon = ()=> (
  <svg className={css.icon} viewBox="0 0 464.736 464.736">
    <path d="M446.598,18.143c-24.183-24.184-63.393-24.191-87.592-0.008l-16.717,16.717c-8.98-8.979-23.525-8.979-32.504,0 c-8.981,8.972-8.981,23.533,0,32.505l5.416,5.419L134.613,253.377h-0.016l-62.685,62.691c-4.982,4.982-7.919,11.646-8.235,18.684 l-0.15,3.344c0,0.016,0,0.03,0,0.046l-2.529,56.704c-0.104,2.633,0.883,5.185,2.739,7.048c1.751,1.759,4.145,2.738,6.63,2.738 c0.135,0,0.269,0,0.42-0.008l30.064-1.331h0.016l18.318-0.815l8.318-0.366c9.203-0.412,17.944-4.259,24.469-10.776l240.898-240.891 l4.506,4.505c4.49,4.488,10.372,6.733,16.252,6.733c5.881,0,11.764-2.245,16.253-6.733c8.98-8.973,8.98-23.534,0-32.505 l16.716-16.718C470.782,81.544,470.782,42.334,446.598,18.143z M272.639,227.33l-84.6,15.96l137.998-138.004l34.332,34.316 L272.639,227.33z"/>
    <path d="M64.5,423.872c-35.617,0-64.5,9.145-64.5,20.435c0,11.284,28.883,20.428,64.5,20.428s64.486-9.143,64.486-20.428 C128.986,433.016,100.117,423.872,64.5,423.872z"/>
  </svg>
)

const ClearIcon = ()=> (
  <svg className={css.icon} viewBox="0 0 24 24">
    <path d="m23.487 13.941-3.535-3.535c-.66-.659-1.813-.662-2.475 0l-4.534 4.534s-.001.001-.001.001-.001.001-.001.001l-2.534 2.534c-.331.33-.513.77-.513 1.236 0 .469.182.908.513 1.239l3.535 3.535c.341.341.79.512 1.237.512.448 0 .896-.171 1.238-.512l2.535-2.535h.001v-.001l4.535-4.536c.681-.681.681-1.79-.001-2.473zm-8.131 8.486c-.098.098-.257.098-.354 0l-3.535-3.535c-.064-.063-.073-.139-.073-.178 0-.038.009-.112.073-.176l2.005-2.005 3.889 3.889z"/>
    <path d="m9.5 16c.552 0 1-.448 1-1v-4h4.07c.552 0 1-.448 1-1s-.448-1-1-1h-4.07v-3h6.5v1c0 .552.448 1 1 1s1-.448 1-1v-4.75c0-1.241-1.009-2.25-2.25-2.25h-14.5c-1.241 0-2.25 1.009-2.25 2.25v16.25c0 1.378 1.122 2.5 2.5 2.5h4.34c.552 0 1-.448 1-1s-.448-1-1-1h-4.34c-.276 0-.5-.224-.5-.5v-2.5zm-1-2h-6.5v-3h6.5zm0-5h-6.5v-3h6.5z"/>
  </svg>
)

const DownloadIcon = ()=> (
  <svg className={css.icon} viewBox="0 0 512 512">
		<path d="M382.56,233.376C379.968,227.648,374.272,224,368,224h-64V16c0-8.832-7.168-16-16-16h-64c-8.832,0-16,7.168-16,16v208h-64 c-6.272,0-11.968,3.68-14.56,9.376c-2.624,5.728-1.6,12.416,2.528,17.152l112,128c3.04,3.488,7.424,5.472,12.032,5.472 c4.608,0,8.992-2.016,12.032-5.472l112-128C384.192,245.824,385.152,239.104,382.56,233.376z"/>
		<path d="M432,352v96H80v-96H16v128c0,17.696,14.336,32,32,32h416c17.696,0,32-14.304,32-32V352H432z"/>
  </svg>
)

const PixelArtInput = ({ label, value, onAdd, onSubtract })=> (
  <div className={css.input}>
    <p className={css.inputLabel}>{label}</p>
    <div className={css.inputContent}>
      <div onClick={onSubtract} className={css.inputButton}>-</div>
      <div className={css.inputValue}>{value}</div>
      <div onClick={onAdd} className={css.inputButton}>+</div>
    </div>
  </div>
)

const PixelArtGenerator = ()=> {

  const [dimensions, setDimensions] = useState({ width: 30, height: 40 })
  const [pixels, setPixels] = useState([])
  const [selectedColor, setSelectedColor] = useState('#ff0000')
  const [recentColors, setRecentColors] = useState([])
  const [tool, setTool] = useState(tools.DRAW)

  useEffect(()=> {
    const value = localStorage.getItem('pixel-art-details')
    if (value) {
      const { dimensions, pixels, selectedColor, recentColors } = JSON.parse(value)
      setDimensions(dimensions)
      setPixels(pixels)
      setSelectedColor(selectedColor)
      setRecentColors(recentColors)
    }
  }, [])

  useEffect(()=> {
    const dimensions_data = { width: dimensions.width, height: dimensions.height }
    const serializable_data = JSON.stringify({ dimensions: dimensions_data, pixels, selectedColor, recentColors })
    localStorage.setItem('pixel-art-details', serializable_data)
  }, [dimensions.width, dimensions.height, pixels, recentColors, selectedColor])

  useEffect(()=> {
    setPixels(current_pixels=> {
      const current_height = current_pixels.length
      const current_width = current_height === 0 ? 0 : current_pixels[0].length
      const new_data = []
      for (let i=0; i<dimensions.height; i++) {
        const new_row = []
        for (let j=0; j<dimensions.width; j++) {
          if (current_height > i && current_width > j) new_row.push(current_pixels[i][j])
          else new_row.push(DEFAULT_COLOR)
        }
        new_data.push(new_row)
      }
      return new_data
    })
  }, [dimensions.width, dimensions.height])

  const handleCellClick = (selected_row, selected_column)=> ()=> {
    if (tool === tools.PICK) return setSelectedColor(pixels[selected_row][selected_column])
    setPixels(currentPixels=> (
      currentPixels.map((row, row_index)=> {
        if (row_index !== selected_row) return row
        return row.map((cell, column_index)=> {
          if (column_index !== selected_column) return cell
          else {
            if (tool === tools.ERASER) return DEFAULT_COLOR
            return selectedColor
          }
        })
      })
    )) 
    if (recentColors.includes(selectedColor)) return
    else setRecentColors(currentColors=> ([selectedColor, ...currentColors].slice(0, 20)))
  }

  const handleReset = ()=> {
    const new_data = []
    for (let i=0; i<dimensions.height; i++) {
      const new_row = []
      for (let j=0; j<dimensions.width; j++) {
        new_row.push(DEFAULT_COLOR)
      }
      new_data.push(new_row)
    }
    setPixels(new_data)
  }

  const handleDownload = ()=> {
    const canvas = document.createElement('canvas')
    canvas.setAttribute('width', dimensions.width*10)
    canvas.setAttribute('height', dimensions.height*10)
    const context = canvas.getContext('2d')
    pixels.forEach((row, row_index)=> {
      row.forEach((pixel, column_index)=> {
        context.fillStyle = pixel
        context.fillRect(10*column_index, 10*row_index, 10, 10)
      })
    })
    const image = canvas.toDataURL("image/png")
    const faux_link = document.createElement("a")
    faux_link.href = image
    faux_link.download = 'pixel-art.png'
    document.body.appendChild(faux_link)
    faux_link.click()
    document.body.removeChild(faux_link)
  }

  const toggleTool = tool=> ()=> setTool(current_tool=> current_tool === tool ? tools.DRAW : tool)

  return (
    <div>
      <div className={css.grid} style={generateGridStyles(dimensions)}>
        {pixels.map((row, row_index)=> row.map((cell, column_index)=> (
          <div 
            key={`cell-${row_index}-${column_index}`}
            className={css.cell}
            style={generateCellStyles(dimensions, cell)}
            onClick={handleCellClick(row_index, column_index)}
          />
        )))}
      </div>
      <div className={css.controls}>
        <div className={css.row}>
          <div className={css.edit}>
            <input 
              type='color'
              className={css.colorPicker} 
              value={selectedColor}
              onChange={e=> setSelectedColor(e.target.value)} 
            />
            <button 
              className={css.button} 
              data-status={tool === tools.ERASER ? 'active' : 'inactive'} 
              type='button' 
              onClick={toggleTool(tools.ERASER)}
            >
              <EraserIcon />
            </button>
            <button 
              className={css.button} 
              data-status={tool === tools.PICK ? 'active' : 'inactive'} 
              type='button' 
              onClick={toggleTool(tools.PICK)}
            >
              <PickerIcon />
            </button>
          </div>
          <div className={css.actions}>
            <button type='button' className={css.button} onClick={handleReset}>
              <ClearIcon />
            </button>
            <button type='button' className={css.button} onClick={handleDownload}>
              <DownloadIcon />
            </button>
          </div>
        </div>
        <div className={css.row}>
          <PixelArtInput 
            label='Width' 
            value={dimensions.width}
            onSubtract={()=> setDimensions(d=> ({ ...d, width: d.width-1 }))} 
            onAdd={()=> setDimensions(d=> ({ ...d, width: d.width+1 }))} 
          />
          <PixelArtInput 
            label='Height' 
            value={dimensions.height}
            onSubtract={()=> setDimensions(d=> ({ ...d, height: d.height-1 }))} 
            onAdd={()=> setDimensions(d=> ({ ...d, height: d.height+1 }))} 
          />
        </div>
        {recentColors.length > 0 && (
          <div className={css.recent}>
            <p className={css.recentTitle}>Recently used colors</p>
            <div className={css.recentColors}>
              {recentColors.map(color=> (
                <div 
                  key={color} 
                  className={css.recentColor}
                  style={{ backgroundColor: color }}
                  onClick={()=> setSelectedColor(color)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )

}

export default PixelArtGenerator